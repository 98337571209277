import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import './index.css';
import HomePage from './Home';
import About from './About';
import Partner from './Partner';
import Visa from './Visa';
import Insurance from './Insurance';
import LoginPage from './login/Login';
import SignupPage from './signup/Signup';
import Visatypes from './Visatypes';
import Application from './application/Application';
import Applicationnew from './application/Applicationnew';
import Applicationiframe from './application/Applicationiframe';
import Verify from './application/Verify';
import Orderpage from './Orderpage';
import Orderpagenew from './Orderpagenew';
import Orderfailed from './Orderfailed';
import Profile from './Profile';
import AgentProfile from  './agentProfile';
import Myorders from './Myorders';
import MyLounges from './Mylounges';
import Myinsurance from './Myinsurance';
import Travelinsurance from './Travelinsurance';
import Inboundtraveldetails from './Inboundtraveldetails';
import Inboundtravelinfo from './Inboundtravelinfo';
import Inboundtravellerinfo from './Inboundtravellerinfo';
import Insuranceplan from './Insuranceplan';
import Insurancequestion from './Insurancequestion';
import Insurancesummary from './Insurancesummary';
import Outboundtraveldetails from './Outboundtraveldetails';
import Outboundtravelinfo from './Outboundtravelinfo';
import Outboundtravellerinfo from './Outboundtravellerinfo';
import Outboundplan from './Outboundplan';
import Outboundquestion from './Outboundquestion';
import Outboundsummary from './Outboundsummary';
import Founders from './Founders';
import Services from './Services';
import Terms from './Terms';
import Privacy from './Privacy';
import Apipage from './Apipage';
import Travelagent from './Travelagent';
import Demo from './Demo';
import Logout from './Logout';
import ResetPassword from './resetpassword/ResetPassword'
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Confirmpassword from './confirmpassword/Confirmpassword';
import TravellerList from './TravellerList';
import VerificationPage from './Verification/VerificationPage';
import AgentCreated from './Verification/AgentCreated';
import DhofarIframeComponent from './DhofarIframe';
import PersonalAccidentinsurance from './personalaccidentinsurance/personalaccidentinsurance';
import BasicInformation from './personalaccidentinsurance/basicinformation';
import AccidentCover from './personalaccidentinsurance/accidentcover';
import AddBeneficiary from './personalaccidentinsurance/AddBeneficiary';
import PersonalInformation from './personalaccidentinsurance/PersonalInformation';
import PersonalInformationquestions from './personalaccidentinsurance/PersonalInformationquestions';
import PersonalAccidentSuccess from './personalaccidentinsurance/personalaccidentsuccess';
import PersonalPolicyStatus from './personalaccidentinsurance/policystatus';
import DownloadPersonalPolicy from './personalaccidentinsurance/downloadpolicy';

import TermPLanDetails from './personalaccidentinsurance/termplan/termplandetails';
import SumAssured from './personalaccidentinsurance/termplan/sumassured';
import TermPlanAddBeneficiary from './personalaccidentinsurance/termplan/termplanAddBeneficiary';
import TermPlanPersonalInformation from './personalaccidentinsurance/termplan/termplanpersonalinformation';
import TermPlanQuestions from './personalaccidentinsurance/termplan/termplanquestions';
import TermPlanSuccess from './personalaccidentinsurance/termplan/termplansuccess';
import TermPlanPolicyStatus from './personalaccidentinsurance/termplan/termplanpolicy';
import TermPlanUploadFiles from './personalaccidentinsurance/termplan/termplanuploadfile';
import DownloadTermPlanPolicy from './personalaccidentinsurance/termplan/termplandowmloadpolicy'

import LifePersonalAccidentinsurance from './lifeinsurance/lifepersonalaccidentinsurance';
import LifeBasicInformation from './lifeinsurance/lifebasicinformation';
import LifeAccidentCover from './lifeinsurance/lifeaccidentcover';
import LifeAddBeneficiary from './lifeinsurance/lifeAddBeneficiary';
import LifePersonalInformation from './lifeinsurance/lifePersonalInformation';
import LifePersonalInformationquestions from './lifeinsurance/lifePersonalInformationquestions';
import LifePersonalAccidentSuccess from './lifeinsurance/lifepersonalaccidentsuccess';
import LifeTermPLanDetails from './lifeinsurance/termplan/lifetermplandetails';
import LifeSumAssured from './lifeinsurance/termplan/lifesumassured';
import LifeTermPlanAddBeneficiary from './lifeinsurance/termplan/lifetermplanAddBeneficiary';
import LifeTermPlanPersonalInformation from './lifeinsurance/termplan/lifetermplanpersonalinformation';
import LifeTermPlanQuestions from './lifeinsurance/termplan/lifetermpalnquestions';
import LifePersonalPolicyStatus from './lifeinsurance/lifepolicystatus';
import LifeDownloadPersonalPolicy from './lifeinsurance/lifedownloadpolicy';
import LifeTermPlanPolicyStatus from './lifeinsurance/termplan/lifetermplanpolicystatus';
import LifeDownloadTermPlanPolicy from './lifeinsurance/termplan/lifetermplandowmloadpolicy';
import LifeTermPlanSuccess from './lifeinsurance/termplan/lifetermpalnsuccess';


import Cyberinsurance from './cyberinsurance/choseplan';
import CyberChosePlan from './cyberinsurance/cyberchoseplan';
import CyberPersonalInformation from './cyberinsurance/cyberpersionaalinformatiom';
import CyberQuestions from './cyberinsurance/cyberquestion';

import MedicalInsurance from './Medicalinsurance/Individual_Medical';
import MedicalBasicInformation from './Medicalinsurance/medicalbasicinformation';
import MedicalSumAssured from './Medicalinsurance/medicalsumassurance';
import MedicalPlan from './Medicalinsurance/medicalplan';
import MedicalPersonalInformation from './Medicalinsurance/medicalpersonal';
import MedicalQuestions from './Medicalinsurance/medicalquestion';
import MedicalPolicyStatus from './Medicalinsurance/medicaldetails';
import MedicalDownload from './Medicalinsurance/medicaldowload';

import MedicalHealthBasicInfo from './Medicalinsurance/Affordable_Health_Cover/medihealthbasicinfo';
import MedicalAccidentCover from './Medicalinsurance/Affordable_Health_Cover/medicalhealthassuredplan';
import MedicalHealthPlan from './Medicalinsurance/Affordable_Health_Cover/medicalhealthplan';
import MedicalHealthPersonalInformation from './Medicalinsurance/Affordable_Health_Cover/medicalhealthpersonal';
import MedicalHealthQuestions from './Medicalinsurance/Affordable_Health_Cover/medicalhealthquestion';
import MedicalHealthPolicyStatus from './Medicalinsurance/Affordable_Health_Cover/medicalhealthpolicy';
import MedicalAffordableDownload from './Medicalinsurance/Affordable_Health_Cover/medicalaffordabledowload';

import MedicalWorkerBasicInfo from './Medicalinsurance/Unified_Domestic_Worker/medicalworkerbasicinfo';
import MedicalWorkerCover from './Medicalinsurance/Unified_Domestic_Worker/medicalworkerassuredplan';
import MedicalWorkerPlan from './Medicalinsurance/Unified_Domestic_Worker/medicalworkerplan';
import MedicalWorkerPersonalInformation from './Medicalinsurance/Unified_Domestic_Worker/medicalworkerpersonal';
import MedicalWorkerQuestions from './Medicalinsurance/Unified_Domestic_Worker/medicalworkerquestion';
import MedicalWorkerPolicyStatus from './Medicalinsurance/Unified_Domestic_Worker/medicalworkerpolicy';
import DownloadMedicalPolicy from './Medicalinsurance/Unified_Domestic_Worker/medicalworkerdownload';

import Motorinsurance from './Motorinsurance/motorinsurance';
import NewpolicyGuestDetails from './Motorinsurance/newpolicyguest';
import NewpolicyOTPForm from './Motorinsurance/newpolictotp';
import NewpolicyVehicleDetails from './Motorinsurance/vehicledetails';
import NewpolicyDriverDetails from './Motorinsurance/insureddriverdetails';
import NewpolicyDrivingLincese from './Motorinsurance/DrivingLicense';
import NewpolicyGeneralInformation from './Motorinsurance/generalinformation';

import Renewpolicy from './Motorinsurance/Renewpolicy/renewpolicy';

import PersonalAccident from './sanad/PersonalAccident/personalaccident';
import DownloadInboundPolicy from './DownloadInboundPolicy';
import DownloadOutboundPolicy from './DownloadOutboundPolicy';
import DownloadSanadPolicy from './sanad/PersonalAccident/DownloadSanadPolicy';
import Makepayment from './application/Makepayment';
import SanadPolicyStatus from './sanad/PersonalAccident/SanadPolicyStatus'
import SanadTermPlan from './sanad/TermPlan/SanadTermPlan';

import Ownerpagedetails from './Homeinsurance/ownerpagedetails';
import HomeinsurancePhotograph from './Homeinsurance/home-insurance-photograph';
import HomeInusurance  from './Homeinsurance/homebasic';
import Ownerpage from './Homeinsurance/ownerpage';
import PlanofHomeinsurance from './Homeinsurance/planofhomeinsurance';
import HomePlanPersonalInformation from './Homeinsurance/homeplan-personalinformation';
import Homeinformationquestions from './Homeinsurance/homeinformationquestions';
import HomeDownloadPolicy from './Homeinsurance/homedownloadpolicy';
import Tenantpage from './Homeinsurance/tenant/tenantpage';
import Tenantpagedetails from './Homeinsurance/tenant/tenantpagedetails';
import Planoftenantinsurance from './Homeinsurance/tenant/planoftenantinsurance';
import TenantPlanPersonalInformation from './Homeinsurance/tenant/tenantplan-personalinformation';
import Tenantinformationquestions from './Homeinsurance/tenant/tenantinformationquestions';
import Tenantinsurancesuccess from './Homeinsurance/tenant/tenantinsurancesuccess';
import Ownersummary from './Homeinsurance/ownersummary';
import TenantPhotograph from './Homeinsurance/tenant/tenantphotograph';
import Tenantsummary from './Homeinsurance/tenant/tenantsummary';

import DhofarMotorinsurance from './DhofarMotorInsurance/dhofarmotordetails';
import DhofarMotorinsuranceoffers from './DhofarMotorInsurance/dhofarmotorstatus';
import MotorInsuranceThirdParty from './DhofarMotorInsurance/thirdparty';
import MotorInsuranceComperhensive from './DhofarMotorInsurance/comprehensive';
import DhofarMotorinsuranceQuote from './DhofarMotorInsurance/quote';
import DhofarMotorinsuranceComprehensiveQuote from './DhofarMotorInsurance/comprehensivequote';
import DhofarMotorInsuranceSuccess from './DhofarMotorInsurance/dhofarmotorsuccess';
import SanadMedicalAffortable from './sanad/Medical/Medicalaffortable';
import DownloadSanadAffordablePolicy from './sanad/Medical/DownloadSanadAffordable';
import SanadMedicalUnifiedDomesticWorker from './sanad/Medical/Medicalunifiedworker';
import DownloadSanadUnifiedWorkerPolicy from './sanad/Medical/downloadsanadunifiedworker';
import SanadLifePersonalAccident from './sanad/LifeInsurance/LifePersonalAccident';
import SanadLifeTermPlan from './sanad/LifeInsurance/LifeTermPlan';
import AgentTravelInsurance from './AgentTravelInsurance';
import SanadMedicalIndividual from './sanad/Medical/MedicalIndividual';
import DownloadSanadIndividualPolicy from './sanad/Medical/DownloadsanadIndividual';
import SandTravelInsurance from './sanad/TravelInsurance/sanadtravel';
import SanadDownloadInboundPolicy from './sanad/TravelInsurance/downlondtravelinbound';
import SanadDownloadOutboundPolicy from './sanad/TravelInsurance/downloadtraveloutbound';
import DownloadSanadTermPlanPolicy from './sanad/TermPlan/DownloadSanadTermPlan';
import SanadMotor from './sanad/Motor/Sanadmotorinsurance'
import ReactGA from "react-ga4";
import SearchPage from './lounge/searchpage';
import HotelDetails from './lounge/loungedetails';
import ShowHotel from './lounge/showhotel';
import PersonalDetails from './lounge/personaldetails';
import LoungesPaymentPage from './lounge/loungespayment';
import DownloadLoungesPolicy from './lounge/downloaddocument';
import OwnerSanadHomeinsurance from './sanad/Home/ownersanandinsurance';
import DownloadSanadhomePolicy from './sanad/Home/Downloadsanadhomepolicy';
import TenantSanadHomeinsurance from './sanad/Home/tenantsanadinsurance';



import HomePlanInsurance from './newinsurance/planpage';
import CustomerDetails from './newinsurance/custemerdetails';
import BuildingDetails from './newinsurance/buildingdetails';
import HomeNewQuestions from './newinsurance/question';


import HotelSearchPage from "./hotel/hotelsearchpage";
import HotelPayment from "./hotel/hotelpayment";
import HotelOfferPage from './hotel/e-hotelofferpage';
import ShowHotelDetails from './hotel/hoteldetails';
import ShowBuyHotel from './hotel/hotelrooms';


ReactGA.initialize("G-ZWGPB7J3GW");
const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/visa",
    element: <Visa />,
  },
  {
    path: "/demo",
    element: <Demo />,
  },
  {
    path: "/order-failed",
    element: <Orderfailed />,
  },
  {
    path: "/partner",
    element: <Partner />,
  },
  {
    path: "/insurance",
    element: <Insurance />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/signup",
    element: <SignupPage />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/visatypes",
    element: <Visatypes />,
  },
  {
    path: "/application",
    element: <Application />,
  },
  {
    path: "/application-new",
    element: <Applicationnew />,
  },
  {
    path: "/application-verify",
    element: <Applicationiframe />,
  },
  {
    path: "/verify",
    element: <Verify />,
  },
  {
    path: "/order-page",
    element: <Orderpage />,
  },
  {
    path: "/order-pagenew",
    element: <Orderpagenew />,
  },
  {
    path: "/travel-insurance",
    element: <Travelinsurance />,
  },
  {
    path: "/agent-travel-insurance",
    element: <AgentTravelInsurance/>,
  },
  {
    path: "/inbound-travel-details",
    element: <Inboundtraveldetails />,
  },
  {
    path: "/inbound-travel-info",
    element: <Inboundtravelinfo />,
  },
  {
    path: "/inbound-traveller-info",
    element: <Inboundtravellerinfo />,
  },
  {
    path: "/insurance-plan",
    element: <Insuranceplan />,
  },
  {
    path: "/insurance-question",
    element: <Insurancequestion />,
  },
  {
    path: "/insurance-summary",
    element: <Insurancesummary />,
  },
  {
    path: "/outbound-travel-details",
    element: <Outboundtraveldetails />,
  },
  {
    path: "/outbound-traveller-info",
    element: <Outboundtravellerinfo />,
  },
  {
    path: "/outbound-plan",
    element: <Outboundplan />,
  },
  {
    path: "/outbound-question",
    element: <Outboundquestion />,
  },
  {
    path: "/outbound-summary",
    element: <Outboundsummary />,
  },
  {
    path: "/outbound-travel-info",
    element: <Outboundtravelinfo />,
  },
  {
    path: "/about-us",
    element: <About />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },

  {
    path:"/agentprofile",
    element : <AgentProfile/>
  },

  {
    path: "/myorders",
    element: <Myorders />,
  },
  {
    path:"/myinsurance",
    element: <Myinsurance />,
  },
  {
    path:"/mylounges",
    element:<MyLounges />
  },
  {
    path: "/founders",
    element: <Founders />,
  },
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/api-page",
    element: <Apipage />,
  },
  {
    path: "/travel-agent",
    element: <Travelagent />,
  },
  {
    path: "/set-password",
    element: <Confirmpassword />
  },
  {
    path: "/travellerlist",
    element: <TravellerList />,
  },
  {
    path: "/verification",
    element: <VerificationPage />,
  },
  {
    path: "/agent-verify",
    element: <AgentCreated />,
  },
  {
    path: "/travel_insurance",
    element: <DhofarIframeComponent />,
  },
  {
    path: "/personal-accident-insurance",
    element: <PersonalAccidentinsurance />,
  },
  {
    path: "/basic-information",
    element: <BasicInformation />,
  },
  {
    path: "/accident-cover",
    element: <AccidentCover />,
  },
  {
    path: "/add-beneficiary",
    element: <AddBeneficiary />,
  },
  {
    path: "/personalinformation",
    element: <PersonalInformation />,
  },

  {
    path: "/personalinformationquestions",
    element: <PersonalInformationquestions />
  },

  {
    path: "/personalinformation-policy",
    element: <PersonalPolicyStatus />
  },

  {
    path: "/personalinformation-downloadpolicy",
    element: <DownloadPersonalPolicy />
  },

  {
    path: "/personalinformation-success",
    element: <PersonalAccidentSuccess />
  },

  {
    path: "/term-plan",
    element: <TermPLanDetails />
  },

  {
    path: "/term-plan-assured",
    element: <SumAssured />
  },
  {
    path: "/termplan-add-beneficiary",
    element: <TermPlanAddBeneficiary />
  },

  {
    path: "/termplan-personalinformation",
    element: <TermPlanPersonalInformation />
  },

  {
    path: "/termplan-questions",
    element: <TermPlanQuestions />
  },
  {
    path:"/termplan-policy",
    element:<TermPlanPolicyStatus/>
  },
  {
    path:'/termplan-fileupload',
    element:<TermPlanUploadFiles/>
  },

  {
    path:"/termplan-downloadpolicy",
    element:<DownloadTermPlanPolicy/>
  },

  {
    path: "/termplan-success",
    element: <TermPlanSuccess />
  },



  {
    path: "/life-accident-insurance",
    element: <LifePersonalAccidentinsurance />,
  },
  {
    path: "/life-basic-information",
    element: <LifeBasicInformation />,
  },
  {
    path: "/lifeaccident-cover",
    element: <LifeAccidentCover />,
  },
  {
    path: "/lifeadd-beneficiary",
    element: <LifeAddBeneficiary />,
  },
  {
    path: "/life-personalinformation",
    element: <LifePersonalInformation />,
  },

  {
    path: "/life-personalinformationquestions",
    element: <LifePersonalInformationquestions />
  },

  {
    path: "/life-personalinformation-policy",
    element: <LifePersonalPolicyStatus />
  },

  {
    path: "/life-personalinformation-downloadpolicy",
    element: <LifeDownloadPersonalPolicy />
  },

  {
    path: "/life-personalinformation-success",
    element: <LifePersonalAccidentSuccess />
  },

  {
    path: "/lifeterm-plan",
    element: <LifeTermPLanDetails />
  },

  {
    path: "/lifeterm-plan-assured",
    element: <LifeSumAssured />
  },
  {
    path: "/lifetermplan-add-beneficiary",
    element: <LifeTermPlanAddBeneficiary />
  },

  {
    path: "/lifetermplan-personalinformation",
    element: <LifeTermPlanPersonalInformation />
  },

  {
    path: "/lifetermplan-questions",
    element: <LifeTermPlanQuestions />
  },
  {
    path:"/lifetermplan-policy",
    element:<LifeTermPlanPolicyStatus/>
  },

  {
    path:"/lifetermplan-downloadpolicy",
    element:<LifeDownloadTermPlanPolicy/>

  },

  {
    path: "/lifetermplan-success",
    element: <LifeTermPlanSuccess />
  },



  {
    path: '/cyber-plan',
    element: <Cyberinsurance />
  },

  {
    path: '/cyber-choose-plan',
    element: <CyberChosePlan />
  },

  {
    path: '/cyber-personalinformation',
    element: <CyberPersonalInformation />
  },

  {
    path:'/cyber-questions',
    element:<CyberQuestions/>

  },



  {
    path:'/medical-insurance',
    element:<MedicalInsurance/>
  },
  {
    path:'/medical-basicinfo',
    element:<MedicalBasicInformation/>
  },
  {
    path:'/medical-assured',
    element:<MedicalSumAssured/>
  },
  {
    path:'/medical-plan',
    element:<MedicalPlan/>
  },
  {
    path:'/medical-personal',
    element:<MedicalPersonalInformation/>
  },

  {
    path:'/medical-questions',
    element:<MedicalQuestions/>
  },

  {
    path:'/medical-details',
    element:<MedicalPolicyStatus/>
  },
  {
    path:'/medical-download',
    element:<MedicalDownload/>
  },


  {
    path:'/medical-health-basic',
    element:<MedicalHealthBasicInfo/>
  },
  {
    path:'/medicalhealth-cover',
    element:<MedicalAccidentCover/>
  },
  {
    path:'/medicalhealth-plan',
    element:<MedicalHealthPlan/>
  },
  {
    path:'/medicalhealth-personal',
    element:<MedicalHealthPersonalInformation/>
  },

  {
    path:'/medicalhealth-questions',
    element:<MedicalHealthQuestions/>
  },
  {
    path:'/medicalhealth-policy',
    element:<MedicalHealthPolicyStatus/>
  },
  {
    path:'/medicalhealth-download',
    element:<MedicalAffordableDownload/>
  },



  {
    path:'/medicalworker-basicinfo',
    element:<MedicalWorkerBasicInfo/>
  },

  {
    path:'/medicalworker-coverplan',
    element:<MedicalWorkerCover/>
  },

  {
    path:'/medicalworker-plan',
    element:<MedicalWorkerPlan/>
  },

  {
    path:'/medicalworker-personal',
    element:<MedicalWorkerPersonalInformation/>
  },
  {
    path:'/medicalworker-questions',
    element:<MedicalWorkerQuestions/>
  },
  {
    path:'/medicalworker-policy',
    element:<MedicalWorkerPolicyStatus/>
  },

  {
    path:'/medicalworker-download',
    element:<DownloadMedicalPolicy/>
  },

  {
    path:'/motorinsurance',
    element:<Motorinsurance/>
  },

  {
    path:'/motor-newpolicy',
    element:<NewpolicyGuestDetails/>
  },

  {
    path: '/newpolicy-otp',
    element:<NewpolicyOTPForm/>
  },

  {
    path:'/newpolicy-vehicle',
    element:<NewpolicyVehicleDetails/>
  },

  {
    path:'/newpolicy-driver',
    element: <NewpolicyDriverDetails/>
  },

  {
    path:'/newpolicy-driverlicense',
    element:<NewpolicyDrivingLincese/>
  },

  {
    path:'/newpolicy-generalinfo',
    element:<NewpolicyGeneralInformation/>
  },

  {
    path:'/renewpolicy',
    element : <Renewpolicy/>
  },

  {
    path: "/sanad-personal-accident",
    element: <PersonalAccident />,
  },
  {
    path: "/download-policy",
    element: <DownloadInboundPolicy />
  },
  {
    path: "/sanad-term-plan",
    element: <SanadTermPlan />,
  },
  {
    path: "/download-outbound-policy",
    element: <DownloadOutboundPolicy />
  },
  {
    path: "/download-sanad-policy",
    element: <DownloadSanadPolicy />
  },
  {
    path: "/make-payment",
    element: <Makepayment/>
  },
  {
    path:"/sanad-policy-status",
    element:<SanadPolicyStatus/>
  },
  {
    path:"/reset-password",
    element:<ResetPassword/>
  },

  {
    path:'/sanad-medical-individual',
    element:<SanadMedicalIndividual/>
  },
  {
    path:'/sanad-medicalindividual-downloadpolicy',
    element:<DownloadSanadIndividualPolicy/>
  },
  {
    path:"/sanad-medicalaffotable",
    element:<SanadMedicalAffortable/>
  },
  {
    path:"/sanad-medical-downloadpolicy",
    element:<DownloadSanadAffordablePolicy/>
  },
  {
    path:'/sanad-medicalunifiedworker',
    element:<SanadMedicalUnifiedDomesticWorker/>
  },
  {
    path:"/get-medicalunifiedworker-dowloadpolicy",
    element:<DownloadSanadUnifiedWorkerPolicy/>
  },
  {
    path:'/sanadtravelinsurance',
    element:<SandTravelInsurance/>
  },
  {
    path:'/sanadtravel-inbound-downloadpolicy',
    element:<SanadDownloadInboundPolicy/>
  },
  {
    path:'/sanadtravel-outbound-downloadpolicy',
    element:<SanadDownloadOutboundPolicy/>
  },
  {
    path:"/home-insurance",
    element:<HomeInusurance/>
  },
  {
    path:"/home-ownerpage",
    element:<Ownerpage/>
  },
  {
    path:"/ownerpagedetails",
    element:<Ownerpagedetails/>
  },
  {
    path:"/home-insurance-photograph",
    element:<HomeinsurancePhotograph/>
  },
  {
    path:"/planofhomeinsurance",
    element:<PlanofHomeinsurance/>
  },
  {
    path:"/homeplanpersonalinformation",
    element:<HomePlanPersonalInformation/>
  },
  {
    path:"/homeinformationquestions",
    element:<Homeinformationquestions/>
  },
  {
    path:"/ownersummary",
    element:<Ownersummary/>
  },
  {
    path:"/home-downloadpolicy",
    element:<HomeDownloadPolicy/>
  },
  {
    path:"/home-tenant",
    element:<Tenantpage/>
  },
  {
    path:"/tenantpagedetails",
    element:<Tenantpagedetails/>
  },
  {
    path : "/tenantphotograph",
    element: <TenantPhotograph />
  },
  {
    path:"/planoftenantinsurance",
    element:<Planoftenantinsurance/>
  },
  {
    path:"/tenantplanpersonalinformation",
    element:<TenantPlanPersonalInformation/>
  },
  {
    path:"/tenantinformationquestions",
    element:<Tenantinformationquestions/>
  },
  {
    path:"/tenantinsurancesuccess",
    element:<Tenantinsurancesuccess/>
  },
  {
    path:"/tenantsummary",
    element:<Tenantsummary/>
  },

  {
    path:"/dhofar-motorinsurance",
    element:<DhofarMotorinsurance/>
  },
  {
    path:"/dhofar-motoroffers",
    element:<DhofarMotorinsuranceoffers/>
  },
  {
    path:'/dhofar-motor-thirdparty',
    element:<MotorInsuranceThirdParty/>
  },
  {
    path:'/dhofar-motor-comperhensive',
    element:<MotorInsuranceComperhensive/>
  },
  {
    path:'/dhofar-motor-quote',
    element:<DhofarMotorinsuranceQuote/>
  },
  {
    path:'/dhofar-motor-comprehensivequote',
    element:<DhofarMotorinsuranceComprehensiveQuote/>
  },
  {
    path:'/dhofar-motor-success',
    element:<DhofarMotorInsuranceSuccess/>
  },
  {
    path:'/sanad-life-personalaccident',
    element:<SanadLifePersonalAccident/>
  },
  {
    path:'/sanad-life-termplan',
    element:<SanadLifeTermPlan/>
  },
  {
    path: "/download-sanad-termpolicy",
    element: <DownloadSanadTermPlanPolicy />
  },
  {
    path:'/sanad-motor-insurance',
    element:<SanadMotor/>
  },
  {
    path:'/lounge-booking',
    element:<SearchPage/>
  },{
    path:'/lounge-details',
    element:<HotelDetails/>
  },
  {
    path:"/lounge-list",
    element:<ShowHotel/>
  },
  {
    path:"/lounge-personaldetails",
    element:<PersonalDetails/>
  },
  {
    path:'/owner-sanadhomeinsurance',
    element:<OwnerSanadHomeinsurance/>
  },
  {
    path:'/lounge-payment',
    element:<LoungesPaymentPage/>
  },
  {
    path:'/lounge-download',
    element:<DownloadLoungesPolicy/>
  },
  {
    path:'/sanadhome-downloadpolicy',
    element:<DownloadSanadhomePolicy/>
  },
  {
    path:'/tenant-sanadhomeinsurance',
    element:<TenantSanadHomeinsurance/>
  },
  {
    path:"/hotel-details",
    element:<ShowHotelDetails/>
  },
  {
     path:"hotel-search",
     element:<HotelSearchPage />
  },
  {
    path:"/hotel-rooms",
    element :<ShowBuyHotel/>
  },
  {
     path:"/hotel-booking",
     element :<HotelOfferPage />
  },
  {
    path:"/hotel-payment",
    element:<HotelPayment/>
  },
  {
    path:"/planpage",
    element:<HomePlanInsurance/>
  },
  {
    path:"/customerdetails",
    element:<CustomerDetails/>
  },
  {
    path:'/buildingdetails',
    element:<BuildingDetails/>
  },
  {
    path:'/homequestion',
    element:<HomeNewQuestions/>
  }
  
]);

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>,
  document.getElementById('root')
);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();