import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { event } from "jquery";
  
 


const HotelOfferPage = ({ initialTime = 15 * 60 }) => {

  const { config } = require('../api/api');
  const [contacts, setContacts] = useState([{ email: "", countryCode: "", mobile: "" },]);
  const [isChecked, setIsChecked] = useState(false);
  const [timeLeft, setTimeLeft] = useState(initialTime); 
  const [firstname,setFirstname] =useState();
  const[lastname,setLastname]=useState();
  const[email,setEmail]=useState();
  const[countrycode,setCountrycode]=useState();
  const[number,setNumber]=useState();
  const [vendorKey, setVendorkey] = useState('');
  const base_url = config.baseURL;

 useEffect(() => {

    const postvData = { organization_name: "Superjet" };
    debugger

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {

      debugger
      fetch(base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorkey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]);

  const addRow = () => {
    if (contacts.length < 3) {
      setContacts([...contacts, { email: "", countryCode: "", mobile: "" }]);
    }
  };
  const removeRow = (index) => {
    const updatedContacts = contacts.filter((_, i) => i !== index);
    setContacts(updatedContacts);
  };
  const handlecheckbox = (event) => {
    setIsChecked(event.target.checked)
  }
  useEffect(() => {
    if (timeLeft <= 0)
      return;
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [timeLeft]);


  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const handlechangeforfirstname=(event)=>{
setFirstname(event.target.value)
  }
 const handlechangeforlastname=(event)=>{
  setLastname(event.target.value)
 }
 const handlechangeforemail=(event)=>{
  setEmail(event.target.value)
 }
 const handlechangeforcountrycode=(event)=>{
  setCountrycode(event.target.value)
 }
 const handlechangefornumber=(event)=>{
  setNumber(event.target.value)
 }

const handleSubmit=(e)=>{
 e.preventDefault();
 window.location.href="/hotel-payment";
}


  return (
    <Fragment>
      <div className="header_section1">
        <Navbar />
        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">E-Hotels</h3>
          </div>
        </div>
      </div>
      <div>
        <div className="row p-4">
          <div className="col-md-2">

          </div>
          <div className="col-md-6">
            <div className="card p-3 shadow"
              style={{
                //   width: '250px',
                //   height: '160px',
                boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
                Width: "100%"
              }}>
              <h2 className="card-title p-0" >Traveler Information</h2>
              <span className="p-0">All data must be indicated in latin letters</span>
            </div>

            <div className="card p-3 shadow"
              style={{
                //   width: '250px',
                //   height: '160px',

                boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
              }}>
              <div className="d-flex">
                <div style={{ backgroundColor: "#eee", height: "65px", width: "40px", border: "0px solid" }}>
                  <h1 className="text-center p-2" style={{ fontSize: "30px" }}>1</h1></div>
                <div style={{
                  height: "65px",
                  width: "87px",
                  marginLeft: "10px"
                }}>
                  <img style={{}} src="https://cdn.worldota.net/t/x220/content/0f/26/0f267156e844bf590f5c0ae4c84da500f3c825d3.jpeg" alt=""></img>
                </div>
                <div className="d-block ml-2">
                  <h3 class="fw-bold p-0">Standard Single room</h3>
                  <span class="text-muted">Standard Single room</span>
                </div>
              </div>
             
              <div>
              <hr/>
                <div className="d-flex mt-4">
                  
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                  </svg>
                  <h5 className="ml-2">Guest 1</h5>
                </div>
              </div>

              <div className='row mb-3'>

                <div className='col-md-4'>

                  <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <span>First Name <span style={{ color: 'red', marginLeft: '2px' }}>*</span></span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    value={firstname}
                    name='fullName'
                    placeholder="Enter name"
                    onChange={handlechangeforfirstname}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                    }}/>
                    </div>

                <div className='col-md-4'>
                  <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <span>
                      Last Name <span style={{ color: "red" }}>*</span>
                    </span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    name='lastname'
                    value={lastname}
                     placeholder="Enter lastname"
                    onChange={handlechangeforlastname}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                    }}
                  />

                </div> 
                </div>
                </div>
              <div className="card p-3 shadow mt-4"
              style={{
                //   width: '250px',
                //   height: '160px',
                boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
                Width: "100%"
              }}>
              <h2 className="card-title p-0" >Buyer billing info</h2>
            </div>
            <div className="card p-3 shadow"
              style={{
                //   width: '250px',
                //   height: '160px',
                boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
              }}>
              {contacts.map((contact, index) => (
                <div>
                  <div className="d-flex align-items-center justify-content-end">
                    {contacts.length > 1 && (
                      <button
                        className="border-0 bg-transparent p-0 ms-2"
                        onClick={() => removeRow(index)}
                        style={{ cursor: "pointer" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" lass="bi bi-x" viewBox="0 0 16 16">
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                        </svg>
                      </button>
                    )}
                  </div>

                  <div className='row mb-3'>
                    <div className='col-md-4 ' >
                      <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span>Email ID<span style={{ color: 'red', marginLeft: '2px' }}></span></span>
                      </label>
                      <input
                        type='email'
                        className='form-control'
                        value={email}
                        name='email'
                         placeholder="Enter email"
                        onChange={handlechangeforemail}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace( /^[^\s@]+@[^\s@]+\.[^\s@]+$/g, '');
                        }}
                        />

                    </div>

                    <div className='col-md-4'>
                      <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span>
                          Country's phone code <span style={{ color: "red" }}></span>
                        </span>
                      </label>
                      <select
                        type='number'
                        className='form-control'
                      name='number'
                      value={countrycode}
                      onchange={handlechangeforcountrycode}
                      >
                     <option value={'Select countrycode'} >Select countrycode</option>
                    </select>
                    </div>

                    <div className='col-md-4'>
                      <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span>
                          Mobile Number <span style={{ color: "red" }}></span>
                        </span>
                      </label>
                      <input
                        type='number'
                        className='form-control'
                        name='number'
                         placeholder="Enter mobile number"
                        value={number}
                        onchange={handlechangefornumber}
                      />
                      </div>
                  </div>
                </div>
              ))}

              <div className="col-md-1 text-end">
                <p className="text-danger" size={20} style={{ cursor: "pointer" }}  ></p>
              </div>
              <div className="d-flex justify-content-end">
                {contacts.length < 3 && (<button className="btn-primary rounded-pill text-white my-3 py-1 " onClick={addRow}>
                  + Add line
                </button>
                )}

              </div>
              <div>
                <h3>Detailed Price</h3>
                <table className="table table-bordered mt-3">
                  <thead style={{ background: "#f4f6fb" }}>
                    <tr>
                      <th rowSpan={2}>Number</th>
                      <th rowSpan={2}>Net price </th>
                      <th rowSpan={2}>Markup</th>
                      <th colSpan={2} style={{ textAlign: "center" }}>Discount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr >
                      <td>Standard Single Room</td>
                      <td>48.08 $</td>
                      <td>
                        <strong>Own:</strong> <p>$0.92</p>
                        <strong>Total:</strong><p> $0.92</p>
                      </td>
                      <td>
                        <strong>Own:</strong> <p>$0.92</p>
                      </td>
                      </tr>
                      </tbody>
                   </table>
                  </div>
                <div className="d-flex justify-content-end">
                <h4>Only 16.00 dollars</h4>
                </div>
                </div>

            <div className="card p-3 shadow mt-4"
              style={{
                //   width: '250px',
                //   height: '160px',
                boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
                Width: "100%"
              }}>
              <h2 className="card-title p-0">Payments methods</h2>
            </div>

            <div className="card p-3 shadow "
              style={{
                //   width: '250px',
                //   height: '160px',
                boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
                Width: "100%"
              }}>

              <p ><input type="checkbox" onChange={handlecheckbox} checked={isChecked} name="note" />
                {"  I agree to the cancellation policy"}
              </p>
              <div className="d-flex justify-content-between">
                <button className="btn-primary rounded-pill text-white my-3 py-1 " >
                  Back to search
                </button>
                <button className="btn-primary rounded-pill text-white my-3 py-1 "
                onClick={(e)=>{handleSubmit(e)}}
                 style={{ backgroundColor: isChecked ? 'blue' : "grey", borderColor: '#1D3561' }}>
                  Further
                </button>
              </div>
            </div>
            </div>
          <div className="col-md-3">
            <div className="card p-3 shadow"
              style={{
                //   width: '250px',
                //   height: '160px',
                boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
                Width: "100%"
              }}>
              <h2 className="card-title p-0" >Booking details</h2>
              </div>

            <div className="card p-3 shadow">
              <div className="d-flex" >
                <h3 class="fw-bold p-0">Men'k Kings</h3>
                <div className="mx-3 mt-2" style={{ display: "flex", gap: "4px" }} >
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="orange" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="orange" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="orange" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
                    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
                    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                  </svg>
                </div>
              </div>
              <p>86A,Revolyustil highway</p>
              <div style={{
                height: "150px",
                width: "100%",
                overflow: "hidden",
                 }}>
                <img src="https://cdn.worldota.net/t/x220/content/0f/26/0f267156e844bf590f5c0ae4c84da500f3c825d3.jpeg" class="w-100 h-20 object-fit-cover" alt=""></img>
              </div>
              <table class="table table-borderless">
                <tbody>
                  <tr>
                    <th >Check-in</th>
                    <td>2025-03-20</td>
                  </tr>
                  <tr>
                    <th >Check-out</th>
                    <td>2025-03-21</td>
                  </tr>
                  <tr>
                    <th scope="row">Nights</th>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Guests</th>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Rooms</th>
                    <td>1</td>
                  </tr>
                </tbody>
              </table>
              <div>
                <h3>Order cost :     66 dollars</h3>
              </div>
              </div>

            <div className="card p-3 shadow"
              style={{
                //   width: '250px',
                //   height: '160px',
                boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
                Width: "100%"
              }}>
              <div className="d-flex">
                <h2 className="card-title p-0">Cancellation Policy</h2>
                 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="skyblue" class="bi bi-exclamation-circle-fill ml-2 mt-1" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
                </svg>

              </div>
              <div className="border border-info rounded p-3 bg-light">
                <h3>Attention!</h3>
                <p>From 17.03.25 10:00 (UTC) cancel penalty will be cancel penalty will be 100 percent of order netto price (140.24 USD)</p>
              </div>
              <span className="mt-1 mx-2"><span style={{ color: "red" }}>*</span> Time Zone according to your settings is (UTC +0)</span>
            </div>
            <div className="mt-4">
              <h3 style={{ fontSize: "20px" }}>{formatTime(timeLeft)}</h3>
              <span>Pay for your order within 15 minutes</span>
            </div>
          </div>

          <div className="col-md-1">
          </div>

        </div>
        </div>
    <Footer />
    </Fragment>
  );
};


export default HotelOfferPage;