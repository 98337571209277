import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../../tr.png';
import { format } from 'date-fns';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';
const { config } = require('../../api/api');


function DownloadSanadhomePolicy() {

  const base_url = config.baseURL;
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

  const { t } = useTranslation();
  const vendor_key = 'c3VwZXJqZXRfc2FuYWRfcGVyc29uYWxfYWNjaWRlbnRfdmVuZG9yX2tleQ==';
  const api_headers = {
    'Authorization': 'Bearer ' + vendor_key,
    'Content-Type': 'application/json' // Ensure you're sending JSON
  }
  const [vendorKey, setVendorkey] = useState('');
  const [dhofarData, setDhofarData] = useState(null);
  const [policyNo, setPolicyNo] = useState(null);
  const [policyLink, setPolicyLink] = useState(null);
  const [policyDetails, setPolicyDetails] = useState(null);
  const [policyDetails1, setPolicyDetails1] = useState(null);
  const [policyCoverDetails, setPolicyCoverDetails] = useState(null);
  const [sandaUrlData, setSandaUrlData] = useState(null);
  const [benData, benAppData] = useState(false);
  const redirect_url = "https://superjetom.com/";
  const [payment_link, setPaymentLink] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [passportFile, setPassportFile] = useState(null);
  //const[passportCopyWithVisaPageOrResidentId,setPassportCopyWithVisaPageOrResidentId]=useState(null);
  const [fileChoosen, setFileChoosen] = useState(true);
  const [loading, setLoading] = useState(false);


  useEffect(async () => {
    debugger
    const fetchData = async () => {
      try {
        var accessTokenData = await createAccessToken();
        var accessToken = accessTokenData?.result?.data?.token;
        if (accessToken != null && accessToken != undefined) {
          debugger
          setDhofarData(accessToken);

        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);



  useEffect(async () => {
    const urlSearchString = window.location.search;
    const uparams = new URLSearchParams(urlSearchString);
    const policy_no = uparams.get('policyno');
    setPolicyNo(policy_no);
    var policy_api_data = {
      "policy_no": policy_no
    };
    debugger
    const response = await fetch(`${base_url}/get_sanadownerdetailsby_policyno`, {
      method: 'POST',
      headers: api_headers,
      body: JSON.stringify(policy_api_data),
    });

    const data = await response.json();

    if (data.message === 'success') {

      setPolicyDetails(data?.result[0]);

      var policy_cover_details = data?.result[0]?.policy_data;
      var policy_sanad_details = data?.result[0]?.sanad_data;
      if (policy_cover_details) {
        policy_cover_details = JSON.parse(policy_cover_details);
        setPolicyCoverDetails(policy_cover_details);
      } else {
        setPolicyCoverDetails(null);
      }

      if (policy_sanad_details) {
        policy_sanad_details = JSON.parse(policy_sanad_details);
        var url_data = {
          'back_url': policy_sanad_details.sanad_BackUrl,
          'return_url': policy_sanad_details.sanad_ReturnUrl,
          'error_url': policy_sanad_details.sanad_ErrorUrl,
        };
        setSandaUrlData(url_data);
      }
      // Handle successful response
      // Example: setPaymentUrl(data.result.data.payment_url);
    } else {
      setPolicyDetails(null);
      setPolicyCoverDetails(null);
      // Handle failure
      // setPolicyLink('');
    }
    // var url_data = Cookies.get('sanadUrlData');
    // debugger
    // if (url_data) {
    //   setSandaUrlData(JSON.parse(url_data));
    // }


  }, []);



  const createAccessToken = async () => {
    try {
      const response = await fetch(base_url + '/sanad_access_token', {
        method: 'GET',
        headers: api_headers
      });
      const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
      if (data.message === 'success') {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };


  //   useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         debugger
  //         const postData = { token: dhofarData, policy_no: policyNo };

  //         // const postData = { id: someId }; // Adjust as necessary
  //         const response = await fetch(base_url + '/get_sanad_medical_affordable_policydocument', {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //           body: JSON.stringify(postData),
  //         });
  // debugger
  //         if (!response.ok) {
  //           throw new Error('Network response was not ok');
  //         }
  //         const data = await response.json();
  //         if (data.message === 'success' && data?.result?.data?.policy_schedule_url != null) {
  //           setPolicyLink(data.result.data.policy_schedule_url);
  //         } else {
  //           setPolicyLink('');
  //         }
  //       } catch (error) {
  //         console.error('Error fetching policy documents:', error);
  //       }
  //     };
  //     fetchData();
  //   }, [dhofarData, policyNo]);

  useEffect(() => {
    const fetchPolicyDetails = async () => {
      debugger
      if (!dhofarData || !policyNo) return; // Ensure both values are available before making the API call
      debugger
      try {
        const postData = { token: dhofarData, policy_no: policyNo };

        const response = await fetch(base_url + '/get_policy_details', {
          method: 'POST',
          headers: api_headers,
          body: JSON.stringify(postData),
        });

        if (!response.ok) {
          setPolicyLink('');
          // throw new Error('Network response was not ok');
        }

        const data = await response.json();

        if (data.message === 'success') {
          setPolicyDetails1(data.result.data);
          await fetchPolicyDocument();
        } else {
          setPolicyDetails1('');
        }
      } catch (error) {
        console.error('Error fetching policy documents:', error);
      }
    };

    fetchPolicyDetails();
  }, [dhofarData, policyNo]);

  const fetchPolicyDocument = async () => {
    debugger
    if (!dhofarData || !policyNo) return; // Ensure both values are available before making the API call
    debugger
    try {
      const postData = { token: dhofarData, policy_no: policyNo };

      const response = await fetch(base_url + '/get_policy_documents', {
        method: 'POST',
        headers: api_headers,
        body: JSON.stringify(postData),
      });

      if (!response.ok) {
        setPolicyLink('');
        // throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data.message === 'success' && data?.result?.data?.policy_schedule_url) {
        setPolicyLink(data.result.data.policy_schedule_url);
      } else {
        setPolicyLink('');
      }
    } catch (error) {
      console.error('Error fetching policy documents:', error);
    }
  };
  const handleClose = () => {
    setShowUploadModal(false);
  };

  const handleShow = () => {
    setShowUploadModal(true);
  };

  useEffect(() => {
    if (passportFile !== null && passportFile !== undefined && passportFile !== "") {
      setFileChoosen(false);
    }
    else {
      setFileChoosen(true);
    }
  }, [passportFile]);

  const handleFileDelete = (e) => {

    const name = e.target.getAttribute('data-name');
    if (name === 'passport') {
      setPassportFile(null);
      document.getElementById('passport').value = '';
    }
  };
  const handleFileChange = (e) => {
    debugger

    if (e.target.files.length === 1) {
      const file = e.target.files[0];
      const name = e.target.name;
      const fileType = file.type;
      const maxSize = 2 * 1024 * 1024; // 5MB in bytes

      // Check if the file exceeds the size limit
      if (file && file.size > maxSize) {
        alert('File size exceeds the 2MB limit. \n يتجاوز حجم الملف الحد المسموح به وهو 2 ميجابايت');
        return;
      }
      const allowedExtensions = ['image/jpeg', 'image/jpg', 'image/png'];
      if (allowedExtensions.includes(fileType)) {
        if (name === 'passport' && passportFile == null) {
          setPassportFile(file);
        } else if (name === 'passport' && passportFile !== null && passportFile !== '' && passportFile !== undefined) {
          setPassportFile(null);
          setPassportFile(() => file);
        }
      } else {
        alert('Please select a valid image (JPG, JPEG, PNG). \n الرجاء تحديد صورة صالحة (JPG، JPEG، PNG)');  
      }
      document.getElementById('passport').value = '';
    }
  };

  const handleUpload = async (e) => {

    e.preventDefault();
    const dhofar = dhofarData;
    const policy = policyNo;
    const formData = new FormData();
    formData.append('token', dhofar);
    formData.append('policy_number', policy);

    if (passportFile !== null) {
      formData.append('passport', passportFile);
    }
    setLoading(true);
    const response = await fetch(base_url + '/get_upload_document', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${vendor_key}`
      },
      body: formData
    });
    debugger

    const data = await response.json();
    setLoading(false);
    if (data.result.success) {

      alert('Document Uploaded successfully \n تم تحميل المستند بنجاح');
    } else {
      alert('file is not uploaded try again \n لم يتم تحميل الملف حاول مرة أخرى');
    }

  };

  return (
    policyNo ? <Fragment>
      <div className="features_section layout_padding my-5">
        <div>
          <div className="row">
            <Col md={3}></Col>
            <Col md={6} className="myforms my-3 ">
              <div style={{ display: 'flex', paddingBottom: '20px' }}>
                <button style={{ width: '100px', fontWeight: 'bold' }} onClick={() => {
                  const regex = /^(ftp|http|https):\/\/[^ "]+$/;
                  var isValidUrl = regex.test(sandaUrlData?.back_url);

                  if (isValidUrl) {
                    window.location.href = sandaUrlData?.back_url + '?policyno=' + policyDetails?.policy_no;
                    // window.open(sandaUrlData?.back_url + '?policyno=' + policyDetails?.policy_no, '_blank')
                  } else {
                    window.location.reload();
                  }

                }} >Back</button>
              </div>

              <div style={{ textAlign: 'left', fontSize: '20px' }}>
                <b>Policy Details  - {policyDetails1 != null ? policyDetails1?._id : ''}</b>
              </div>

              <br />

              <table className="table table-bordered table-condenced" style={{ textAlign: 'left' }}>

                <tbody>

                  <tr><td style={{ width: '50%' }}><b>Name</b>
                  </td><td>{policyDetails1 != null ? policyDetails1?.personalInformation?.fullName : ''}</td></tr>

                  <tr><td><b>Nationality</b>
                  </td><td>{policyDetails1 != null ? policyDetails1?.nationality?.toString() : ''}</td></tr>


                  <tr><td><b>Mobile Number</b>
                  </td><td>{policyDetails1 != null ? policyDetails1?.personalInformation?.mobileNumber : ''}</td></tr>

                  {/* <tr><td><b>Cover Type</b>
                  </td><td>{policyDetails1 != null ? policyDetails1?.cover_type : ''}</td></tr> */}

                  <tr><td><b>Email</b>
                  </td><td>{policyDetails1 != null ? policyDetails1?.personalInformation?.email : ''}</td></tr>


                  <tr><td><b>Civil ID</b>
                  </td><td>{policyDetails1 != null ? policyDetails1?.civil_id : ''}</td></tr>

                  <tr><td><b>Total Fee (OMR)</b>
                  </td><td>{policyDetails1 != null ? policyDetails1?.amount?.total?.toString() : ''}</td></tr>

                  <tr><td><b>Plan</b>
                  </td><td>{policyDetails1 != null ? policyDetails1?.plan : ''}</td></tr>

                </tbody>

              </table>
              <div className="text-center">
                {
                  policyLink != null && policyLink != '' ?
                    // true ?
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <button
                        onClick={() => {
                          debugger
                          if (policyLink) {
                            var url = policyLink;
                            const link = document.createElement('a');
                            link.href = url;
                            link.target = '_blank';
                            link.download = url.split('/').pop(); // Extracts filename from URL
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          } else {
                            alert('Policy error')
                          }
                        }}
                        style={{ fontWeight: 'bold' }}
                      >
                        Download Policy Document
                      </button>


                      <div className="col-lg-4 col-md-4 col-sm-8 col-8 p-1 mb-2 d-flex justify-content-center" style={{ width: '33%' }}>
                        <button type="button" style={{ fontWeight: 'bold' }} onClick={handleShow}>
                          Upload Documents
                        </button>
                      </div>
                      <div style={{ margin: '10px 0' }} >
                        <button style={{ marginLeft: 'auto', fontWeight: 'bold' }}
                          onClick={() => {
                            debugger
                            const regex = /^(ftp|http|https):\/\/[^ "]+$/;
                            var isValidUrl = regex.test(sandaUrlData?.return_url);

                            if (isValidUrl) {
                              window.location.href =
                                sandaUrlData?.return_url +
                                '?policyno=' + policyDetails?.policy_no +
                                '&sanad_reference_no=' + policyDetails?.sanad_reference_no +
                                '&policy_link=' + policyLink;
                              window.open(sandaUrlData?.return_url + '?policyno=' + policyDetails?.policy_no + '&sanad_reference_no=' + policyDetails?.sanad_reference_no + '&policy_link=' + policyLink, '_blank')
                            } else {
                              window.location.reload();
                            }

                          }}

                        >Go To Sanad</button>
                      </div></div>
                    : policyLink === '' ? <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><h2>Policy Payment Pending</h2>
                      <div>
                        <button
                          onClick={() => {

                            if (policyDetails?.payment_link) {
                              window.location.href = policyDetails?.payment_link;

                              // Reload the current page (form tab)
                              // window.location.reload();
                            }

                          }} style={{ marginLeft: 'auto', width: '200px', fontWeight: 'bold' }}>Make Payment</button></div></div> : "Loading"
                }
              </div>
              <p></p>
              {/* {!policyLink &&(<p style={{ color: "red" }}>(This payment link is valid for a maximum of 24 hours. If the link expires, please create a new policy.\nرابط الدفع هذا صالح لمدة أقصاها 24 ساعة. إذا انتهت صلاحية الرابط، يرجى إنشاء سياسة جديدة.) </p>)} */}
              {!policyLink && (<label style={{ color: "red", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span>
                  This payment link is valid for a maximum of 24 hours. If the link expires, please create a new policy.
                </span>
                <span style={{ textAlign: "right", direction: "rtl" }}>
                  رابط الدفع هذا صالح لمدة أقصاها 24 ساعة. إذا انتهت صلاحية الرابط، يرجى إنشاء سياسة جديدة.
                </span>
              </label>)}
            </Col>
            <Col md={3}></Col>

          </div>
        </div>
      </div>


      {showUploadModal && (
        <div className="modal fade show" style={{ display: 'block' }} id="uploadModal">
          <div className="modal-dialog modal-dialog-centered modal-lg" style={{ left: '10%' }}>
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title" style={{ textAlign: "start" }}>
                  <span >
                    {t('Upload')} {t('Documents')}
                  </span>
                  <p className="p-0">Please upload a file format only Image </p>
                  <p style={{ color: "red", padding: '0px' }}>(accept only jpg, jpeg, png)</p>

                </h2>


                <button type="button" className="close" onClick={handleClose}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={(e) => handleUpload(e).then(handleClose)}>
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-8 col-form-label text-left">
                      <strong>{t('Passport copy ')}</strong>
                      <br /> <small>{t('(Attach the visa page or resident ID)')}</small>
                    </label>
                    <div className="col-sm-12 col-md-4">
                      {passportFile === null ? (
                        <button className="btn btn-primary" type="button" onClick={() => document.getElementById('passport').click()}>
                          {t('Upload File')}
                        </button>
                      ) : (
                        <div className="d-flex border mt-2 align-items-center p-2 rounded">
                          <p className="mb-0" style={{ width: '80%' }}>
                            {passportFile.name.length > 5
                              ? `${passportFile.name.substring(0, 5)}...${passportFile.type.split('/')[1]}`
                              : passportFile.name}
                          </p>
                          <span
                            className="text-danger ms-auto"
                            onClick={handleFileDelete}
                            data-name="passport"
                            style={{ cursor: 'pointer', width: '20%' }}>
                            &times;
                          </span>
                        </div>
                      )}
                    </div>
                  </div>


                  <input
                    type="file"
                    name="passport"
                    id="passport"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />

                  <div className="mt-4 text-center">
                    <button type="submit" disabled={fileChoosen} className="btn btn-primary">
                      {t('Submit')}
                      {/* {loading ? "Submitting..." : "Submit"} */}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}


      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
    </Fragment> : <div style={{

      border: 'none',
      textAlign: 'center',
      marginTop: '25%',
      fontSize: '20px',
      fontWeight: 'bold'
    }}>  {"Policy Number Invalid"}</div>



  );
}

export default DownloadSanadhomePolicy;