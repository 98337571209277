import React, { useState, useEffect, useRef } from "react";
import { Fragment } from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';
import $ from 'jquery';
import { use } from "react";
const { config } = require('../../api/api');


const OwnerSanadHomeinsurance = () => {
  const base_url = config.baseURL;
  const vendor_key = 'c3VwZXJqZXRfc2FuYWRfcGVyc29uYWxfYWNjaWRlbnRfdmVuZG9yX2tleQ==';
  const api_headers = {
      'Authorization': 'Bearer ' + vendor_key,
      'Content-Type': 'application/json' // Ensure you're sending JSON
  }
   const signatureRef = useRef();
   const signatureRef1 = useRef();
   const section1Ref=useRef();
   const section5Ref=useRef();
   const buildingRef=useRef(null);
   const basementRef=useRef();
   const addressRef=useRef(null);
   const fileInputRef=useRef()
   const floor=useRef(null)
   const floorRef=useRef(null)
  const selectOpt1 = useRef(null);
  const [selectedCode, setSelectedCode] = useState('');
   const [selectedoccupation, setSelectedoccupation] = useState('');
  const selectnationalty = useRef(null);
  const redirecturl = config.redirectURL;
  const selectnationalty1 = useRef(null);
  const [done, setDone] = useState(false);
  const [sanadBackUrl, setSanadBackUrl] = useState('');
  const [isHidePage, setIsHidePage] = useState('');
  const [sanadRequesteeName, setSanadRequesteename] = useState('');
  const [sanadUserName, setSanadUserName] = useState('');
  const [sanadUserMobileNumber, setSanadUserMobileNumber] = useState('');
  const [sanadReferenceNumber, setSanadReferenceNumber] = useState('');
  const [sanadCivilIDNumber, setSanadCivilIDNumber] = useState('');
  const [sanadServiceRequestId, setSanadServiceRequestId] = useState('');
  const [sanadTransDate, setSanadTransDate] = useState('');
  const [sanadReturnUrl, setSanadReturnUrl] = useState('');
  const [sanadErrorUrl, setSanadErrorUrl] = useState('');
  const [sanadLanguage, setSanadLanguage] = useState('');
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
  const [isSignatureEmpty1, setIsSignatureEmpty1] = useState(true);
  const [sumAssured, setSumAssured] = useState(0);
  const [tenantsumAssured, tenantsetSumAssured] = useState(0);
  const [tenantsec3sumAssured, tenantsetsec3SumAssured] = useState(0);
  const [sec3sumAssured, setsec3SumAssured] = useState(0);
  const [sec5sumAssured, setsec5SumAssured] = useState(0);
  const [ischeckedsec1, setIscheckedsec1,] = useState(0);
  const [ischeckedsec2, setIscheckedsec2,] = useState(0);
  const [ischeckedsec3, setIscheckedsec3,] = useState(0);
  const [ischeckedsec4, setIscheckedsec4,] = useState(0);
  const [ischeckedsec5, setIscheckedsec5,] = useState(0);
  const [ischeckedsec6, setIscheckedsec6,] = useState(0);
  const [ischeckedsec7, setIscheckedsec7,] = useState(0);
  const [ischeckedsec8, setIscheckedsec8,] = useState(0);
 
  const [ischeckedsec1tenant, setIscheckedsec1tenant,] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedtenant, setIsExpandedtenant] = useState(false);
  const [isExpandedofsec3, setIsExpandedofsec3] = useState(false);
  const [isExpandedofsec3tenant, setIsExpandedofsec3tenant] = useState(false);
  const [isExpandedofsec5, setIsExpandedofsec5] = useState(false);
  const [errorsec1tenant, setErrorsec1tenant] = useState("");
  const [errorsec1, setErrorsec1] = useState("");
  const [errorsec3, setErrorsec3] = useState("");
  const [errorsec3tenant, setErrorsec3tenant] = useState("");
  const [errorsec5, setErrorsec5] = useState("");
  const [section_1_sum_assuredtenant, setsection_1_sum_assuredtenant] = useState(null);
  const [section_3_sum_assuredtenant, setsection_3_sum_assuredtenant] = useState(null);
  const [section_5_sum_assuredtenant, setsection_5_sum_assuredtenant] = useState(null);
  const [section_1_sum_assured, setsection_1_sum_assured] = useState();
  const [section_3_sum_assured, setsection_3_sum_assured] = useState();
  const [section_5_sum_assured, setsection_5_sum_assured] = useState();
  const [selectedOption, setSelectedOption] = useState("Owner");
  const [fileName, setFileName] = useState([]);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [emailErrortenant, setEmailErrortenant] = useState('');
  const [mobileError, setMobileError] = useState('');
  const[addressError,setAddressError]=useState('');
  const [mobileErrortenant, setMobileErrortenant] = useState('');
  const [civilIDError, setCivilIDError] = useState('');
  const [civilIDErrortenant, setCivilIDErrortenant] = useState('');
  const [nameError, setNameError] = useState('');
  const[ageError,setAgeError]=useState('');
  const[typeError,setTypeError]=useState('');
  const[floorError,setFloorError]=useState('');
  const[photoError,setPhotoError]=useState('');
  const [nameErrortenant, setNameErrortenant] = useState('');
  const [occupationError, setOccupationError] = useState("");
  const [occupationErrortenant, setOccupationErrortenant] = useState("");
  const [locationError, setLocationError] = useState('');
  const [locationErrortenant, setLocationErrortenant] = useState('');
  const { t } = useTranslation();
  const [occupationList, setOccupationList] = useState([]);
  const[formdetails,setFormdetails]= useState([]);
  const [nationalityError, setNationalityError] = useState("");
  const [expriydateofError, setExpriydateError] = useState('');
  const [nationalityErrortenant, setNationalityErrortenant] = useState("");
  const [expriydateofErrortenant, setExpriydateErrortenant] = useState('');
  const [countryList, setCountryList] = useState([]);
  const [countryListtenant, setCountryListtenant] = useState([]);
  const [vatErrortenant, setVatErrortenant] = useState('');
  const [vatError, setVatError] = useState('');
  const [formDataform, setformDataform] = useState([]);
  const [basicPremium, setBasicPremium] = useState(null);
  const [accessTokenKey, setAccessTokenKey] = useState('');
  const [questionList, setQuestionList] = useState();
  const [quotationData, setQuotationData] = useState(null);
  const [premium, setPremium] = useState(null);
const [personalPolicyPaymentLink, setPersonalPolicyPaymentLink] = useState(null);
  const [quotationDatatenant, setQuotationDatatenant] = useState(null);
  const [personalPolicyData, setPersonalPolicyData] = useState(null);
  const [loading, setLoading] = useState(false);
  const[questiontype,setQuestiontype]=useState(null);


  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

  const [questionsData, setQuestionsData] = useState([{
    english: '',
    arabic: '',
    key: '',
    accepted_answer: '',
    accepted_answer1: '',
    isChecked: '',
    isChecked_answer: '',
    details: ''
  }]);
  const[Tenantquestiondata,setTenantquestiondata]= useState([{

    english: '',
    arabic: '',
    key: '',
    accepted_answer: '',
    accepted_answer1: '',
    isChecked: '',
    isChecked_answer: '',
    details: ''

  }]);

  
  

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    mobileNumber: '',
    dob: '',
    civilIDno: '',
    passportNumber: '',
    height: '',
    weight: '',
    resident_location: '',
    agebuilding:'',
    nationality: '',
    vat_in: '',
    occupation:'',
    gender: '',
    is_married: '',
    expiryDateof: '',
    buildingtype: '',
    property_photograph_1:'',
    property_photograph_2:'',
    property_photograph_3:'',
    building:"",
    floor_no:""
    
  }
  );
  const [formData1, setFormData1] = useState({
    nationality: '',
    occupation:'',
    fullName: '',
    email: '',
    mobileNumber: '',
    civilIDno: '',
    resident_location: '',
    nationality: '',

  });
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  useEffect(async () => {
      debugger
      // update sanad data in superjet
      const urlSearchString = window.location.search;
      const uparams = new URLSearchParams(urlSearchString);
      const sanadUserName = uparams.get('SANADUSERNAME');
      const sanadRequesteename = uparams.get('REQUESTEENAME');
      const sanadUserMobileNumber = uparams.get('REQUESTEEMOBILENO');
      const sanadReferenceNumber = uparams.get('SANADREFNUMBER');
      const sanadCivilIDNumber = uparams.get('REQUESTEECIVILNO');
      const sanadAccessToken 
      = uparams.get('SANADACCESSTOKEN');
      const sanad_ServiceRequestId = uparams.get('SERVICEREQUESTID');
      const sanad_TransDate = uparams.get('TRANSDATE');
      const sanad_ReturnUrl = uparams.get('RETURNURL');
      const sanad_ErrorUrl = uparams.get('ERRORURL');
      const sanad_BackUrl = uparams.get('BACKURL');
      const sanad_Language = uparams.get('LANGUAGE');
      setSanadUserName(sanadUserName);
      setSanadRequesteename(sanadRequesteename); 
      setSanadUserMobileNumber(sanadUserMobileNumber);
      setSanadReferenceNumber(sanadReferenceNumber);
      setSanadCivilIDNumber(sanadCivilIDNumber);
      setSanadServiceRequestId(sanad_ServiceRequestId);
      setSanadTransDate(sanad_TransDate);
      setSanadReturnUrl(sanad_ReturnUrl);
      setSanadErrorUrl(sanad_ErrorUrl);
      setSanadBackUrl(sanad_BackUrl);
      setSanadLanguage(sanad_Language);
      // const response = await fetch(base_url + '/verify_sanad_access_token', {
      //     method: 'POST',
      //     headers: api_headers,
      //     body: JSON.stringify({
      //         sanad_access_token: sanadAccessToken // Serializing the body as JSON
      //     })
      // });
      // const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');

      // debugger
      // if (data.message === 'success') {
      //     setIsHidePage('success');
      // } else {
      //     setIsHidePage('fail');
      // }

      if (sanadAccessToken === "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFkbWluQHNhbmFkLmNvbSIsInBhc3N3b3JkIjoiMTIzNCIsImlhdCI6MTcyODA1MjA0NywiZXhwIjoxNzI4MDUyOTQ3fQ.ucyIy6q6ovSDstXZL1zO-9swTAGYFeypV0uo8_rQJJc") {
          setIsHidePage('success');
      } else {
          setIsHidePage('fail');
      }
      var sanad_urls_data = {
          'back_url': sanad_BackUrl,
          'return_url': sanad_ReturnUrl,
          'error_url': sanad_ErrorUrl,
      }
      //    sessionStorage.setItem("sanadUrlData", JSON.stringify(sanad_urls_data));
      const expiryDate = new Date();
      expiryDate.setTime(expiryDate.getTime() + (1 * 60 * 60 * 1000));
      Cookies.set('sanadUrlData', JSON.stringify(sanad_urls_data), { expires: expiryDate });
      setFormData({
          fullName: sanadRequesteename,
          email: '',
          mobileNumber: sanadUserMobileNumber,
          dob: '',
          civilIDno: sanadCivilIDNumber,
          passportNumber: '',
          height: '',
          weight: '',
          resident_location: '',
          nationality: '',
          vat_in: '',
          gender: '',
          is_married: '',
          expiryDateof: ''
      });
  }, []);

  const handleChange = (amount) => {
    setSumAssured((prevSum) => {
      const newSum = Math.max(0, prevSum + amount);

      if (newSum === 0 || newSum > section_1_sum_assured) {
        setErrorsec1("Please enter sum assured, must not be more than " + section_1_sum_assured);
      } else {
        setErrorsec1("");
      }

      return newSum;
    });
  };


  const handleChangeofsection3 = (amount) => {
    //setsec3SumAssured(prevSum => Math.max(0, prevSum + amount)); 
    setsec3SumAssured((prevSum) => {
      const newSum = Math.max(0, prevSum + amount);

      if (newSum === 0 || newSum > section_3_sum_assured) {
        setErrorsec3("Please enter sum assured, must not be more than "+ section_3_sum_assured);
      } else {
        setErrorsec3("");
      }

      return newSum;
    });
  }
  const handleCheckboxChange = (e) => {
    setIsExpanded(!isExpanded);
    setIscheckedsec1(!ischeckedsec1);
    setIscheckedsec1(e.target.checked ? 1 : 0);
  };

  const handleCheckboxChangeofsection2 = (e) => {
    setIscheckedsec2(e.target.checked ? 1 : 0); 
  };

  const handleCheckboxChangeofsection3 = (e) => {
    setIsExpandedofsec3(!isExpandedofsec3);
    setIscheckedsec3(e.target.checked ? 1 : 0);
    };
    const handleCheckboxChangeofsection4 = (e) => {
      setIscheckedsec4(e.target.checked ? 1 : 0); 
    };
    
  const handleCheckboxChangeofsection5 = (e) => {
    setIsExpandedofsec5(!isExpandedofsec5);
    setIscheckedsec5(e.target.checked ? 1 : 0);
    
  };
    const handleCheckboxChangeofsection6 = (e) => {
      setIscheckedsec6(e.target.checked ? 1 : 0); 
    };
    const handleCheckboxChangeofsection7 = (e) => {
      setIscheckedsec7(e.target.checked ? 1 : 0); 
    };
    const handleCheckboxChangeofsection8 = (e) => {
      setIscheckedsec8(e.target.checked ? 1 : 0); 
    };
  
  const handleChangeofsection5 = (amount) => {
    // setsec5SumAssured(prevSum => Math.max(0, prevSum + amount)); // Ensures it doesn't go below 0
    setsec5SumAssured((prevSum) => {
      const newSum = Math.max(0, prevSum + amount);

      if (newSum === 0 || newSum > section_5_sum_assured) {
        setErrorsec5("Please enter sum assured, must not be more than " + section_5_sum_assured);
      } else {
        setErrorsec5("");
      }

      return newSum;
    });
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  }

  function handleRadioChangequestions(index, isChecked) {
    const updatedQuestionsData = [...questionsData];
    updatedQuestionsData[index] = {
      ...updatedQuestionsData[index],
      isChecked,
      isChecked_answer: isChecked ? "Yes" : "No"
    };
    setQuestionsData(updatedQuestionsData);
  }

  function handleRadioChangequestionstenant(index, isChecked) {
    const updatedQuestionsData1 = [...Tenantquestiondata];
    updatedQuestionsData1[index] = {
      ...updatedQuestionsData1[index],
      isChecked,
      isChecked_answer: isChecked ? "Yes" : "No"
    };
   setTenantquestiondata(updatedQuestionsData1);
  }

  
  function handleDetailsChangetenant(index, details) {

    const updatedQuestionsData1 = [...Tenantquestiondata];
    updatedQuestionsData1[index] = {
      ...updatedQuestionsData1[index],
      details
    };
    console.log("details",details)
    setTenantquestiondata(updatedQuestionsData1);
  }

  function handleDetailsChange(index, details) {

    const updatedQuestionsData = [...questionsData];
    updatedQuestionsData[index] = {
      ...updatedQuestionsData[index],
      details
    };
    
    setQuestionsData(updatedQuestionsData);
  }


const[fileCount,setFileCount]=useState("0");
const[propertyphoto_1,setPropertyPhoto_1]=useState(null);
const[propertyphoto_2,setPropertyPhoto_2]=useState(null);
const[propertyphoto_3,setPropertyPhoto_3]=useState(null);
const files=[propertyphoto_1,propertyphoto_2,propertyphoto_3];


const handleFileChange=async(e)=>{
  debugger;
  const file=e.target.files[0];
  setPhotoError('');
  setFileName(file?file.name:"No File Chosen");

//Check if a file is selected

if(e.target.files && e.target.files.length >0){
  const file = e.target.files[0];
      const name = e.target.name;
      const fileType = file.type;
      const maxSize = 1 * 1024 * 1024;
if (file && file.size > maxSize){
  alert('File size exceeds the 2MB limit. \n يتجاوز حجم الملف الحد المسموح به وهو 2 ميجابايت');
  return;
}
const allowedExtensions = ['image/jpeg', 'image/jpg', 'image/png'];
      if (allowedExtensions.includes(fileType)){   
       
  if(propertyphoto_1===null || propertyphoto_1===undefined || propertyphoto_1===""){
    setPropertyPhoto_1(e.target.files[0]);
    setIsFileUploaded(true);
  } else if(propertyphoto_2===null || propertyphoto_2===undefined || propertyphoto_2===""){
    setPropertyPhoto_2(e.target.files[0]);
  }else if(propertyphoto_3===null || propertyphoto_3===undefined || propertyphoto_3===""){
    setPropertyPhoto_3(e.target.files[0]);
    setIsFileUploaded(true);
  }else{
  setIsFileUploaded(false);
}

}
else {
  alert('Please select a valid image (JPG, JPEG, PNG). \n الرجاء تحديد صورة صالحة (JPG، JPEG، PNG)');  
}
}
document.getElementById("file-upload").value="";
};


useEffect(()=>{
  const count=files.filter((file)=>file!==null && file!==undefined && file!=="").length;
  setFileCount(count);
},[files]);

  

  const handleProceed = () => {
    const fileArray =[];
    files.forEach((file)=>{
    const reader =new FileReader();
    reader.onload=()=>{
         fileArray.push({
          base64:reader.result,
          name: file.name,
          type:file.type
         });

         if(fileArray.length===files.length){
          sessionStorage.setItem("files",JSON.stringify(fileArray));
         }
    }
    reader.readAsDataURL(file);
    });
   
  
  };

  const handleRemove = (e) => {
    const name=e.target.getAttribute("name");
    switch(name){
      case "photo_1":
        setPropertyPhoto_1(null);
        break;
        case "photo_2":
        setPropertyPhoto_2(null);
        break;
        case "photo_3":
        setPropertyPhoto_3(null);
        break;
        default:
    }
    document.getElementById("file-upload").value="";
  }  

  const handleChangeforpersonalinfotenant = (e) => {

    setFormData1({ ...formData1, [e.target.name]: e.target.value });
    console.log(formData1)

  };
  const handleChangeforoccupation=(e)=>{
    setFormData((Data)=>({
      ...Data,
      occupation:e.target.value
    }));
  }
  const handleChangeforpersonalinfo = (e) => {

    setFormData({ ...formData, [e.target.name]: e.target.value });
    console.log(formData)

  };

  const handleLocationBlur = (e) => {
    const { value } = e.target;

    // Optional: Regular expression to allow only letters, spaces, commas, and hyphens
    const locationPattern = /^[a-zA-Z\s,-]+$/;

    // Check if the Residential Location is empty or invalid
    if (value === '') {
      setLocationError('Please enter your Residential Location');
    } else if (!locationPattern.test(value)) {
      setLocationError('Please enter a valid Residential Location (letters, spaces, commas, hyphens only)');
    } else {
      setLocationError('');
    }
  };

  const handleageofbuildingBlur=(e)=>{
    const { value } = e.target;
    const agePattern = /^[0-9]+$/;
    if (!agePattern.test(value)) {
      setAgeError('Please enter valid Age of Building,it accept numbers only');
    } else {
      setAgeError('');
    }

  }

  const handletypeofbuildingBlur=(e)=>{
    const { value } = e.target;
    const agePattern = /^[0-9]+$/;
    if (!agePattern.test(value)) {
      setAgeError('Please enter valid Age of Building,it accept numbers only');
    } else {
      setAgeError('');
    }

  }


  const handleLocationBlurtenant = (e) => {
    const { value } = e.target;

    // Optional: Regular expression to allow only letters, spaces, commas, and hyphens
    const locationPattern = /^[a-zA-Z\s,-]+$/;

    // Check if the Residential Location is empty or invalid
    if (value === '') {
      setLocationErrortenant('Please enter your Residential Location');
    } else if (!locationPattern.test(value)) {
      setLocationErrortenant('Please enter a valid Residential Location (letters, spaces, commas, hyphens only)');
    } else {
      setLocationErrortenant('');
    }
  };

  const handleCivilIDBlurtenant = (e) => {
    const { value } = e.target;

    // Regular expression to allow only numbers and check length between 4 to 9 digits
    const civilIDPattern = /^\d{4,9}$/;

    // Check if the Civil ID matches the pattern
    if (!civilIDPattern.test(value)) {
      setCivilIDErrortenant('Please enter valid civil id number, it accept numbers only, 4 to 9 digit.');
    } else {
      setCivilIDErrortenant('');
    }
  };



  const handleCivilIDBlur = (e) => {
    const { value } = e.target;

    // Regular expression to allow only numbers and check length between 4 to 9 digits
    const civilIDPattern = /^\d{4,9}$/;

    // Check if the Civil ID matches the pattern
    if (!civilIDPattern.test(value)) {
      setCivilIDError('Please enter valid civil id number, it accept numbers only, 4 to 9 digit.');
    } else {
      setCivilIDError('');
    }
  };
  const handleMobileBlur = (e) => {
    const { value } = e.target;

    // Check if the mobile number has exactly 8 digits
    if (value.length !== 8 || !/^\d+$/.test(value)) {
      setMobileError('Please enter 8 digit phone number');
    } else {
      setMobileError('');
    }
  };
  const handleMobileBlurtenant = (e) => {
    const { value } = e.target;

    // Check if the mobile number has exactly 8 digits
    if (value.length !== 8 || !/^\d+$/.test(value)) {
      setMobileErrortenant('Please enter 8 digit phone number');
    } else {
      setMobileErrortenant('');
    }
  };


  const handleEmailBlur = (e) => {
    const { value } = e.target;

    // Regular expression to validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the entered email is valid
    if (!emailPattern.test(value)) {
      setEmailError('Please enter valid e-mail address');
    } else {
      setEmailError('');
    }
  };



  const handleEmailBlurtenant = (e) => {
    const { value } = e.target;

    // Regular expression to validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the entered email is valid
    if (!emailPattern.test(value)) {
      setEmailErrortenant('Please enter valid e-mail address');
    } else {
      setEmailErrortenant('');
    }
  };


  const handleNameBlurtenant = (e) => {
    const { value } = e.target;

    // Regular expression for English letters and spaces
    const englishLetterPattern = /^[a-zA-Z\s]*$/;

    // Check if the name is empty or contains invalid characters
    if (value === '') {
      setNameErrortenant('Please enter Full Name');
    } else if (!englishLetterPattern.test(value)) {
      setNameErrortenant('Please enter Full Name, accept english letters only.');
    } else {
      setNameErrortenant('');
    }
  };

  const handleNameBlur = (e) => {
    const { value } = e.target;

    // Regular expression for English letters and spaces
    const englishLetterPattern = /^[a-zA-Z\s]*$/;

    // Check if the name is empty or contains invalid characters
    if (value === '') {
      setNameError('Please enter Full Name');
    } else if (!englishLetterPattern.test(value)) {
      setNameError('Please enter Full Name, accept english letters only.');
    } else {
      setNameError('');
    }
  };

  const handleExpriydateBlur = (e) => {
    const { value } = e.target;


    // Check if the Residential Location is empty or invalid
    if (value === '') {
      setExpriydateError('Please Select a Expiry Date of Civil ID / Passport');
    } else {
      const selectedDate = new Date(value);
      const currentDate = new Date();

      if (selectedDate < currentDate) {
        document.getElementsByName("expiryDateof")[0].focus();
        setExpriydateError('Expiry date cannot be in the future');
      } else if (isNaN(selectedDate)) {
        document.getElementsByName("expiryDateof")[0].focus();
        setExpriydateError('Invalid Expiry Date');
      } else {
        setExpriydateError('');
      }
    }
  };


  const handleExpriydateBlurtenant = (e) => {
    const { value } = e.target;


    // Check if the Residential Location is empty or invalid
    if (value === '') {
      setExpriydateErrortenant('Please Select a Expiry Date of Civil ID / Passport');
    } else {
      const tenantDate = new Date(value);
      const currentDate = new Date();

      if (tenantDate < currentDate) {
        document.getElementsByName("expiryDateof")[0].focus();
        setExpriydateErrortenant('Expiry date cannot be in the future');
      } else if (isNaN(tenantDate)) {
        document.getElementsByName("expiryDateof")[0].focus();
        setExpriydateErrortenant('Invalid Expiry Date');
      } else {
        setExpriydateErrortenant('');
      }
    }
  };


  const handleVatBlurtenant = (e) => {
    const { value } = e.target;

    // Regular expression to allow only letters and numbers
    const vatPattern = /^[a-zA-Z0-9]{15}$/;

    // Check if the VATIN is exactly 15 characters long and matches the pattern
    if (!vatPattern.test(value)) {
      setVatErrortenant('Please enter a valid VATIN (15 characters, letters, and numbers only)');
    } else {
      setVatErrortenant('');
    }
  };
  const handleVatBlur = (e) => {
    const { value } = e.target;

    // Regular expression to allow only letters and numbers
    const vatPattern = /^[a-zA-Z0-9]{15}$/;

    // Check if the VATIN is exactly 15 characters long and matches the pattern
    if (!vatPattern.test(value)) {
      setVatError('Please enter a valid VATIN (15 characters, letters, and numbers only)');
    } else {
      setVatError('');
    }
  };

 
  useEffect(() => {
    //debugger
    // Initialize Select2
    $(selectOpt1.current).select2({width:"100%"});

    // Handle Select2 change event
    $(selectOpt1.current).on('select2:select', (e) => {
        //debugger
        // Find the selected option by matching the code (value)
        const selectedOption = occupationList.find(option => option.CODE === e.params.data.id);
        debugger
        setOccupationError(""); // Clear the error if valid

        setSelectedCode(e.params?.data?.id?.toString());
        setSelectedoccupation(e.params?.data?.text?.split('-')[0]?.trim());
        
    });
    

    // Clean up Select2 instance on unmount
    // return () => {
    //     $(selectOpt1.current).select2('destroy');
    // };
}, [occupationList]);


 useEffect(() => {
        //debugger
        // Initialize Select2
        $(selectnationalty.current).select2({width:"100%"});

        // Handle Select2 change event
        $(selectnationalty.current).on('select2:select', (e) => {
            //debugger
            // Find the selected option by matching the code (value)
            const selectedOption_nat = countryList.find(option => option===e.params.data.id);
            debugger
         setNationalityError("");
            
        setFormData(prevFormData => ({
            ...prevFormData,
            nationality: selectedOption_nat,
        }));
            
        });
    
    }, [countryList]);
    
 useEffect(() => {
  //debugger
  // Initialize Select2
  $(selectnationalty1.current).select2();

  // Handle Select2 change event
  $(selectnationalty1.current).on('select2:select', (e) => {
      //debugger
      // Find the selected option by matching the code (value)
      const selectedOption_nat = countryList.find(option => option===e.params.data.id);
      debugger
   setNationalityError("");
      
  setFormData(prevFormData => ({
      ...prevFormData,
      nationality: selectedOption_nat,
  }));
      
  });

}, [countryList]);

 
const [buildingtype, setBuildingType] = useState('')
const[floor_no,setFloor_no]=useState('');


const handleChangeBuildingType=(e)=>{
  setBuildingType(e.target.value);
  setTypeError('')
  
}
const handleChangeFloorno=(e)=>{
  setFloorNo(e.target.value);
  setFloorError('')
}

 const handleChangeforquestion=(e)=>{
setQuestiontype((data)=>({...data,[e.target.name]:e.target.value}));
 }
  
const [agebuilding, setagebuildingValue] = useState('');
const [agebuildingintenant, setagebuildingValueintenant] = useState('');

  const handleChangeage = (e) => {
    

    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      agebuilding: value, // Update the value of buildingTypeTenant
    }));
   };

  const handleChangeageintenant = (event) => {
    setagebuildingValueintenant(event.target.value);
  };

  const [floorno, setFloorNo] = useState('')
  const [floornotenant, setFloorNotenant] = useState('')


    const [Protection , setProtection] = useState('');
    const [Protectiontenant , setProtectiontenant] = useState('');
    const handleChangeProtection = (event) => {
      setProtection(event.target.value)
    };

    const handleChangeProtectiontenant= (event) => {
      setProtectiontenant(event.target.value)
    };

    const [address, setaAddressValue] = useState('');
    const [addresstenant, setaAddressValuetenant] = useState('');
  

    const handleChangeAddress = (event) => {
      const name=event.target.name;
      const addressProperty=event.target.value.trimStart();
      // setAddressError('');
      setaAddressValue(event.target.value.trimStart());
      if(( addressProperty!==''&& addressProperty!==null &&  addressProperty!==undefined && name==="address")){
        setAddressError((data)=>({...data,addressOfPropertyErr:""}));
     }
    };
    const handleError=(e)=>{
      
  
     if((address===""||address===null||address===undefined)){
      setAddressError((data)=>({...data,addressOfPropertyErr:"Please enter valid details"}))
     }
      
    }
  
  

    const handleChangeAddresstenant = (event) => {
      setaAddressValuetenant(event.target.value);
    };

    const [security, setSecurity] = useState('');
    const [securitytenant, setSecuritytenant] = useState('');
    const handleChangeSecurity= (event) => {
      setSecurity(event.target.value)
    };
  
    
    const handleChangeSecuritytenant= (event) => {
      setSecuritytenant(event.target.value)
    };
  


  const clearSignature = () => {
    signatureRef.current.clear();
    setIsSignatureEmpty(true); // Disable button after clearing
  };
  const handleSignature = () => {
    if (!signatureRef.current.isEmpty()) {
      setIsSignatureEmpty(false); // Enable button when signature is drawn
    }
  };

  const handleSignaturetenant = () => {
    if (!signatureRef1.current.isEmpty()) {
      setIsSignatureEmpty1(false); // Enable button when signature is drawn
    }
  };

  const clearSignaturetenant= () => {
    signatureRef1.current.clear();
    setIsSignatureEmpty1(true); // Disable button after clearing
  };

  const [isChecked, setIsChecked] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);
  const handleCheckboxChangequestions = (event) => {
    setIsChecked(event.target.checked);
  };


  useEffect(async () => {
    debugger
         const fetchData = async () => {
             try {
                 var accessTokenData = await createAccessToken();
                 var accessToken = accessTokenData?.result?.data?.token;
                 if (accessToken != null && accessToken != undefined) {
                    // document.getElementsByName("gender_value")[0].focus();
                     setAccessTokenKey(accessToken);
                     
                   await getOccupationList(accessToken);
                   await getCountryList(accessToken);
                   await gethomeinsuranceform (accessToken);
                   await getQuestionList(accessToken);
                   await getSumAssured(accessToken); 
                  
                     // debugger
                     // console.log('accessTokenData', accessTokenData)
                 }
             } catch (error) {
                 console.error('Error:', error);
             }
         };
 
         fetchData();
     }, []);

    
  const createAccessToken = async () => {
    debugger
    try {
        const response = await fetch(base_url + '/sanad_access_token', {
            method: 'GET',
            headers: api_headers
        });
        const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
        if (data.message === 'success') 
          
        {
          
            return data;
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
};


    const getCountryList = async (accessToken) => {
      debugger
      try {
          const response = await fetch(base_url + '/get_countries', {
              method: 'POST',
              headers: api_headers,
              body: JSON.stringify({
                  token: accessToken // Serializing the body as JSON
              })
          });
          const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
          if (data.message === ' success') {
              setCountryList(data.result.data);
              console.log("countryList",countryList);
              
          } else {
              setCountryList(null);
          }
      } catch (error) {
          setCountryList(null);
          // return null;
      }
  };

    const getOccupationList = async (accessToken) => {
   debugger
    try {

        const response = await fetch(base_url + '/get_occupation', {
            method: 'POST',
            headers: api_headers,
            body: JSON.stringify({
                token: accessToken // Serializing the body as JSON
            })
        });
        const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
        if (data.message === ' success') {
            setOccupationList(data?.result?.data);
            console.log("setOccupationList",data?.result?.data)
            
        } else {
          setOccupationList([]);  
        }
    } catch (error) {
      setOccupationList([]);  
        
    }
};


const gethomeinsuranceform = async (accessToken) => {
  debugger
   try {

       const response = await fetch(base_url + '/get_home_insurance_form', {
           method: 'POST',
           headers: api_headers,
           body: JSON.stringify({
               token: accessToken // Serializing the body as JSON
           })
       });
       const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
       if (data.message === 'success') {
           setFormdetails(data.result.data);
           console.log(data.result.data)
           
       } else {
        setFormdetails(null);
       }
   } catch (error) {
       // setOccupationList(null);
       
   }
};




const getQuestionList = async (accessToken) => {
  debugger
  try {
      const response = await fetch(base_url + '/get_homequestions', {
          method: 'POST',
          headers: api_headers,
          body: JSON.stringify({
              token: accessToken // Serializing the body as JSON
          })
      });
      const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
      debugger
      if (data.message === 'success') {
          setQuestionList(data.result.data);
          var questionsLists = data.result.data;

          questionsLists = questionsLists.map(question => ({
              english: question.English,
              arabic: question.Arabic,
              key: question.key,
              accepted_answer: question.accepted_answer,
              isChecked: '',
              details: '',
              isChecked_answer: ''
          }));
          setQuestionsData(questionsLists);
          setTenantquestiondata(questionsLists);
          // setRelationList(data?.result?.data);
      } else {
          setQuestionList(null);
      }
  } catch (error) {
      setQuestionList(null);
      // return null;
  }
};

//get sum assured value//

const getSumAssured = async (accessToken) => {
  debugger
   try {

       const response = await fetch(base_url + '/get_sum_assured', {
           method: 'POST',
           headers: api_headers,
           body: JSON.stringify({
               token: accessToken // Serializing the body as JSON
           })
       });
       const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
       if (data.message ==" success") {
        setsection_1_sum_assured(data?.result?.data?.section_1_sum_assured);
        setsection_3_sum_assured(data?.result?.data?.section_3_sum_assured);
        setsection_5_sum_assured(data?.result?.data?.section_5_sum_assured);
           
           
       } else {
        
        //setFormdetails(null);
        setsection_1_sum_assured(null);
        setsection_3_sum_assured(null);
      setsection_5_sum_assured(null);
       }
   } catch (error) {
        console.error('Error during the fetch:', error);
    setsection_1_sum_assured(null);
    setsection_3_sum_assured(null);
    setsection_5_sum_assured(null);
        
       
   }
};


const getQuotation = async (accessToken) => {
  debugger
  try {    
      setLoading(true);
      var quotation_data =

      {
          "nationality":formData.nationality,
          "civil_id": formData.civilIDno,
          "personalInformation-fullName": formData.fullName,
          "personalInformation-email":formData.email,
          "personalInformation-mobileNumber":formData.mobileNumber,
         "personalInformation-resident_location":formData.resident_location,
         "homeOwnerType":"owner",
        "section_1-selected":ischeckedsec1,
       "section_2-selected":ischeckedsec2,
       "section_3-selected":ischeckedsec3,
"section_4-selected":ischeckedsec4,
"section_5-selected":ischeckedsec5,
"section_6-selected":ischeckedsec6,
"section_7-selected":ischeckedsec7,
"section_8-selected":ischeckedsec8,
"section_1-sum_assured":sumAssured,
"section_3-sum_assured":sec3sumAssured,
"section_5-sum_assured":sec5sumAssured,
"home_details-business_of_insured":"Employee",
"home_details-age_of_building":formData.agebuilding,
"home_details-type_of_building":buildingtype,        //formData.buildingtype
"home_details-floor_no":floorno,//formData.floor_no,                      
"home_details-is_property_in_basement":questiontype?.question,
"home_details-is_whole_building_residential":questiontype?.question1,
"home_details-not_residential_reason":"test",
"home_details-address":address,
"home_details-have_fire_alarm_system":questiontype?.question2,
"home_details-have_portable_extinguishers":questiontype?.question3,
"home_details-have_hydrant_system":questiontype?.question4,
"home_details-other_fire_protection":Protection ||"not available",
"home_details-have_surveillance_camera":questiontype?.question5,
"home_details-have_burglar_alarm_system":questiontype?.question6,
"home_details-have_grilled_doors":questiontype?.question7,
"home_details-have_watchman_service":questiontype?.question8,
"home_details-have_security_checkpoint":questiontype?.question9,
"home_details-other_security_system":security||"not available",
"home_details-previous_insurance_details":"not available",
"token": accessToken
             };
    
      const response = await fetch(base_url + '/get_owner_Quotations', {
          method: 'POST',
          headers: api_headers,
          body: JSON.stringify(quotation_data)
      });
      const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
      debugger
      // setLoading(false);
      if (data.message === 'success') {
      
       
          setQuotationData(data?.result?.data?.amount);
          // await addPersonalPolicy(accessToken, data?.result?.data?.amount);
          //  setPremium(data?.result?.data?.amount?.premium_details)
         return data?.result;
          } 
          
          else {
          setQuotationData(null);
          setPremium(null);
      }
  } catch (error) {
      setQuotationData(null);
      // return null;
  }
};




const addPersonalPolicy = async (accessToken, quotationData) => {
  debugger
  try {
    // setLoading(true);
      var policy_data =
      { 
        "token": accessToken,
          "sanad_username": sanadUserName,
          "sanad_requesteename": sanadRequesteeName,
          "sanad_reference_no": sanadReferenceNumber,
          "sanad_civil_no": sanadCivilIDNumber,
          "sanad_passport_no": '',
          "sanad_service_requestid":sanadServiceRequestId,
          "sanad_data": {
              "sanadUserName":sanadUserName,
              "sanad_requesteename": sanadRequesteeName,
              "sanadUserMobileNumber": sanadUserMobileNumber,
              "sanadReferenceNumber": sanadReferenceNumber,
              "sanadCivilIDNumber": sanadCivilIDNumber,
              "sanad_ServiceRequestId": sanadServiceRequestId,
              "sanad_TransDate": sanadTransDate,
              "sanad_ReturnUrl": sanadReturnUrl,
              "sanad_ErrorUrl":sanadErrorUrl,
              "sanad_BackUrl":sanadBackUrl,
              "sanad_Language": sanadLanguage,
              "sanad_passport_no": ''
          },
        "policy_data": {
          "nationality":formData?.nationality,
          "civil_id": formData?.civilIDno,
          "personalInformation_fullName": formData?.fullName,
          "personalInformation_email":formData?.email,
          "personalInformation_mobileNumber":formData?.mobileNumber,
         "personalInformation_resident_location":formData?.resident_location,
         "homeOwnerType":"owner",
        "section_1_selected":ischeckedsec1,
       "section_2_selected":ischeckedsec2,
       "section_3_selected":ischeckedsec3,
      "section_4_selected":ischeckedsec4,
      "section_5_selected":ischeckedsec5,
      "section_6_selected":ischeckedsec6,
      "section_7_selected":ischeckedsec7,
      "section_8_selected":ischeckedsec8,
      "section_1_sum_assured":sumAssured,
      "section_3_sum_assured":sec3sumAssured,
      "section_5_sum_assured":sec5sumAssured,
      "home_details_business_of_insured":"Employee",
      "home_details_age_of_building":formData?.agebuilding,
      "home_details_type_of_building":buildingtype, 
      "home_details_floor_no":floorno,                  
       "home_details_is_property_in_basement":questiontype?.question,
      "home_details_is_whole_building_residential":questiontype?.question1,
       "home_details_not_residential_reason":"test",
"home_details_address":address,
"home_details_have_fire_alarm_system":questiontype?.question2,
"home_details_have_portable_extinguishers":questiontype?.question3,
"home_details_have_hydrant_system":questiontype?.question4,
"home_details_other_fire_protection":Protection ||"not available",
"home_details_have_surveillance_camera":questiontype?.question5,
"home_details_have_burglar_alarm_system":questiontype?.question6,
"home_details_have_grilled_doors":questiontype?.question7,
"home_details_have_watchman_service":questiontype?.question8,
"home_details_have_security_checkpoint":questiontype?.question9,
"home_details_other_security_system":security||"not available",
"home_details_previous_insurance_details":"not available",
"question_have_insurance_declined_before":questionsData[0]?.isChecked_answer,
"question_have_insurance_cancelled_by_other_company":questionsData[1]?.isChecked_answer,
"question_have_any_special_conditions_imposed":questionsData[2]?.isChecked_answer,
"question_have_any_mortgage":questionsData[3]?.isChecked_answer,
"question_has_any_claims":questionsData[4]?.isChecked_answer,
"amount_base_price":quotationData?.base_price,
"amount_total":quotationData?.total,
"amount_policy_fee":quotationData?.policy_fee,
"amount_government_fee":quotationData?.government_fee,
"amount_emergency_fund_fee":quotationData?.emergency_fund_fee,
"amount_vat":quotationData?.vat,
"amount_premium_details_section_1_premium":quotationData?.premium_details?.section_1_premium,
"amount_premium_details_section_2_premium":quotationData?.premium_details?.section_2_premium,
"amount_premium_details_section_3_premium":quotationData?.premium_details?.section_3_premium,
"amount_premium_details_section_4_premium":quotationData?.premium_details?.section_4_premium,
"amount_premium_details_section_5_premium":quotationData?.premium_details?.section_5_premium,
"amount_premium_details_section_6_premium_personal":quotationData?.premium_details?.section_6_premium_personal,
"amount_premium_details_section_6_premium_ownership_type":quotationData?.premium_details?.section_6_premium_ownership_type,
"amount_premium_details_section_7_premium":quotationData?.premium_details?.section_7_premium,
"amount_premium_details_section_8_premium":quotationData?.premium_details?.section_8_premium,
"amount_premium_details_total_premium":quotationData?.premium_details?.total_premium,
"total_property_photograph_count":3,
"signature": signatureRef.current.toDataURL(),
"occupation":selectedoccupation,         
"occupation_code":selectedCode,
"id_expiry_date":formData.expiryDateof

        }
      };
      debugger
   
      const document = new FormData();
      document.append( "property_photograph_1", propertyphoto_1);
      document.append( "property_photograph_2", propertyphoto_2);
      document.append( "property_photograph_3", propertyphoto_3);
      document.append( "policy_data" ,JSON.stringify(policy_data));

      const response = await fetch(base_url +'/get_ownerpolicyno', {
          method: 'POST',
          headers:{ "Authorization":`Bearer ${ vendor_key}`
        },
          body:document

     });
console.log("document",document)

const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
      if(data.message === 'success'&& data.result==='sanad reference number already exist'){
        setLoading(false);
          alert('Sanad reference number already exists. Please create a new reference number.\nالرقم المرجعي لسند موجود بالفعل. الرجاء إنشاء رقم مرجعي جديد.');
      }else if (data.message === 'success') {
        setLoading(false);
          if (data?.result?.policy_number != null && data?.result?.policy_number != undefined) {
             setDone(true);
              setPersonalPolicyData(data?.result?.policy_number);
              await createPolicyPaymentLink(accessToken, data?.result?.policy_number);
          } else {
              if (data?.result?.data?.decision_reasons != undefined && data?.result?.data?.decision_reasons[0] != undefined) {
                  // alert(data?.result?.data?.decision_reasons[0]);
                     setDone(true);
                  //setModalMessage(data?.result?.data?.decision_reasons[0]);
                  //setModalMessageNO(data?.result?.data?.referal_quote_id);
                  //setModalMessagePolicy(data?.result?.message);
                   //setIsModalOpen(true);
              }

          }

      } else {
          setPersonalPolicyData(null);
      }
  } catch (error) {
      setPersonalPolicyData(null);
      // return null;
  }

}



const createPolicyPaymentLink = async (accessToken, policy_no) => {
  try {
      var policy_data =
      {
          "token": accessToken,
          "policy_no": policy_no,
          "redirect_url":  redirecturl+"/sanadhome-downloadpolicy?policyno="+policy_no       
      }
      debugger
      const response = await fetch(base_url + '/get_paymentlink', {
          method: 'POST',
          headers: api_headers,
          body: JSON.stringify(policy_data)
      });
      const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
      if (data.message === 'success') {
          setPersonalPolicyPaymentLink(data?.result?.data.payment_url);
          // window.location.href = data?.result?.data?.payment_url
          // setCountryList(data.result.data);
          // setRelationList(data?.result?.data);
      } else {
          setPersonalPolicyPaymentLink(null);
      }
  } catch (error) {
      setPersonalPolicyPaymentLink(null);
      // return null;
  }
};


  return (
    isHidePage === 'success' ? <Fragment>
      <form onSubmit={handleSubmit} onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault(); // Prevent Enter key from submitting the form
        }
      }} className="visa-form1">
        <div>
          <div style={{ display: 'flex', paddingBottom: '20px' }}>
            <button style={{ width: '100px', fontWeight: 'bold' }} onClick={() => {
              const regex = /^(ftp|http|https):\/\/[^ "]+$/;
              var isValidUrl = regex.test(sanadBackUrl);
              if (isValidUrl) {
                window.location.href = sanadBackUrl; // Open in the same tab
              } else {
                window.location.reload();
              }

            }}

            >
              Back</button>
          </div>
        </div>
        

          <div className="row">
            <div className="col-md-6">
              <div className='inside_visa-form mt-3'>
                <div class="card mb-3 ">
                  <div class="p-2">

                    <h4 class="card-title" style={{ "color": "#f95845" }}>Note:</h4>
                    <p>Section 1 or Section 5 are mandatory. You can select either of them or both.</p>
                  </div>
                </div>


                <div className=" card mb-3 border-warning">
                  {/* <Card.Body> */}
                  <div className="d-flex justify-content-between align-items-center p-2">
                    <div>
                      <h4 className="text-muted">Section 1</h4>

                      <ul className="mb-3" style={{ listStyleType: 'none' }}>
                        <li>Home Contents Cover</li>
                        <li>Contents Temporarily Removed</li>
                        <li>Replacement of Locks and Keys</li>
                        <li>Contents in Open</li>
                        <li>Stock in Deep Freezer / Refrigerator</li>
                      </ul>
                    </div>

                    <input type="checkbox"
                      checked={ischeckedsec1===1}
                      onChange={handleCheckboxChange}
                       ref={section1Ref}  />
                  </div>
                  {isExpanded && (
                    <div className="p-3 border rounded">
                      <h5>Sum Assured</h5>
                      <input
                        type="text"
                        className="form-control mb-1 text-center font-weight-bold"
                        value={sumAssured}
                        readOnly
                      />
                      {errorsec1 && <p style={{ color: "red" }}>{errorsec1}</p>}
                      <div className="button-grid d-grid gap-3"
                        style={{
                          gridTemplateColumns: 'repeat(4, 1fr)',
                          gridGap: '10px',
                          textAlign: 'center'
                        }}>



                        <div className="button increase">
                          <button className="btn btn-outline-info m-2"
                            style={{
                              backgroundColor: '#33d0f2',
                              color: 'white',
                              padding: '5px 10px',
                              fontSize: '12px'

                            }} onClick={() => handleChange(100)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                            </svg> 100 </button>
                          <button className="btn btn-outline-info m-2"

                            style={{
                              backgroundColor: '#33d0f2',
                              color: 'white',
                              padding: '5px 10px',
                              fontSize: '12px'
                            }} onClick={() => handleChange(500)}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                            </svg> 500</button>
                          <button className="btn btn-outline-info m-2"
                            style={{
                              backgroundColor: '#33d0f2',
                              color: 'white',
                              padding: '5px 10px',
                              fontSize: '12px'
                            }} onClick={() => handleChange(1000)}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                            </svg> 1000</button>
                          <button className="btn btn-outline-info m-2"
                            style={{
                              backgroundColor: '#33d0f2',
                              color: 'white',
                              padding: '5px 10px',
                              fontSize: '12px'
                            }} onClick={() => handleChange(10000)}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                            </svg> 10000</button>
                        </div>
                        <button className="btn btn-outline-secondary m-2"

                          style={{
                            backgroundColor: '#545b62',
                            color: 'white', padding: '5px 10px',
                            fontSize: '12px'
                          }} onClick={() => handleChange(-100)} disabled={sumAssured < 100}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z" />
                          </svg> 100</button>
                        <button className="btn btn-outline-secondary m-2"

                          style={{
                            backgroundColor: '#545b62',
                            color: 'white', padding: '5px 10px',
                            fontSize: '12px'
                          }} onClick={() => handleChange(-500)} disabled={sumAssured < 500}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z" />
                          </svg> 500</button>
                        <button className="btn btn-outline-secondary m-2"
                          style={{
                            backgroundColor: '#545b62',
                            color: 'white',
                            padding: '5px 10px',
                            fontSize: '12px'
                          }} onClick={() => handleChange(-1000)} disabled={sumAssured < 1000}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z" />
                          </svg> 1000</button>
                        <button className="btn btn-outline-secondary m-2"
                          style={{
                            backgroundColor: '#545b62',
                            color: 'white', padding: '5px 10px',
                            fontSize: '12px'
                          }} onClick={() => handleChange(-10000)} disabled={sumAssured < 10000}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z" />
                          </svg> 10000</button>
                      </div>

                    </div>
                  )}
                  {/* </Card.Body> */}
                </div>




                <div className=" card mb-3">
                  {/* <Card.Body> */}
                  <div className=" d-flex justify-content-between align-items-center p-2">
                    <div>
                      <h4 className="text-muted">Section 2</h4>
                      Accidental Damage to Contents
                    </div>
                    <input type="checkbox"    checked={ischeckedsec2===1}
                      onChange={handleCheckboxChangeofsection2}style={{ cursor: 'pointer' }} />
                  </div>
                  {/* </Card.Body> */}
                </div>

                {/* Section 3 */}
                <card className=" card mb-3">

                  {/* <Card.Body> */}
                  <div className="d-flex justify-content-between align-items-center p-2">
                    <div>
                      <h4 className="text-muted">Section 3</h4>
                      Personal Belongings, Valuables
                    </div>
                    <input
                      type="checkbox"
                       checked={ischeckedsec3===1}
                      onChange={handleCheckboxChangeofsection3} />
                  </div>
                  {isExpandedofsec3 && (
                    <div className="p-3 border rounded">
                      <h5>Sum Assured</h5>
                      <input
                        type="text"
                        className="form-control mb-3 text-center font-weight-bold"
                        value={sec3sumAssured}
                        readOnly
                      />
                      {errorsec3 && <p style={{ color: "red" }}>{errorsec3}</p>}
                      <div className="d-flex justify-content-between flex-wrap">

                        <div className="button increase">
                          <button className="btn btn-outline-info m-2"
                            style={{
                              backgroundColor: '#33d0f2',
                              color: 'white', padding: '5px 10px',
                              fontSize: '12px'

                            }} onClick={() => handleChangeofsection3(100)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                            </svg> 100 </button>




                          <button className="btn btn-outline-info m-2"
                            style={{
                              backgroundColor: '#33d0f2',
                              color: 'white', padding: '5px 10px',
                              fontSize: '12px'

                            }} onClick={() => handleChangeofsection3(500)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                            </svg> 500 </button>

                        </div>
                        <div className="button decrease">
                          <button className="btn btn-outline-secondary m-2"
                            style={{
                              backgroundColor: '#545b62',
                              color: 'white',
                              padding: '5px 10px',
                              fontSize: '12px'
                            }} onClick={() => handleChangeofsection3(-100)} disabled={sec3sumAssured < 100}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z" />
                            </svg> 100</button>
                          <button className="btn btn-outline-secondary m-2"

                            style={{
                              backgroundColor: '#545b62',
                              color: 'white', padding: '5px 10px',
                              fontSize: '12px'
                            }} onClick={() => handleChangeofsection3(-500)} disabled={sec3sumAssured < 500}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z" />
                            </svg> 500</button>
                        </div>

                      </div>
                    </div>
                  )}
                  {/* </Card.Body> */}
                </card>

                {/* Section 4 */}

                <div className="card mb-3">
                  {/* <Card.Body> */}
                  <div className=" d-flex justify-content-between align-items-center p-2">
                    <div>
                      <h4 className="text-muted">Section 4</h4>
                      Personal Documents, Credit Card, Money
                    </div>
                    <input type="checkbox" checked={ischeckedsec4===1}
                      onChange={handleCheckboxChangeofsection4} />
                  </div>
                  {/* </Card.Body> */}
                </div>



                <div className="card mb-3 border-warning" >
                  {/* <Card.Body> */}
                  <div className=" card-body d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="text-muted">Section 5</h4>

                      Building(s) Cover
                    </div>
                    <input type="checkbox"
                      //checked={isExpandedofsec5}
                      checked={ischeckedsec5===1}
                      ref={section5Ref}
                      onChange={handleCheckboxChangeofsection5} />
                  </div>


                  {isExpandedofsec5 && (
                    <div className="p-3 border rounded">
                      <h5>Sum Assured</h5>
                      <input
                        type="text"
                        className="form-control mb-3 text-center font-weight-bold"
                        value={sec5sumAssured}
                        readOnly
                      />



                      {errorsec5 && <p style={{ color: "red" }}>{errorsec5}</p>}
                      <div className="button-grid d-grid gap-3"
                        style={{
                          gridTemplateColumns: 'repeat(4, 1fr)',
                          gridGap: '10px',
                          textAlign: 'center'
                        }} />



                      <div className=" button increase " style={{ marginLeft: '25%' }}>
                        <button className="btn btn-outline-info m-2 "
                          style={{
                            backgroundColor: '#33d0f2',
                            color: 'white', padding: '5px 10px',
                            fontSize: '12px'

                          }} onClick={() => handleChangeofsection5(100)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                          </svg> 100 </button>




                        <button className="btn btn-outline-info m-2"
                          style={{
                            backgroundColor: '#33d0f2',
                            color: 'white', padding: '5px 10px',
                            fontSize: '12px'

                          }} onClick={() => handleChangeofsection5(1000)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                          </svg> 1000 </button>


                        <button className="btn btn-outline-info m-2"
                          style={{
                            backgroundColor: '#33d0f2',
                            color: 'white', padding: '5px 10px',
                            fontSize: '12px'

                          }} onClick={() => handleChangeofsection5(10000)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                          </svg> 10000 </button>

                      </div>

                      <div className="button increase " style={{ marginLeft: '25%' }}>
                        <button className="btn btn-outline-secondary m-2"
                          style={{
                            backgroundColor: '#545b62',
                            color: 'white', padding: '5px 10px',
                            fontSize: '12px'
                          }} onClick={() => handleChangeofsection5(-100)} disabled={sec5sumAssured < 100}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z" />
                          </svg> 100</button>



                        <button className="btn btn-outline-secondary m-2"
                          style={{
                            backgroundColor: '#545b62',
                            color: 'white', padding: '5px 10px',
                            fontSize: '12px'
                          }} onClick={() => handleChangeofsection5(-1000)} disabled={sec5sumAssured < 1000}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z" />
                          </svg> 1000</button>



                        <button className="btn btn-outline-secondary m-2"
                          style={{
                            backgroundColor: '#545b62',
                            color: 'white', padding: '5px 10px',
                            fontSize: '12px'
                          }} onClick={() => handleChangeofsection5(-10000)} disabled={sec5sumAssured < 10000}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z" />
                          </svg> 10000</button>
                      </div>
                    </div>

                  )}
                  {/* </Card.Body> */}
                </div>

                {/* Section 6 */}

                <div className="card mb-3">
                  {/* <Card.Body> */}
                  <div className=" d-flex justify-content-between align-items-center p-2">
                    <div>
                      <h4 className="text-muted">Section 6</h4>
                      <ul style={{ listStyleType: 'none' }} className="mb-3">
                        <li>Liabilities Cover</li>
                        <li>Personal Liability</li>
                        <li>Liability as Owner of the insured Building</li>
                      </ul>
                    </div>
                    <input type="checkbox" checked={ischeckedsec6===1}
                      onChange={handleCheckboxChangeofsection6} />
                  </div>
                  {/* </Card.Body> */}
                </div>


                {/* Section 7 */}


                <div className=" card mb-3">
                  {/* <Card.Body> */}
                  <div className="d-flex justify-content-between align-items-center p-2">
                    <div>
                      <h4 className="text-muted">Section 7</h4>
                      Rent and Alternative Accommodation
                    </div>
                    <input type="checkbox" checked={ischeckedsec7===1}
                      onChange={handleCheckboxChangeofsection7} />
                  </div>
                  {/* </Card.Body> */}
                </div>


                {/* Section 8 */}

                <div className="card mb-3">
                  {/* <Card.Body> */}
                  <div className="d-flex justify-content-between align-items-center p-2">
                    <div>
                      <h4 className="text-muted">Section 8</h4>
                      Death Cover
                    </div>
                    <input type="checkbox" checked={ischeckedsec8===1}
                      onChange={handleCheckboxChangeofsection8} />
                  </div>
                  {/* </Card.Body> */}
                </div>
              </div>




            </div>

            <div className="col-md-6">
            <div className='inside_visa-form mt-3'>
              <h1>Property Insurance Form</h1>

              <form>

                {formdetails.map(item => (
                  <div key={item.key}>

                    <form onSubmit={" "}>
                      {item.type === 'Number' && item.key === 'age_of_building' &&
                        <div className="input-group mb-2 mt-2">
                          <div className="input-group-prepend prepends">
                          </div>

                          <input type="number" placeholder={(lnstr === 'en') ? item.text_english : item.text_arabi} name="age_of_building" 
                          className="form-control" value={formData.agebuilding } onChange={handleChangeage} 
                           onBlur={handleageofbuildingBlur} required />
                        </div>
                      }
                         {item.key === 'age_of_building' &&  <p  style={{ color: 'red' }}>{ageError}</p>}
                          
                      {item.type === 'Select' && item.key === 'type_of_building' && <div className="input-group mb-2 mt-2">
                        <div className="input-group-prepend prepends">
                        </div>
                        <select className="form-control" //ref={type_of_building} 
                        ref={buildingRef}
                        //name={item.key} //value={formData.buildingtype} 
                        value={buildingtype} 
                        onChange={handleChangeBuildingType} 
                        required>
                          <option value='' disabled selected >{(lnstr === 'en') ? item.text_english : item.text_arabi}</option>
                          {item.options.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                    

                      
                         {/* {typeError && <p style={{ color: 'red' }}>{typeError}</p>} */}

                      </div>
                      
                      }
                        {item.type === 'Select' && item.key === 'type_of_building' &&  <div  style={{
                      color: typeError ? 'red' : 'black'}}>
                    House/Villa, Apartment/Flat or Others{typeError && `  ${typeError}`}  </div>
}
                      

                      {item.type === 'Select' && item.key === 'floor_no' && <div className="input-group mb-2 mt-2">
                        <div className="input-group-prepend prepends">   

                        </div>
                        <select className="form-control" ref={floorRef} 
                        name={item.key}
                         //value={formData.floor_no} 
                         value={floorno}
                          onChange={handleChangeFloorno} 
                        required>
                          <option value='' disabled selected >{(lnstr === 'en') ? item.text_english : item.text_arabi}</option>
                          {item.options.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        {/* {floorError  && <p style={{ color: 'red' }}>{floorError}</p>} */}
                      </div>
                      }
                        {item.key === 'floor_no' &&  < p style={{ color: 'red' }}>{floorError}</p>}


                      {item.type === 'Yes-No' && item.key === 'is_property_in_basement' && (<div>
                        <p>{(lnstr === 'en') ? item.text_english : item.text_arabi}</p>
                        <div className="custom-radio" style={{ color: '#3fd2f2' }}>
                          <input
                            type="radio"
                            id="yes"
                            ref={basementRef}
                            name="question"
                            value="Yes"
                            onChange={handleChangeforquestion}   
                          />
                          <label for="yes">Yes</label>

                          <input
                            type="radio"
                            id="no"
                            name="question"
                            value="No"
                            onChange={handleChangeforquestion}
                          />
                          <label for="no">No</label>
                        </div> <br></br></div>

                      )}

                      {item.type === 'Yes-No' && item.key === 'is_whole_building_residential' && (<div>
                        <p>{(lnstr === 'en') ? item.text_english : item.text_arabi}</p>
                        <div className="custom-radio" style={{ color: '#3fd2f2' }}>
                          <input
                            type="radio"
                            id="yes1"
                            name="question1"
                            value="Yes"
                            onChange={handleChangeforquestion}
                          />
                          <label for="yes1">Yes</label>

                          <input
                            type="radio"
                            id="no1"
                            name="question1"
                            value="No"
                            onChange={handleChangeforquestion}
                          />
                          <label for="no1">No</label>
                        </div><br></br></div>
                      )}


                      {item.type === 'Text' && item.key === 'address' && 
                      <>
                      <div className="input-group mb-2 mt-2">
                        <div className="input-group-prepend prepends">
                        </div>
                        <textarea type="number" placeholder={(lnstr === 'en') ? item.text_english : item.text_arabi}
                         name="address"
                        ref={addressRef} 
                        className="form-control"
                         value={address} 
                         onChange={handleChangeAddress}
                         onBlur={handleError}
                          required />
                       
                     
                        </div>

                        
                        {item.key === 'address' && <p style={{color:'red'}}>{addressError?.addressOfPropertyErr}</p>}
                        
                        <h2 class="heading-mt mt-md-12">Type of Fire protection devices installed in premises</h2>
                        </>
                      }


                      {item.type === 'Yes-No' && item.key === 'have_fire_alarm_system' && (<div>
                        <p>{(lnstr === 'en') ? item.text_english : item.text_arabi}</p>
                        <div className="custom-radio" style={{ color: '#3fd2f2' }}>
                          <input
                            type="radio"
                            id="yes2"
                            name="question2"
                            value="Yes"
                            onChange={handleChangeforquestion}
                          />
                          <label for="yes2">Yes</label>

                          <input
                            type="radio"
                            id="no2"
                            name="question2"
                            value="No"
                            onChange={handleChangeforquestion}
                          />
                          <label for="no2">No</label>
                        </div><br></br></div>
                      )}


                      {item.type === 'Yes-No' && item.key === 'have_portable_extinguishers' && (<div>
                        <p>{(lnstr === 'en') ? item.text_english : item.text_arabi}</p>
                        <div className="custom-radio" style={{ color: '#3fd2f2' }}>
                          <input
                            type="radio"
                            id="yes3"
                            name="question3"
                            value="Yes"
                            onChange={handleChangeforquestion}
                          />
                          <label for="yes3">Yes</label>

                          <input
                            type="radio"
                            id="no3"
                            name="question3"
                            value="No"
                            onChange={handleChangeforquestion}
                          />
                          <label for="no3">No</label>
                        </div><br></br></div>
                      )}
                      {item.type === 'Yes-No' && item.key === 'have_hydrant_system' && (<div>
                        <p>{(lnstr === 'en') ? item.text_english : item.text_arabi}</p>
                        <div className="custom-radio" style={{ color: '#3fd2f2' }}>
                          <input
                            type="radio"
                            id="yes4"
                            name="question4"
                            value="Yes"
                            onChange={handleChangeforquestion}
                          />
                          <label for="yes4">Yes</label>

                          <input
                            type="radio"
                            id="no4"
                            name="question4"
                            value="No"
                            onChange={handleChangeforquestion}
                          />
                          <label for="no4">No</label>
                        </div><br></br></div>
                      )}

                      {item.type === 'Text' && item.key === 'other_fire_protection' && <div className="input-group mb-2 mt-2">
                        <div className="input-group-prepend prepends">
                        </div>
                        <input type="text" placeholder={(lnstr === 'en') ? item.text_english : item.text_arabi} name="registration" className="form-control" value={Protection}
                          onChange={handleChangeProtection}
                          required />
                        <br></br></div>
                      }


                      {item.type === 'Yes-No' && item.key === 'have_surveillance_camera' && (<div>
                        <br />
                        <h1>Security systems provided in the premises</h1>

                        <p>{(lnstr === 'en') ? item.text_english : item.text_arabi}</p>
                        <div className="custom-radio" style={{ color: '#3fd2f2' }}>
                          <input
                            type="radio"
                            id="yes5"
                            name="question5"
                            value="Yes"
                            onChange={handleChangeforquestion}
                          />
                          <label for="yes5">Yes</label>

                          <input
                            type="radio"
                            id="no5"
                            name="question5"
                            value="No"
                            onChange={handleChangeforquestion}
                          />
                          <label for="no5">No</label>
                        </div><br></br></div>
                      )}


                      {item.type === 'Yes-No' && item.key === 'have_burglar_alarm_system' && (<div>
                        <p>{(lnstr === 'en') ? item.text_english : item.text_arabi}</p>
                        <div className="custom-radio" style={{ color: '#3fd2f2' }}>
                          <input
                            type="radio"
                            id="yes6"
                            name="question6"
                            value="Yes"
                            onChange={handleChangeforquestion}
                          />
                          <label for="yes6">Yes</label>

                          <input
                            type="radio"
                            id="no6"
                            name="question6"
                            value="No"
                            onChange={handleChangeforquestion}
                          />
                          <label for="no6">No</label>
                        </div><br></br></div>
                      )}


                      {item.type === 'Yes-No' && item.key === 'have_grilled_doors' && (<div>
                        <p>{(lnstr === 'en') ? item.text_english : item.text_arabi}</p>
                        <div className="custom-radio" style={{ color: '#3fd2f2' }}>
                          <input
                            type="radio"
                            id="yes7"
                            name="question7"
                            value="Yes"
                            onChange={handleChangeforquestion}
                          />
                          <label for="yes7">Yes</label>

                          <input
                            type="radio"
                            id="no7"
                            name="question7"
                            value="No"
                            onChange={handleChangeforquestion}
                          />
                          <label for="no7">No</label>
                        </div><br></br></div>
                      )}

                      {item.type === 'Yes-No' && item.key === 'have_watchman_service' && (<div>
                        <p>{(lnstr === 'en') ? item.text_english : item.text_arabi}</p>
                        <div className="custom-radio" style={{ color: '#3fd2f2' }}>
                          <input
                            type="radio"
                            id="yes8"
                            name="question8"
                            value="Yes"
                            onChange={handleChangeforquestion}
                          />
                          <label for="yes8">Yes</label>

                          <input
                            type="radio"
                            id="no8"
                            name="question8"
                            value="No"
                            onChange={handleChangeforquestion}
                          />
                          <label for="no8">No</label>
                        </div><br></br></div>
                      )}

                      {item.type === 'Yes-No' && item.key === 'have_security_checkpoint' && (<div>
                        <p>{(lnstr === 'en') ? item.text_english : item.text_arabi}</p>
                        <div className="custom-radio" style={{ color: '#3fd2f2' }}>
                          <input
                            type="radio"
                            id="yes9"
                            name="question9"
                            value="Yes"
                            onChange={handleChangeforquestion}
                          />
                          <label for="yes9">Yes</label>

                          <input
                            type="radio"
                            id="no9"
                            name="question9"
                            value="No"
                            onChange={handleChangeforquestion}
                          />
                          <label for="no9">No</label>
                        </div><br></br></div>
                      )}


                      {item.type === 'Text' && item.key === 'other_security_system' && <div className="input-group mb-2 mt-2">
                        <div className="input-group-prepend prepends">
                        </div>
                        <input type="text" placeholder={(lnstr === 'en') ? item.text_english : item.text_arabi} name="registration" className="form-control"  value={security} onChange={handleChangeSecurity}required />
                        <br></br></div>
                      }



                    </form>
                  </div>
                ))}
              </form>


            </div>



          </div>
          </div>


            <div ref={fileInputRef} className='row mb-3 d-flex'>

              {/* File Upload Section */}
              <div class="col-md-6 mt-5">
                <div className='inside_visa-form'>
                  <h2 style={{ fontSize: "20px" }}>{(lnstr === 'en') ? "Please select Photographs of the property" : "يرجى اختيار صور فوتوغرافية لمكان الإقامة.\n"}</h2>
                  <label
                    htmlFor="file-upload"
                    className="d-flex flex-column align-items-center justify-content-center border rounded p-3"
                    style={{
                      cursor: "pointer",
                      borderStyle: "dashed",
                      borderColor: "#ccc",
                      borderWidth: "18px",
                      width: "100px",
                      height: "42px",
                      margin: "0 auto",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="currentColor"
                      className="bi bi-plus-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm0 13A6 6 0 1 1 8 2a6 6 0 0 1 0 12z" />
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>

                  </label>
                  <div className="mt-2">
                    <span className="d-flex justify-content-center mt-4">{(lnstr === 'en') ? "Add File" : "إضافة ملف\n"}</span>
                  </div>
                  <input
                    type="file"
                    id="file-upload"  
                    
                    style={{ display: "none" }}
                    
                    onChange={handleFileChange}/>


         <div className="d-flex flex-column justify-content-center mt-4">
          <p className="d-flex justify-content-center">
          <b>{`File Chosen ${fileCount}/3`}</b>
          </p>
          {propertyphoto_1 &&(
            <div className="d-flex justify-content-center">
              <p>
                <b>{propertyphoto_1?.name.length>10?propertyphoto_1.name.split(".")[0].substring(0,8)+"."+propertyphoto_1.type.split("/")[1]:propertyphoto_1.name}</b>
              </p>
              <div className="col-3 p-0 text-right text-danger cursor-pointer"
              style={{
                borderRadius:"5px",
                fontSize:"12px",
                cursor:"pointer",
                
              }}
              name="photo_1"
              onClick={handleRemove}>Remove
              </div></div>
          )}


{propertyphoto_2 &&(
            <div className="d-flex justify-content-center">
              <p className="">
                <b>{propertyphoto_2?.name.length>10?propertyphoto_2.name.split(".")[0].substring(0,8)+"."+propertyphoto_2.type.split("/")[1]:propertyphoto_2.name}</b>
              </p>
              <div className="col-3 p-0 text-right text-danger cursor-pointer"
              style={{
                borderRadius:"5px",
                fontSize:"12px",
                cursor:"pointer"
              }}
              name="photo_2"
              onClick={handleRemove}>Remove
              </div></div>
          )}

{propertyphoto_3 &&(
            <div className="d-flex justify-content-center">
              <p className="">
                <b>{propertyphoto_3?.name.length>10?propertyphoto_3.name.split(".")[0].substring(0,8)+"."+propertyphoto_3.type.split("/")[1]:propertyphoto_3.name}</b>
              </p>
              <div className="col-3 p-0 text-right text-danger"
              style={{
                borderRadius:"5px",
                fontSize:"12px",
                cursor:"pointer"
              }}
              name="photo_3"
              onClick={handleRemove}>Remove
              </div>
              </div>
          )}

{photoError  && <p  className="d-flex justify-content-center" style={{ color: 'red' }}>{photoError}</p>}
                   

</div>
</div>
</div>
            
              <div class="col-md-6 mt-5 ">
                <div className='inside_visa-form'>
                  <div>
                    <h2  style={{ fontSize: "20px" }}>{(lnstr === 'en') ? 'Choose Plan Sum Assured' : 'اختر مبلغ الخطة المؤمن'}</h2>
                    <ul style={{ listStyle: 'Circle', marginLeft: "20px" }}>
                      <li>
                        <strong>20.000 {"OMR"}</strong>
                        <p><u>Home Plan</u></p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          



          <div className='row mb-3 mt-4'>
            <div className='col-md-6 text-start'>
              <h2>Personal Details</h2>
            </div>
            <div className='col-md-6 text-right'>
              <h2>التفاصيل الشخصية    </h2>
            </div>
          </div>
          <div className='inside_visa-form'>
            <div className='row mb-3'>

              <div className='col-md-4'>

                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <span>Full Name <span style={{ color: 'red', marginLeft: '2px' }}>*</span></span>
                  <span>الاسم الكامل</span></label>
                <input
                  type='text'
                  className='form-control'
                  value={formData.fullName}
                  name='fullName'
                  onChange={handleChangeforpersonalinfo}
                  onBlur={handleNameBlur} 
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                  }}// Validate on blur (when user leaves the field)
                />
                {nameError && <p style={{ color: 'red' }}>{nameError}</p>} {/* Show error message */}
              </div>
              {/* <div className='col-md-3'>
                            <label>Full Name:</label>
                            <input
                                type='text'
                                className='form-control'
                                value={formData.fullName}
                                name='fullName'
                                onChange={handleChange}
                            />
                        </div> */}
              <div className='col-md-4'>
                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <span>
                    Email ID <span style={{ color: "red" }}>*</span>
                  </span>
                  <span style={{ textAlign: "right", direction: "rtl" }}>
                    معرف البريد الإلكتروني
                  </span>
                </label>
                <input
                  type='email'
                  className='form-control'
                  name='email'
                  value={formData.email}
                  onChange={handleChangeforpersonalinfo}
                  onBlur={handleEmailBlur} // Validate on blur (when user leaves the field)
                />
                {emailError && <p style={{ color: 'red' }}>{emailError}</p>} {/* Show error message */}
              </div>

              <div className='col-md-4'>
                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <span>
                    Mobile Number <span style={{ color: "red" }}>*</span>
                  </span>
                  <span style={{ textAlign: "right", direction: "rtl" }}>
                    رقم الجوال
                  </span>
                </label>
                <input
                  type='tel'
                  className='form-control'
                  name='mobileNumber'
                  value={formData.mobileNumber}
                  onChange={handleChangeforpersonalinfo}
                  maxLength={8} // Enforce max length in the UI
                  onBlur={handleMobileBlur} // Validate on blur (when user leaves the field)
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
                />
                {mobileError && <p style={{ color: 'red' }}>{mobileError}</p>} {/* Show error message */}
              </div>

            </div>






            <div className='row mb-3'>
              <div className='col-md-4'>

                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <span>
                    Civil ID Number <span style={{ color: "red" }}>*</span>
                  </span>
                  <span style={{ textAlign: "right", direction: "rtl" }}>
                    البطاقة المدنية
                  </span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  name='civilIDno'
                  value={formData.civilIDno}
                  onChange={handleChangeforpersonalinfo}
                  onBlur={handleCivilIDBlur} // Validate on blur (when user leaves the field)
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
              />
                {civilIDError && <p style={{ color: 'red' }}>{civilIDError}</p>} {/* Show error message */}



              </div>


              <div className='col-md-4'>
                {/* <label>Occupation <span style={{ color: 'red' }}>*</span></label> */}
                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <span>
                    Occupation <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                  </span>
                  <span style={{ textAlign: "right", direction: "rtl" }}>
                    إشغال
                  </span>
                </label>
                {/* <div className="styledata"> */}
                <select
                   ref={selectOpt1}
                  className="form-control"
                  // value={selectedoccupation}
                  
                  //onChange={handleChangeforoccupation}
                  name='occupation'
                  style={{ borderRadius: '5px' }}
            
                // Add your desired height and border-radius here
                >
                  <option >{`${t('Select Occupation')}`}</option>
                  {
                  occupationList?.map((option) => (
                    
                    <option key={option.CODE} value={option.CODE}>
                      {option.ENGLISH} - {option.ARABIC}
                      </option>
                    
                  ))}
                 
                </select>
                
                {/* </div> */}
                
                {occupationError && <p style={{ color: 'red' }}>{occupationError}</p>} {/* Show error message */}


              </div>


              <div className='col-md-4'>
                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <span>Residential Location<span style={{ color: 'red', marginLeft: '2px' }}>*</span></span>
                  <span>الموقع السكني</span></label>
                <input
                  type='text'
                  className='form-control'
                  name='resident_location'
                  value={formData.resident_location}
                  onChange={handleChangeforpersonalinfo}
                  onBlur={handleLocationBlur} // Validate on blur (when user leaves the field)
                />
                {locationError && <p style={{ color: 'red' }}>{locationError}</p>} {/* Show error message */}
              </div>



            </div>

            <div className='row mb-3'>
              <div className='col-md-4'>


                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <span>Expiry Date of Civil ID/Passport<span style={{ color: 'red', marginLeft: '2px' }}>*</span></span>
                </label>
                <input
                  type='date'
                  className='form-control'
                  name='expiryDateof'
                  value={formData.expiryDateof}
                  min={new Date().toISOString().split('T')[0]} // This sets the minimum date to today's date
                  onChange={handleChangeforpersonalinfo}
                  onBlur={handleExpriydateBlur}
                //   onKeyDown={(e) => e.preventDefault()}
                //  placeholder="dd-mm-yyyy"
                />
                 {expriydateofError && <p style={{ color: 'red' }}>{expriydateofError}</p>}
              </div>
             


              <div className='col-md-4'>
                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <span>
                    Nationality <span style={{ color: "red" }}>*</span>
                  </span>
                  <span style={{ textAlign: "right", direction: "rtl" }}>
                    جنسية
                  </span>
                </label>
                <select ref={selectnationalty} 
                  value={formData.nationality} name='nationality'
                  onChange={handleChangeforpersonalinfo}


                  className="form-control"
                  style={{ borderRadius: '5px' }} //onChange={handleChange2}
                //Add your desired height and border-radius here
                >
                  <option>{`${t('Select Nationality')}`}</option>
                  {

                    countryList?.map((option) => (
                      <option key={option} value={option}>
                        {option.charAt(0).toUpperCase() + option.slice(1)}
                      </option>
                    ))
                  }

                </select>
                {nationalityError && <p style={{ color: 'red' }}>{nationalityError}</p>}

              </div>

              <div className='col-md-4'>
                <label>VATIN (optional)</label>
                <input
                  type='text'
                  className='form-control'
                  name='vat_in'
                  //value={formData.vat_in}
                  onChange={handleChangeforpersonalinfo}
                  maxLength={15} // Limit the input to 15 characters in the UI
                  onBlur={handleVatBlur} // Validate on blur (when user leaves the field)
                />
                {vatError && <p style={{ color: 'red' }}>{vatError}</p>} {/* Show error message */}
              </div>
            </div>
          </div>


          <div className='row mb-3 mt-4'>
            <div className='col-md-6 text-start'>
              <h2>Please answer the following</h2>
            </div>
            <div className='col-md-6 text-right'>
              <h2>الرجاء الإجابة على ما يلي</h2>
            </div>
          </div>
          <div class='inside_visa-form'>



            {
              questionsData.map((question, index) => (
                <div key={index}>
                  <p>{index+1}.{question.english}</p>
                  <div className="custom-radio">
                    <input
                      type="radio"
                      id={`yes-${index}`}
                      name={`option-${index}`}
                      value="Yes"
                      checked={question.isChecked === true}
                      onChange={() => handleRadioChangequestions(index, true)}
                    />
                    <label htmlFor={`yes-${index}`}>Yes</label>
                    {/* {index == 3 ? <label htmlFor={`yes-${index}`}>Yes</label> : <label htmlFor={`yes-${index}`}>I Agree</label>} */}

                    <input
                      type="radio"
                      id={`no-${index}`}
                      name={`option-${index}`}
                      value="No"
                      checked={question.isChecked === false}
                      onChange={() => handleRadioChangequestions(index, false)}
                    />
                    <label htmlFor={`no-${index}`}>No</label>
                    {/* {index == 3 ? <label htmlFor={`no-${index}`}>No</label> : <label htmlFor={`no-${index}`}>I Disagree</label>} */}

                  </div>
                  <br />
                  { question.isChecked_answer != '' && question.accepted_answer !== question.isChecked_answer && (
                    <div className='col-md-6'>
                      <textarea
                        style={{
                          height: '100px',
                          width: '40%'
                        }}
                        value={question.details || ''} // Ensure there's a default value
                        onChange={(e) => handleDetailsChange(index, e.target.value)}
                        placeholder="Please provide more details"
                      />
                    </div>
                  )}
                </div>
              ))}
            <b>Please put your signature here</b><br />
            <SignatureCanvas
               ref={signatureRef}
              penColor="black"
              canvasProps={{ width: 340, height: 200, className: 'sigCanvas' }}
              style={{ border: '1px solid #ccc', backgroundColor: 'white' }} // Ensure background is white
            onEnd={handleSignature} // Detect when drawing ends
            />
            <div>
              <button type="button"
                style={{ backgroundColor: !isSignatureEmpty ? '#1D3561' : 'grey', borderColor: '#1D3561' }}
                className="rounded-pill text-white my-3 py-1 " onClick={async () => {
                  clearSignature();
                }}
              disabled={isSignatureEmpty}
              >
                Clear Signature
              </button>

            </div>


            <p ><input type="checkbox"
               checked={isChecked}
               onChange={handleCheckboxChangequestions} 
              name="note" />
              {"I/We hereby declare that to the best of my/our knowledge and belief; the above statements and particulars as furnished herein are complete in all form and true and that I/We have not misstated or misrepresented or suppressed any material facts. (A material fact is one which is likely to influence Dhofar Insurance company acceptance or assessment of this proposal. If in any doubt whether facts are material, they should be disclosed). Submitting this form does not bind the Proposer to complete the Insurance, nor Dhofar Insurance company to accept, but it is agreed that this form shall be the basis of the contract should a policy be issued."}
            </p>
              <div className='col-md-2 '>
              {!done&&(<button type="button"   //disabled={isChecked ? false : true }
                style={{ backgroundColor: isChecked ? '#1D3561' : "grey", borderColor: '#1D3561' }}
                className="rounded-pill text-white my-3 py-1 "
                onClick={async () => {

                  const englishLetterPattern = /^[a-zA-Z\s]*$/;
                  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                  const civilIDPattern = /^\d{4,9}$/;
                  const passportPattern = /^[a-zA-Z0-9]*$/;
                  const locationPattern = /^[a-zA-Z\s,-]+$/;
                  const agePattern = /^[0-9]+$/;

                  setNameError('');
                  setEmailError('');
                  setMobileError('');
                  setCivilIDError('');
                  setOccupationError('');
                  setLocationError('');
                  setExpriydateError('');
                  setNationalityError("");
                  setAgeError('');
                  setTypeError('');
                  setFloorError('');
                  setPhotoError('');
                
                  var question_valid = !questionsData.some(q => q.isChecked_answer === '');
                  var personal_details_valid = formData.fullName !== '' && formData.email !== '' && formData.mobileNumber !== '' && formData.dob !== '' && formData.civilIDno !== '' && formData.passportNumber !== '' && formData.height !== '' && formData.weight !== '' && selectedoccupation !== '' && formData.resident_location !== '' && formData.nationality !== '' && formData.gender !== '' && formData.is_married !== ''&&formData.expiryDateof!=='';
                  debugger
  
                  // if( sumAssured==''|| sumAssured==null || sumAssured==undefined)
                    if(! ischeckedsec1 && ! ischeckedsec5){
                    //document.getElementsByName("question9")[0].focus();
                    section1Ref.current.focus();
                    alert("Section 1 or Section 5 are mandatory. You can select either of them or both. \n القسم 1 أو القسم 5 إلزامي. يمكنك اختيار أي منهما أو كليهما.")
                  }

                  else if( ischeckedsec1 && sumAssured ==''|| sumAssured === null ||sumAssured === undefined ){
                    section1Ref.current.focus();
                    alert("Please enter sum assured for 'Section 1' must not be more than 12500 \n الرجاء إدخال مبلغ التأمين للقسم 1 ويجب ألا يزيد عن 12500");
                  }
                  else if(ischeckedsec5 && sec5sumAssured==''|| sec5sumAssured === null || sec5sumAssured === undefined ){
                    section5Ref.current.focus();
                    alert("Please enter sum assured for 'Section 5' must not be more than 50000 \n الرجاء إدخال مبلغ التأمين للقسم 5 ويجب ألا يزيد عن 50000");  
                  }
                  else if( sumAssured>12500){
                    section1Ref.current.focus();
                    alert("Please enter sum assured for 'Section 1' must not be more than 12500 \n الرجاء إدخال مبلغ التأمين للقسم 1 ويجب ألا يزيد عن 12500");
                  
                  }

                  // else if( sec5sumAssured==''|| sec5sumAssured==null || sec5sumAssured==undefined){
                  //   //document.getElementsByName("question9")[0].focus();
                  //   section5Ref.current.focus();
                  //   alert("Please choose 'Section 1' and 'Section 5' are mandatory.You must select both of them \n الرجاء اختيار القسم 1 والقسم 5 إلزاميان. ويجب عليك اختيار كليهما");
               
                  // }
                  else if( sec5sumAssured>50000){
                    section5Ref.current.focus();
                    alert("Please enter sum assured for 'Section 5' must not be more than 50000 \n الرجاء إدخال مبلغ التأمين للقسم 5 ويجب ألا يزيد عن 50000");
                  
                  }
                  else if (formData.agebuilding === '' || formData.agebuilding ===null || formData.agebuilding === undefined || !agePattern.test(formData.agebuilding)) {
                    document.getElementsByName("age_of_building")[0].focus();
                      // Check if the Civil ID matches the pattern
                      if (!agePattern .test(formData.agebuilding)) {
                        setAgeError('Please enter valid Age of Building,it accept numbers only');
                          alert('Please enter Age of Building \n الرجاء إدخال عمر البناء');
                      } else {
                        setAgeError('Please enter Age of Building');
                          alert('Please enter Age of Building \n الرجاء إدخال عمر البناء');
                      }
                    }
                  else if(buildingtype ===''|| buildingtype ===null || buildingtype ===undefined){
                      buildingRef.current.focus();
                      setTypeError('Please enter valid details')
                      alert('Please Select a Type of Building \n برجاء اختيار نوع المبنى');
                     
                         }
                  else if(floorno==''){  
                          if (floorRef.current) {
                            floorRef.current.focus();
                          }
                          setFloorError('Please enter valid details')
                          alert('Please Select Floor number \n الرجاء تحديد رقم الطابق');
                         
                             }

                else if(!questiontype?.question){
                   alert("Please select 'Yes' or 'No' for the question,Is your property in the basement? \n الرجاء اختيار نعم أو لا للسؤال، هل الممتلكات الخاصة بك في الطابق السفلي؟");
                   basementRef.current.focus();
                  } 
                else if(!questiontype?.question1){
                   alert("Please select 'Yes' or 'No' for the question,Is the whole building used for residential purposes only? \n الرجاء اختيار نعم أو لا للسؤال، هل المبنى بأكمله يستخدم للأغراض السكنية فقط؟");
                 } 
                 else if((address===""||address===null||address===undefined)){
                  addressRef.current.focus();
                  // setAddressError((data)=>({...data,addressOfPropertyErr:"Please enter valid details"}))
                  alert('Please enter the address of the property \n الرجاء إدخال عنوان العقار');
                   
                }
                

                
        else if(!questiontype?.question2){
          alert("Please select 'Yes' or 'No' for the Fire Alarm Systems question \n الرجاء تحديد نعم أو لا لسؤال أنظمة إنذار الحريق");
        }
        else if(!questiontype?.question3){
          alert("Please select 'Yes' or 'No' for the Portable Extinguishers question \n الرجاء اختيار نعم أو لا لسؤال الطفايات المحمولة");
        }
        else if(!questiontype?.question4){
          alert("Please select 'Yes' or 'No' for the Hydrant System question. \n الرجاء تحديد نعم أو لا لسؤال نظام الصنبور");
        }
        else if(!questiontype?.question5){
          alert("Please select 'Yes' or 'No' for the Surveillance Camera question. \n الرجاء اختيار نعم أو لا لسؤال كاميرا المراقبة.");
        }
        else if(!questiontype?.question6){
          alert("Please select 'Yes' or 'No' for the Burglar Alarm System question. \n الرجاء تحديد نعم أو لا لسؤال نظام إنذار ضد السرقة.");
        }
        else if(!questiontype?.question7){
          alert("Please select 'Yes' or 'No' for the Grilled Doors question. \n الرجاء اختيار نعم أو لا لسؤال الأبواب المشوية.");
        }
        else if(!questiontype?.question8){
          alert("Please select 'Yes' or 'No' for the 24 Hours watchman services question. \n الرجاء تحديد نعم أو لا لسؤال خدمات الحراسة على مدار 24 ساعة.");
        }
        else if(!questiontype?.question9){
          alert("Please select 'Yes' or 'No' for the Security check point question.\n الرجاء تحديد نعم أو لا لسؤال نقطة التفتيش الأمني ");
        }
         
        else if(!propertyphoto_1 || !propertyphoto_2 || !propertyphoto_3){
        
          fileInputRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          if (propertyphoto_1 && propertyphoto_2 && propertyphoto_3) {
            setPhotoError(''); // Clear the error if all three images are uploaded
          } else{
            setPhotoError('Please upload three photos of the property')
          alert('Please Select Photographs of the property \n برجاء اختيار صور فوتوغرافية للعقار');
         
             }
            }
                   
                  else if (formData.fullName === ''||formData.fullName === null ||formData.fullName === undefined || !englishLetterPattern.test(formData.fullName)) {
                    document.getElementsByName("fullName")[0].focus();
                    if (formData.fullName === '') {
                        setNameError('Please enter Full Name');
                        alert('Please enter Full Name\n الرجاء إدخال الاسم الكامل');
                    } else if (!englishLetterPattern.test(formData.fullName)) {
                        setNameError('Please enter Full Name, accept english letters only.');
                        alert('Please enter Full Name, accept english letters only.\n الرجاء إدخال الاسم الكامل، وقبول الحروف الإنجليزية فقط.');
                    }
                  }

                    else if (formData.email === '' || formData.email===null || formData.email=== undefined || !emailPattern.test(formData.email)) {
                      document.getElementsByName("email")[0].focus();
                      if (!emailPattern.test(formData.email)) {
                          setEmailError('Please enter valid e-mail address');
                          alert('Please enter a valid e-mail address\nيرجى إدخال عنوان بريد إلكتروني صالح');
                      } else {
                          setEmailError('Please enter e-mail address');
                          alert('Please enter e-mail address\n الرجاء إدخال عنوان البريد الإلكتروني');
                      }
                  } 

                  else if (formData.mobileNumber === '' ||formData.mobileNumber ===null ||formData.mobileNumber === undefined || formData.mobileNumber.length !== 8 || !/^\d+$/.test(formData.mobileNumber)) {
                    // Check if the mobile number has exactly 8 digits
                    document.getElementsByName("mobileNumber")[0].focus();
                    setMobileError('Please enter 8 digit phone number');
                    alert('Please enter 8 digit phone number\n الرجاء إدخال رقم الهاتف المكون من 8 أرقام ');
                  } 

                else if (formData.civilIDno === ''||formData.civilIDno ===null||formData.civilIDno === undefined || !civilIDPattern.test(formData.civilIDno)) {
                  document.getElementsByName("civilIDno")[0].focus();
                  // Check if the Civil ID matches the pattern
                  if (!civilIDPattern.test(formData.civilIDno)) {
                      setCivilIDError('Please enter valid civil id number, it accept numbers only, 4 to 9 digit.');
                      alert('Please enter valid civil id number, it accept numbers only, 4 to 9 digit.\nالرجاء إدخال رقم الهوية المدنية الصحيح، ويقبل الأرقام فقط، المكونة من 4 إلى 9 أرقام.');
                  } else {
                      setCivilIDError('Please enter civil id number.');
                      alert('Please enter civil id number.\n الرجاء إدخال رقم البطاقة المدنية');
                  }
              }
              else if(selectedoccupation ==='' ||selectedoccupation ===null || selectedoccupation ===undefined || selectedoccupation ==='Select Occupation'){
                setOccupationError('Please Select a Occupation')
                alert('Please Select a Occupation\n الرجاء تحديد المهنة');
                selectOpt1.current.focus();
                   }

                         
            else if (formData.resident_location === '' || formData.resident_location===null ||formData.resident_location===undefined || !locationPattern.test(formData.resident_location)) {
              document.getElementsByName("resident_location")[0].focus();
              if (formData.resident_location === '') {
                  setLocationError('Please enter your Residential Location');
                  alert('Please enter your Residential Location\n الرجاء إدخال موقع السكن الخاص بك');
              } else if (!locationPattern.test(formData.resident_location)) {
                  setLocationError('Please enter a valid Residential Location (letters, spaces, commas, hyphens only)');
                  alert('Please enter a valid Residential Location (letters, spaces, commas, hyphens only)\n الرجاء إدخال موقع سكن صالح (الحروف والمسافات والفواصل والواصلات فقط)');
              } 
              }

              else if(formData.expiryDateof==='' ||formData.expiryDateof===null ||formData.expiryDateof===undefined){
                document.getElementsByName("expiryDateof")[0].focus();
                setExpriydateError('Please Select a Expiry Date of Civil ID / Passport')
                alert('Please Select a Expiry Date of Civil ID / Passport\n الرجاء تحديد تاريخ انتهاء البطاقة المدنية / جواز السفر')
              } 
            

              else if(formData.nationality ===''|| formData.nationality ===null || formData.nationality === undefined){
                setNationalityError('Please Select a Nationality')
                alert('Please Select the Nationality\n الرجاء تحديد الجنسية');
                selectnationalty.current.focus();
                    }              
                    else if (!question_valid) {
                      alert('Please ensure the questions and answer\n يرجى التأكد من الأسئلة والإجابة');
                    }
                    else if( questionsData[0].isChecked_answer!=='No'){
                      alert("Please select the answer 'No' in question 1 \n الرجاء اختيار الإجابة لا في السؤال 1")
                    }
                    else if( questionsData[1].isChecked_answer!=='No'){
                      alert("Please select the answer 'No' in question 2 \n 2 الرجاء اختيار الإجابة لا في السؤال")
                    }
                    else if( questionsData[2].isChecked_answer!=='No'){
                      alert("Please select the answer 'No' in question 3 \n 3 الرجاء اختيار الإجابة لا في السؤال")
                    }
                    else if( questionsData[3].isChecked_answer!=='No'){
                      alert("Please select the answer 'No' in question 4 \n 4 الرجاء اختيار الإجابة لا في السؤال")
                    }
                    
                    else if( questionsData[4].isChecked_answer!=='No'){
                      alert("Please select the answer 'No' in question 5 \n 5 الرجاء اختيار الإجابة لا في السؤال")
                    }
             else if (signatureRef.current.isEmpty()) {
            signatureRef.current._canvas.focus();
            alert('Please put the signature\n من فضلك ضع التوقيع');
          } 
          else if (!isChecked) {
            alert('Please ensure the declaration \n يرجى التأكد من الإعلان');
           }   
       
             else {                        
                    debugger
                    setPersonalPolicyData(null);
                    setPersonalPolicyPaymentLink(null);
                    debugger
                  var quot = await getQuotation(accessTokenKey);
                    if(quot.success){
                     const quotation=quot.data?.amount
                    setPremium(quotation.premium_details)
                    await addPersonalPolicy(accessTokenKey, quotation);
                    }
                }
            }
        }
      
              >
                Proceed
              </button>)}
            </div>
             </div>




          {/* payment details */}
          <div className='row mb-3 mt-4'>
            <div className='col-md-6 text-start'>
              <h2>Payment Details</h2>
            </div>
            <div className='col-md-6 text-right'>
              <h2>تفاصيل الدفع</h2>
            </div>
          </div>

          <div class='inside_visa-form'>

            <div className="pl-1">
              <ul className="list-unstyled">
                <li><strong>Base Price : </strong>{quotationData != null && quotationData != undefined ? quotationData.base_price + " OMR" : ''}</li>
                <li><strong>Total: </strong>{quotationData != null && quotationData != undefined ? quotationData.total + " OMR" : ''}</li>
                <li><strong>Policy Fee : </strong>  {quotationData != null && quotationData != undefined ? quotationData.policy_fee + " OMR" : ''}</li>
                <li><strong>Government Fee : </strong>{quotationData != null && quotationData != undefined ? quotationData.government_fee + " OMR" : ''} </li>
                <li><strong>Emergency Fund Fee : </strong>{quotationData != null && quotationData != undefined ? quotationData.emergency_fund_fee + " OMR" : ''}</li>
                <li><strong>VAT:</strong>{quotationData != null && quotationData != undefined ? quotationData.vat + " OMR" : ''}</li><br/>
                <li><h2>Reference Number : </h2>{personalPolicyData != null && personalPolicyData != undefined ? personalPolicyData : ''}</li>

              </ul>
            </div>
          </div>
          
          <div className='row'>
                    <div className='col-md-2 text-center'>
                        <button type="button"
                            style={{ backgroundColor: personalPolicyPaymentLink != null ? '#1D3561' : 'grey', borderColor: '#1D3561' }}
                            className="rounded-pill text-white my-3 py-1 "
                            onClick={() => {

                                // resetForm();
                                window.location.href = personalPolicyPaymentLink;
                                // window.open(personalPolicyPaymentLink, '_blank')
                                // Open link in a new tab
                                // window.open(personalPolicyPaymentLink, '_blank')

                                // Reload the current page (form tab)
                                // window.location.reload();
                            }}
                            disabled={personalPolicyPaymentLink != null ? false : true}
                        >
                            Make Payment
                        </button>
                    </div>
                    <div className='col-md-4'></div>
                    <div className='col-md-4'></div>

                </div>
          </form>

          {loading && (
                <div className="overlay">
                    <div className="spinner"></div>
                </div>
            )}

          </Fragment> : <div style={{
            width: '100vw',
            height: '100vh',
            border: 'none',
            textAlign: 'center',
            marginTop: '25%',
            fontSize: '20px',
            fontWeight: 'bold'
        }}>  {isHidePage == '' ? "Loading" : "Token Invalid"}</div>
           );
              
              };
  
  
  
export default OwnerSanadHomeinsurance;