import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../../Navbar";
import Cookies from 'js-cookie';
import Footer from "../../Footer";
import { useTranslation } from 'react-i18next';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
const { config } = require('../../api/api');

function TenantPhotograph() {
 const base_url = config.baseURL;
  const lnstr = Cookies.get("langData") ? Cookies.get("langData") : "en";
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  
  const [fileName, setFileName] = useState("No File Chosen");

  const [fileCount, setFileCount] = useState("0");
  const [propertyPhoto_1, setPropertyPhoto_1] = useState(null);
  const [propertyPhoto_2, setPropertyPhoto_2] = useState(null);
  const [propertyPhoto_3, setPropertyPhoto_3] = useState(null);
  const [checkFiles,setCheckFiles]= useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const files= [propertyPhoto_1, propertyPhoto_2, propertyPhoto_3];
  const [file,setFiles]=useState([]);
  
  // Handle File Upload
  const handleFileChange =async (e) => {
    const file = e.target.files[0];
    setFileName(file ? file.name : "No File Chosen");

    // Check if a file is selected
    if (e.target.files && e.target.files.length >0) {

      const file = e.target.files[0];
      const name = e.target.name;
      const fileType = file.type;
      const maxSize = 1 * 1024 * 1024; // 5MB in bytes

      // Check if the file exceeds the size limit
      if (file && file.size > maxSize) {
        alert('File size exceeds the 1MB limit.');
        return;
      }

      if (fileType.toLowerCase().endsWith('jpg')||fileType.toLowerCase().endsWith('jpeg')||fileType.toLowerCase().endsWith('png')) {
       
         if (propertyPhoto_1 === null || propertyPhoto_1 === undefined || propertyPhoto_1 === "") {
             setPropertyPhoto_1(e.target.files[0]);
          setIsFileUploaded(true);
         } else if (propertyPhoto_2 === null || propertyPhoto_2 === undefined || propertyPhoto_2 === "") {
          setPropertyPhoto_2(e.target.files[0]);
         } else if (propertyPhoto_3 === null || propertyPhoto_3 === undefined || propertyPhoto_3 === "") {
           setPropertyPhoto_3(e.target.files[0]);
          setIsFileUploaded(true);
         }else {
           setIsFileUploaded(false);
         }
        }
       else {
        alert('Please select a valid image format (jpg, jpeg, png).');
        }
    }
    document.getElementById("file-upload").value = "";
    
  };

 useEffect(() => {
  const count = files.filter((file) => file !== null && file !== undefined && file !== "").length;
  setFileCount(count);
},[files]);

  // Handle Skip and Proceed Actions
  const handleSkip = () => {

    window.location.href = "/planofhomeinsurance";
    
  };

  const handleProceed = () => {
    const fileArray =[];
    files.forEach((file)=>{
    const reader =new FileReader();
    reader.onload=()=>{
         fileArray.push({
          base64:reader.result,
          name: file.name,
          type:file.type
         });

         if(fileArray.length===files.length){
          sessionStorage.setItem("tenantfiles",JSON.stringify(fileArray));
         }
    }
    reader.readAsDataURL(file);
    });
    setLoading(true);
    window.location.href = "/planoftenantinsurance";
  };

  
useEffect(()=>{

const storedFiles=sessionStorage.getItem("tenantfiles");
if(storedFiles){
    const fileArray=JSON.parse(storedFiles);
    const recreatedFiles =fileArray.map((file)=>{
    const arr= file.base64.split(",");
    const bstr=atob(arr[1]);
    let n = bstr.length;
    const u8arr =new Uint8Array(n);
    while(n--){
      u8arr[n]=bstr.charCodeAt(n);
    }
    return new File([u8arr],file.name,{type:file.type})
  });
  setFiles(recreatedFiles);
  recreatedFiles.forEach((file,index)=>{
    if(index===0){
  setPropertyPhoto_1(file);
    }
    if(index===1){
  setPropertyPhoto_2(file);
    }
    if(index===2){
  setPropertyPhoto_3(file);

    }
  });
}
},[])

  const handleRemove = (e) => {
    
    const name = e.target.getAttribute("name");
    //setpropertyPhoto assign null
    switch (name){
      case "photo_1":
        setPropertyPhoto_1(null);
        break;
      case "photo_2":
        setPropertyPhoto_2(null);
        break;
      case "photo_3":
        setPropertyPhoto_3(null);
        break;
      default:
    }  
    document.getElementById("file-upload").value = "";
  };



  return (
    <Fragment>
      <div className="header_section1">
        <Navbar />
        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{lnstr === "en" ? "Home Insurance" : "التأمين على المنزل"}</h3>
          </div>
        </div>
      </div>

      <div className="features_section">
        <div className="container">
          <div className="row">
            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">
              {
                <div className="container  text-center">
                  <h2>{lnstr === "en" ? "Please select Photographs of the property" : "يرجى اختيار صور فوتوغرافية لمكان الإقامة.\n"}</h2>

                  {/* File Upload Section */}
                  <div className="container text-center mt-5">
                    <label
                      htmlFor="file-upload"
                      className="d-flex flex-column align-items-center justify-content-center border rounded p-3"
                      style={{
                        cursor: "pointer",
                        borderStyle: "dashed",
                        borderColor: "#ccc",
                        borderWidth: "2px",
                        width: "55px",
                        height: "55px",
                        margin: "0 auto"
                      }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                        <path d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm0 13A6 6 0 1 1 8 2a6 6 0 0 1 0 12z" />
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                    </label>
                    <div className="mt-2">
                      <span className="mt-4">{lnstr === "en" ? "Add File" : "إضافة ملف\n"}</span>
                    </div>
                    <input type="file" id="file-upload" accept=".jpg,.jpeg,.png" style={{ display: "none" }} onChange={handleFileChange} />

                    <div className="d-flex flex-column justify-content-center mt-4">
                      <p className="">
                        <b>{` File Chosen ${fileCount}/3`}</b>
                      </p>
 {propertyPhoto_1 && (
  <div className="d-flex justify-content-center w-100 mb-2">
    <p
      className="text-left mb-2 mb-sm-0"
      style={{
        width: '100%',
        maxWidth: '150px', // max width for larger screens
        wordWrap: 'break-word',
      }}
    >
      <b>
        {propertyPhoto_1?.name.length > 10
          ? propertyPhoto_1.name.split(".")[0].substring(0, 8) +
            propertyPhoto_1.name.substring(
              propertyPhoto_1.name.lastIndexOf("."),
              propertyPhoto_1.name.length
            )
          : propertyPhoto_1.name}
      </b>
    </p>
    <div
      className="text-right text-danger cursor-pointer"
      style={{
        borderRadius: "5px",
        fontSize: "12px",
        cursor: "pointer",
        width: '100%',
        maxWidth: '90px', 
      }}
      name="photo_1"
      onClick={handleRemove}
    >
      Remove
    </div>
  </div>
)}
{propertyPhoto_2 && (
  <div className="d-flex justify-content-center w-100 mb-2">
    <p
      className="text-left mb-2 mb-sm-0"
      style={{
        width: '100%',
        maxWidth: '150px', 
        wordWrap: 'break-word',
      }}
    >
      <b>
        {propertyPhoto_2?.name.length > 10
          ? propertyPhoto_2.name.split(".")[0].substring(0, 8) +
            propertyPhoto_2.name.substring(
              propertyPhoto_2.name.lastIndexOf("."),
              propertyPhoto_2.name.length
            )
          : propertyPhoto_2.name}
      </b>
    </p>
    <div
      className="text-right text-danger cursor-pointer"
      style={{
        borderRadius: "5px",
        fontSize: "12px",
        cursor: "pointer",
        width: '100%', 
        maxWidth: '90px', 
      }}
      name="photo_2"
      onClick={handleRemove}
    >
      Remove
    </div>
  </div>
)}
{propertyPhoto_3 && (
  <div className="d-flex justify-content-center w-100 mb-2">
    <p
      className="text-left mb-2 mb-sm-0"
      style={{
        width: '100%',
        maxWidth: '150px', 
        wordWrap: 'break-word',
      }}
    >
      <b>
        {propertyPhoto_3?.name.length > 10
          ? propertyPhoto_3.name.split(".")[0].substring(0, 8) +
            propertyPhoto_3.name.substring(
              propertyPhoto_3.name.lastIndexOf("."),
              propertyPhoto_3.name.length
            )
          : propertyPhoto_3.name}
      </b>
    </p>
    <div
      className="text-right text-danger cursor-pointer"
      style={{
        borderRadius: "5px",
        fontSize: "12px",
        cursor: "pointer",
        width: '100%', 
        maxWidth: '90px', 
      }}
      name="photo_3"
      onClick={handleRemove}
    >
      Remove
    </div>
  </div>
)}
    </div>
                    {/* Buttons */}
                    <div className="d-flex justify-content-center ">
                      {/* <button
                        className="btn btn-primary rounded-pill text-white  mt-5"
                        style={{
                          width: "100px",
                          border: "none"
                        }}
                        onClick={handleSkip}>
                        {lnstr === "en" ? "Skip" : "يتخطى\n"}
                      </button> */}
                      <button type="submit" 
                      disabled={fileCount<3} onClick={handleProceed} className="btn btn-primary rounded-pill text-white  mt-5">
                        {lnstr === "en" ? "Proceed" : "يتابع\n"}
                      </button>
                    </div>
                  </div>
                </div>
              }
            </Col>

            <Col md={3}></Col>
          </div>
        </div>
      </div>

      <Footer />
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
    </Fragment>
  );
}

export default TenantPhotograph;
