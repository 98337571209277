import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { format } from 'date-fns';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';
const { config } = require('../api/api');

function LifeDownloadPersonalPolicy() {

  const base_url = config.baseURL;
  const redirect_url = config.redirectURL;
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const [vendorKey, setVendorkey] = useState('');
  const [dhofarData, setDhofarData] = useState(false);
  const [downloadpolicy, setDownloadValue] = useState('');
  const [policyLink, setPolicyLink] = useState(null);
  const [policy_no, setPolicyNo] = useState(null);
  const [age, setAge] = useState(null);


  const [payment_link, setPaymentValue] = useState('');
  const [passportFile, setPassportFile] = useState(null);
  const [questionnaireFile, setQuestionnaireFile] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [fileChoosen,setFileChoosen]=useState(true);
  
  // useEffect(() => {

  //   var quotation_data =
  //   {
  //     "token": JSON.parse(dhofarData),
  //     "policy_no": policy_no,
  //   };



  //   if (vendorKey !== '') {


  //     fetch(base_url + '/get_personal_accident_policy_details', {
  //       method: 'POST',
  //       headers: {
  //         'Authorization': `Bearer ${vendorKey}`,
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(quotation_data),
  //     })

  //       .then(response => response.json())
  //       .then(data => {

  //         debugger
  //         setDownloadValue(data.result[0]);

  //       })
  //       .catch(error => {
  //         console.error('Error posting data:', error);
  //       });

  //   }

  // }, []);

const pattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)(:[0-9]{1,5})?(\/.*)?$/;

useEffect(() => {
  const urlSearchString = window.location.search;
  const uparams = new URLSearchParams(urlSearchString);
  const policy_no = uparams.get('policyno');
  setPolicyNo(policy_no);
}, []);


useEffect(() => {
  const createOrganization = async () => {
    try {
      const postvData = { organization_name: "Superjet" };

      const response = await fetch(base_url + '/create_organization', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postvData),
      });
      const data = await response.json();
      setVendorkey(data.result[0].vendor_key);
      sessionStorage.clear()
    } catch (error) {
      console.error('Error posting data:', error);
    }
  };

  if (!vendorKey) {
    createOrganization();
  }
}, [vendorKey, base_url]);

useEffect(() => {
  const createToken = async () => {
    if (vendorKey) {
      try {
        const response = await fetch(base_url + '/create_personal_accident_token', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
          },
        });
        const data = await response.json();
        const dhdata = data.result.data.token;
        setDhofarData(dhdata);
      } catch (error) {
        console.error('Error getting token:', error);
      }
    }
  };

  createToken();
}, [vendorKey, base_url]);

useEffect(() => {
  const fetchPolicyDetails = async () => {
    if (vendorKey && dhofarData && policy_no) {
      try {
        const quotation_data = {
          token: dhofarData,
          policy_no: policy_no,
        };

        const response = await fetch(base_url + '/get_personal_accident_policy_details', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(quotation_data),
        });

        const data = await response.json();
        setDownloadValue(data.result[0]);

        const postData1 = { token: dhofarData, policy_no: policy_no };
        const response2 = await fetch(base_url + '/download_personalaccident_policy', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData1),
        });
        const data2 = await response2.json();

        if (data2.message === 'success') {
          debugger
          setPolicyLink(data2.result.data.policy_schedule_url);
        } else {
          setPolicyLink('');
        }
      } catch (error) {
        console.error('Error fetching policy details:', error);
      }
    }
  };

  fetchPolicyDetails();
}, [vendorKey, dhofarData, policy_no, base_url]);


const handleQotation = async (event) => {



  debugger

  var quotation_data =
  {
    "token": dhofarData,
    "policy_no": policy_no,
    "redirect_url": redirect_url + '/personalinformation-downloadpolicy?policyno=' + policy_no
  };
  debugger
  if (vendorKey !== '') {
    // const fetchPolicy = async () => {
    try {


      const Policyresponce = await fetch(base_url + '/create_personal_accident_payment_link', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(quotation_data),
      });

      if (!Policyresponce.ok) {
        throw new Error('Network response was not ok');
      }
      debugger

      const data = await Policyresponce.json();

      setPaymentValue(data.result.data.payment_url);
      window.location.href = data.result.data.payment_url;
      //   await handlePolicy(data.result.data.amount)
    } catch (error) {
      console.error('Error fetching occupations:', error);
    }
    // };


  }
};


const handleSubmit = async (event) => {
  event.preventDefault();

  window.location.href = '/insurance';    

};

const handlerepayment = async (event) => {
  // event.preventDefault();
  handleQotation();
}

const isValidUrl = (url) => {
  return pattern.test(url);
};

    useEffect(() => {
      if (showUploadModal) {
        document.body.style.overflow = 'hidden'; 
      } else {
        document.body.style.overflow = 'auto';
      }
      
      return () => {
        document.body.style.overflow = 'auto'; 
      };
    }, [showUploadModal]);


    
const handleUpload = async (e) => {
      debugger;
      e.preventDefault();
      const dhofar = dhofarData;
      const policy = policy_no;
      const formData = new FormData();
      formData.append('token', dhofar);
      formData.append('policy_no', policy);
  
      if (passportFile !== null) {
        formData.append('passport', passportFile);
      }
  
      // if (questionnaireFile !== null) {
      //   formData.append('questionnaire', questionnaireFile);
      // }
  
      const response = await fetch(base_url + '/get_personal_accident_uploadfile', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${vendorKey}`
        },
        body: formData
      });
      const data = await response.json();
      if (data.result.success) {
        alert(data.result.message);
      } else {
        alert('file is not uploaded try again');
      }
    };
  
    const handleFileChange = (e) => {
      // debugger;
      if (e.target.files.length === 1) {
        const file = e.target.files[0];
        const name = e.target.name;
        const fileType = file.type;
        const maxSize = 2 * 1024 * 1024; // 5MB in bytes
  
        // Check if the file exceeds the size limit
        if (file && file.size > maxSize) {
          alert('File size exceeds the 2MB limit.');
          return;
        }
  
        if (fileType === 'application/pdf') {
          if (name === 'passport' && passportFile == null) {
            setPassportFile(file);
          } else if (name === 'passport' && passportFile !== null && passportFile !== '' && passportFile !== undefined) {
            setPassportFile(null);
            setPassportFile(() => file);
          }
        } else {
          alert('Please select only PDF file.');
        }
        document.getElementById('passport').value = '';
        // document.getElementById('questionnaire').value = '';
      }
    };
    const handleFileDelete = (e) => {
      // debugger;
      const name = e.target.getAttribute('data-name');
      if (name === 'passport') {
        setPassportFile(null);
        document.getElementById('passport').value = '';
      }
    };
  
    const handleClose = () => {
      setShowUploadModal(false);
    };
  
    const handleShow = () => {
      setShowUploadModal(true);
    };
    useEffect(()=>{
    if((passportFile!==null&& passportFile!==undefined && passportFile!=="")){
      setFileChoosen(false);
    }
    else{
      setFileChoosen(true);
    }
  },[passportFile]);
  return (



      <Fragment>

          <div className="header_section1">

              <Navbar />


              <div className="container-fluid bg-breadcrumb">
                  <div className="container text-center py-5">
                      <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Download Policy - ' + policy_no : 'تحميل السياسة\n'}</h3>
                  </div>
              </div>


          </div>


          <div className="features_section">
              <div className="container">

                  <div className="row">

                      <Col md={3}></Col>

                      <Col md={6} className="myforms my-5" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                          <br />

                          <table className="table table-bordered table-condenced" style={{ textAlign: 'left' }}>

                              <tbody>

                                  <tr><td style={{ width: '50%' }}><b>Name</b>
                                      </td><td>{downloadpolicy != null ? downloadpolicy ?.first_name : ''}</td></tr>

                                  <tr><td><b>Email</b>
                                      </td><td>{downloadpolicy != null ? downloadpolicy ?.email : ''}</td></tr>
                                  


                                  <tr><td><b>Passport</b>
                                      </td><td>{downloadpolicy != null ? downloadpolicy?.passport : ''}</td></tr>
                                  

                                  <tr><td><b>Cover Type</b>
                </td><td>{downloadpolicy != null ? downloadpolicy.policy_data?.cover_type : ''}</td></tr>

                                  <tr><td><b>Civil ID</b>
                                      </td><td>{downloadpolicy != null ? downloadpolicy.policy_data?.civil_id : ''}</td></tr>

                                 

                              </tbody>

                          </table>

                          <div className="col-12 d-flex flex-column align-items-center">
  {
    policyLink != null && policyLink !== '' ? (
      <div className="row w-100 justify-content-center">
        
        {/* Download Policy Button */}
        <div className="col-lg-4 col-md-4 col-sm-8 col-8 p-1 mb-2 d-flex justify-content-center" style={{width: '33%'}}>
          <button className="btn btn-primary w-100"
            onClick={() => {
              if (policyLink) {
                var url = policyLink;
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.download = url.split('/').pop(); // Extract filename from URL
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              } else {
                alert('Policy error')
              }
            }}
          >
            Download Policy
          </button>
        </div>
        
        {/* Go To Home Button */}
        <div className="col-lg-4 col-md-4 col-sm-8 col-8 p-1 mb-2 d-flex justify-content-center" style={{width: '33%'}}>
          <button className="btn btn-primary w-100" onClick={handleSubmit}>
            Go To Home
          </button>
        </div>

        {/* Upload Documents Button */}
        <div className="col-lg-4 col-md-4 col-sm-8 col-8 p-1 mb-2 d-flex justify-content-center" style={{width: '33%'}}>
          <button type="button" className="btn btn-primary w-100 d-flex justify-content-center align-items-center" onClick={handleShow}>
            Upload Documents
          </button>
        </div>
        
      </div>
    ) : policyLink === '' ? (
      <div className="text-center">
        <h2>Policy Payment Pending</h2>
        <div>
          <button onClick={handlerepayment}
            style={{ marginLeft: 'auto', width: '33%', fontWeight: 'bold', backgroundColor: '#3fd2f2', color: 'white' }}>
            Make Payment
          </button>
        </div>
      </div>
    ) : "Loading"
  }
</div>

            </Col>
            <Col md={2}></Col>
          </div>
        </div>
      </div>
      <Footer />

      {showUploadModal && (
        <div className="modal fade show" style={{ display: 'block' }} id="uploadModal">
          <div className="modal-dialog modal-dialog-centered modal-lg" style={{ left: '10%' }}>
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title">
                  <span>
                    {t('Upload')} {t('Documents')}
                  </span>
                  <p style={{color:'red', marginLeft : '25px'}}>
                  Please upload a file format only Pdf.
                  </p>
                </h2>
                <button type="button" className="close" onClick={handleClose}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={(e) => handleUpload(e).then(handleClose)}>
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-8 col-form-label text-left">
                    <strong>{t('Passport copy With visa page or Resident ID')}</strong>
                      {/* <strong>{t('Passport copy ')}</strong>
                      <br /> <small>{t('(Attach the visa page or resident ID)')}</small> */}
                    </label>
                    <div className="col-sm-12 col-md-4">
                      {passportFile === null ? (
                        <button className="btn btn-primary" type="button" onClick={() => document.getElementById('passport').click()}>
                          {t('Upload File')}
                        </button>
                      ) : (
                        <div className="d-flex border mt-2 align-items-center p-2 rounded">
                          <p className="mb-0" style={{ width: '80%' }}>
                            {passportFile.name.length > 5
                              ? `${passportFile.name.substring(0, 5)}...${passportFile.type.split('/')[1]}`
                              : passportFile.name}
                          </p>
                          <span
                            className="text-danger ms-auto"
                            onClick={handleFileDelete}
                            data-name="passport"
                            style={{ cursor: 'pointer', width: '20%' }}>
                            &times;
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  <input
                    type="file"
                    name="passport"
                    id="passport"
                    accept="application/pdf,image/*"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  <input
                    type="file"
                    name="questionnaire"
                    id="questionnaire"
                    accept="application/pdf,image/*"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />

                  <div className="mt-4 text-center">
                    <button type="submit" disabled={fileChoosen} className="btn btn-primary">
                      {t('Submit')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

          {loading && (
              <div className="overlay">
                  <div className="spinner"></div>
              </div>
          )}

      </Fragment>

    );
}

export default LifeDownloadPersonalPolicy;