import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
//import Selectoption from "../Selectoptions";
//import Selectoptionocc from "../Selectoptionoccuption";
import $ from 'jquery';
const { config } = require('../../api/api');


function TenantPlanPersonalInformation() {

  const base_url = config.baseURL;

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  const { t } = useTranslation();





  const [formData, setFormData] = useState({
    occupation: '',
    nationality: 'Oman',
  });
  const [loading, setLoading] = useState(false);
  const [Occupations, setOccupation] = useState('');
  const [dhofarData, setDhofarData] = useState(false);
  const [age, setAge] = useState(null);
  const postData = { token: dhofarData };
  const selectOpt1 = useRef(null);
  const selectOpt2 = useRef(null);
  const [occupations, setOccupations] = useState([]);
  const [vendorKey, setVendorkey] = useState('');

  const [bmi, setBmi] = useState('0');
  const [error, setError] = useState('');
  const [natioalitys, setNationalityValue] = useState([]);
  const [nameError,setNameErr]=useState("");
  const nameRef = useRef(null);
  const emailRef=useRef(null);
  const mobileRef=useRef(null);
  const civilIdRef=useRef(null);

 const locationRef=useRef(null);
 const expiryDateRef=useRef(null);
  const [occupationError,setOccupationError]=useState("");
  const[nationalityError,setNationalityError]=useState("");
  useEffect(() => {
    if (vendorKey !== '' && (occupations === null || occupations.length === 0)) {
      const fetchOccupations = async () => {
        try {
          const response = await fetch(base_url + '/get_home_occupations', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${vendorKey}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          setOccupations(data.result.data);
        } catch (error) {
          console.error('Error fetching occupations:', error);
        }
      };

      fetchOccupations();
    }
  }, [vendorKey, occupations]);


    

 useEffect(() => {
  const initializeSelect2 = () => {
    $(selectOpt1.current).select2({
      width: "100%",
    });
  };

  const handleSelect2Change = (e) => {
    const selectedOption = occupations.find(option => option.CODE === e.params.data.id);
    setSelectedCode(e.params?.data?.id?.toString());
    setSelectedoccupation(e.params?.data?.text?.split('-')[0]?.trim());
    if(e.params?.data?.text!==""){
        setOccupationError("");
    }
    else{
      setOccupationError("Please select an occupation.");
    }
    // handleChange2();
  };

  const cleanupSelect2 = () => {
    $(selectOpt1.current).select2('destroy');
  };

  initializeSelect2();
  $(selectOpt1.current).on('select2:select', handleSelect2Change);

  return cleanupSelect2;
}, [vendorKey, occupations]);

  useEffect(() => {

    const storedDhofarString = Cookies.get('homeData'); //sessionStorage.getItem('userData')
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href='/';
    }

  }, []);



  useEffect(() => {
    const postvData = { organization_name: "Superjet" };

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {

      fetch( base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorkey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });
    }
  }, [vendorKey]);


  useEffect(() => {

    const personaljson = sessionStorage.getItem('HomeInsurancePersonalInfo');
    if (personaljson != undefined) {
      const personal = JSON.parse(personaljson);
      //   setSelectedGender(value.basicinfo_gender_detail);
      setSelectedCode(personal.occupation_code);
      setfnameValue(personal.fullName);
      setemailValue(personal.email);
      setphoneValue(personal.mobilenumber);
      setcivilidValue(personal.civilid);
      setSelectedoccupation(personal.occupation);
      setResidentialLocationValue(personal.Residential_Location);
      setexpirydateValue(personal.expiry_date);
      setSelectednationality(personal.nationality);
      setVATINValue(personal.vat_in);

    }

  }, []);

  useEffect(() => {

    if (vendorKey !== '' && (natioalitys === null || natioalitys.length === 0)){
      const fetchNationality = async () => {
        try {
  
  
          const response = await fetch(base_url + '/get_udw_countries', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${vendorKey}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
          });
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const data = await response.json();
  
          setNationalityValue(data.result.data);
        } catch (error) {
          console.error('Error fetching occupations:', error);
        }
      };
      fetchNationality();
    };
  },[vendorKey,natioalitys]);

useEffect(() => {
  const initializeSelect2 = () => {
    $(selectOpt2.current).select2({
      width: "100%",
    });
  };

  const handleSelect2Change = (e) => {
    const selectedValue = e.params.data.id;
    setSelectednationality(selectedValue);
     if(e.params.data.id!==""){
        setNationalityError("");
    }
    else{
      setNationalityError("Please select a nationality.");
    }
  };

  const cleanupSelect2 = () => {
    $(selectOpt2.current).select2('destroy');
  };

  initializeSelect2();
  $(selectOpt2.current).on('select2:select', handleSelect2Change);

  return cleanupSelect2;
}, [vendorKey, natioalitys]);
  // const today = new Date().toISOString().split('T')[0];
  const today = new Date();
  const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];


  


 

  const [selectedoccupation, setSelectedoccupation] = useState('');
  const [selectednationality, setSelectednationality] = useState("Oman");


 
  const currentdate = new Date().toISOString().split('T')[0];

  const lastNameRef = React.createRef();
  const last1NameRef = React.createRef();

  const handleKeyDown = (e, nextInputRef) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
      if (nextInputRef.current) {
        nextInputRef.current.focus(); // Focus the next input
      }
    }
  };

  const handleKeyDown1 = (e, nextInputRef) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
      if (nextInputRef.current) {
        nextInputRef.current.focus(); // Focus the next input
      }
    }
  };

  const [fnameValue, setfnameValue] = useState('');

  const handlefnameChange = (event) => {
    setfnameValue(event.target.value.trimStart());
  };

  const [emailValue, setemailValue] = useState('');
  const handleemailChange = (event) => {
    setemailValue(event.target.value);
  };

  const [phoneValue, setphoneValue] = useState('');
  const handlephoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setphoneValue(value);
  };

  const [civilidValue, setcivilidValue] = useState('');
  const handlecivilidChange = (e) => {
    const civilValue=e.target.value;
     const civilIDPattern = /^\d{0,9}$/;
   
    // Check if the Civil ID matches the pattern
    if (civilIDPattern.test(civilValue)) {
      setcivilidValue(civilValue);
    }
    };

  const [ResidentialLocationValue, setResidentialLocationValue] = useState('');
  const handleResidentialLocationChange = (event) => {
    setResidentialLocationValue(event.target.value);
  };

  const [expirydateValue, setexpirydateValue] = useState('');
  const [dateError,setDateError]=useState("");

  const handleexpirydateChange = (event) => {
   
    setexpirydateValue(event.target.value);

  };

  const handleChangenationality = (e) => {
    // setFormData({ ...formData, [name]: value });
    setSelectednationality(e.target.value)
    
  };

  const [VATINValue, setVATINValue] = useState('');
  const handleVATINChange = (event) => {
    setVATINValue(event.target.value);
  };

  useEffect(() => {

    const personaljson = sessionStorage.getItem('homeTenantPersonalInfo');
    if (personaljson != undefined) {
      const personal = JSON.parse(personaljson);
      //   setSelectedGender(value.basicinfo_gender_detail);
      setSelectedCode(personal.occupation_code);
      setfnameValue(personal.fullName);
      setemailValue(personal.email);
      setphoneValue(personal.mobilenumber);
      setcivilidValue(personal.civilid);
      setSelectedoccupation(personal.occupation);
      setResidentialLocationValue(personal.Residential_Location);
      setexpirydateValue(personal.expiry_date);
      setSelectednationality(personal.nationality);
      setVATINValue(personal.vat_in);

    }

  }, []);


  const handleSubmit = async (event) => {

    event.preventDefault();

    if (selectOpt1.current.value === `-- ${t('Select')} --`) {
      alert('Please select an occuptions.');
      return;
    }else{

      var data1 = {
        fullName: fnameValue,
        email: emailValue,
        mobilenumber: phoneValue,
        civilid: civilidValue,
        occupation: selectedoccupation,
        occupation_code: selectedCode,
        Residential_Location: ResidentialLocationValue,
        expiry_date: expirydateValue,
        nationality:selectednationality,
        vat_in: VATINValue  
      }
     
      if((data1.fullName===""||data1.fullName===null||data1.fullName===undefined)||nameError!==""){
           nameRef.current.focus();
           setNameErr("Please enter Full Name, accept english letters only.")
             return;
      }else if((data1.email===""||data1.email===null||data1.email===undefined)||emailError!==""){
          emailRef.current.focus();
          setEmailError("Please enter a valid email address. Example: example@domain.com")
          return;
      }else if((data1.mobilenumber===""||data1.mobilenumber===null||data1.mobilenumber===undefined)||mobileError!==""){
          mobileRef.current.focus();
          setMobileError("Please enter 8 digit phone number");
          return;
      }else if((data1.civilid===""||data1.civilid.length<4)||civilIDError!==""){
          civilIdRef.current.focus();
          setCivilIDError("Please enter valid civil id number, it accept numbers only, 4 to 9 digit.");
          return;
      }
      else if((data1.occupation===""||data1.mobilenumber===null||data1.mobilenumber===undefined)||occupationError!==""){
        // $(selectOpt1.current).focus();
          setOccupationError("Please select an occupation.");
          return;
      }
      else if((data1.Residential_Location===""||data1.Residential_Location===null||data1.Residential_Location===undefined)||locationError!==""){
          locationRef.current.focus();
          setLocationError("Please enter your Residential Location.");
          return;
      }else if((data1.expiry_date===""||data1.expiry_date===null||data1.expiry_date===undefined)|| dateError!==""){
          expiryDateRef.current.focus();
          setDateError("Please choose a future date.");
      }else if(data1.nationality===""||data1.nationality===null||data1.nationality===undefined){
          setNationalityError("Please choose a nationality");
      }
      else{
      sessionStorage.setItem("homeTenantPersonalInfo", JSON.stringify(data1));
      event.preventDefault();
      setLoading(true);
      // event.preventDefault();
      window.location.href = '/tenantinformationquestions';      
      }
    }

  };

  const [mobileError, setMobileError] = useState('');

  const handleMobileBlur = (e) => {
    const { value } = e.target;

    // Check if the mobile number has exactly 8 digits
    if (value.length !== 8 || !/^\d+$/.test(value)) {
      setMobileError('Please enter 8 digit phone number');
      setphoneValue('');
    } else {
      setMobileError('');
    }
  };


  const [civilIDError, setCivilIDError] = useState('');
  const handleCivilIDBlur = (e) => {
    const { value } = e.target;

    // Regular expression to allow only numbers and check length between 4 to 9 digits
    const civilIDPattern = /^\d{4,9}$/;

    // Check if the Civil ID matches the pattern
    if (!civilIDPattern.test(value)) {
      setCivilIDError('Please enter valid civil id number, it accept numbers only, 4 to 9 digit.');
      // setcivilidValue('');
    } else {
      setCivilIDError('');
    }
  };

  const [locationError, setLocationError] = useState('');

  const handleLocationBlur = (e) => {
    const { value } = e.target;

    // Optional: Regular expression to allow only letters, spaces, commas, and hyphens
    const locationPattern = /^[a-zA-Z\s,-]+$/;

    // Check if the Residential Location is empty or invalid
    if (value === '') {
      setLocationError('Please enter your Residential Location.');
      setResidentialLocationValue('');
    } else if (!locationPattern.test(value)) {
      setLocationError('Please enter a valid Residential Location (letters, spaces, commas, hyphens only)');
      setResidentialLocationValue('');
    } else {
      setLocationError('');
    }
  };

  const [emailError, setEmailError] = useState(''); 

  const handleEmailBlur = (e) => {
    const { value } = e.target;

    // Regular expression to validate a basic email format
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Check if the email matches the pattern
    if (!emailPattern.test(value)) {
      setEmailError('Please enter a valid email address. Example: example@domain.com');
      // setemailValue('');
    } else {
      setEmailError(''); // Clear the error if valid
    }
  };




  const [vatError, setVatError] = useState('');

  const handleVatBlur = (e) => {
    const { value } = e.target;

    // Regular expression to allow only letters and numbers
    const vatPattern = /^[a-zA-Z0-9]{15}$/;

    // Check if the VATIN is exactly 15 characters long and matches the pattern
    if (!vatPattern.test(value)) {
      setVatError('Please enter a valid VATIN (15 characters, letters, and numbers only)');
    } else {
      setVatError('');
    }
  };

const handleFullNameBlur=(e)=>{
   if(fnameValue.trimStart()===""){
    setNameErr("Please enter Full Name, accept english letters only.");
   }
   else{
    setNameErr("");
   }
}










  const [selectedCode, setSelectedCode] = useState('');

  const handleChange2 = () => {

    if(selectedoccupation!==""){
        setOccupationError("");
    }
    else{
      setOccupationError("Please select an occupation.");
    }
   
  };




  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Enter Personal Information  ' : 'أدخل المعلومات الشخصية\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container-fluid">

          <div className="row">

            {/* <Col md={3}></Col> */}
            <div className=" col-md-3"></div>

            {/* <Col md={6}  className="myforms my-5"> */}
            <div className="col-12 col-md-6 myforms my-5 ml-md-5" >
              {/* <div className="clformrow mt-1"> */}

<div className="row">
<div className="col-12">
                <form onSubmit={handleSubmit}>
                {/* <div className="input-group  mb-2 mt-2">
    <span className="input-group-text">
      {lnstr === 'en' ? 'Full Name' : 'الاسم الكامل'}
    </span>
  
  <input
    type="text"
    name="firstName"
    className="form-control  col-7"
    ref={nameRef}
    value={fnameValue}
    onChange={handlefnameChange}
    onBlur={handleFullNameBlur}
    onKeyDown={(e) => handleKeyDown(e, lastNameRef)}
    onInput={(e) => {
      e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
    }}
  />
</div> */}

                 <div className="input-group  mb-2 mt-2">
               
                    <div className=" input-group-prepend prepends">
                      <span className="input-group-text">{(lnstr == 'en') ? 'Full Name' : 'الاسم الكامل'} <span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                    </div>
                 
         
                    <input type="text" name="firstName" className="col-6  form-control"
                     ref={nameRef}
                      value={fnameValue} onChange={handlefnameChange} 
                      onBlur={handleFullNameBlur}
                      onKeyDown={(e) => handleKeyDown(e, lastNameRef)}
                        onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                      }} 
                     />
                   
                  </div> 

                  <div>{nameError && <p style={{ color: 'red' }}>{nameError}</p>}</div>


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Email ID')} <span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                    </div>
                    <input type="text" name="email" className="form-control col-6" value={emailValue}  ref={emailRef} 
                      onBlur={handleEmailBlur}
                      onChange={handleemailChange}  onKeyDown={(e) => handleKeyDown1(e, last1NameRef)}/>
                  </div>
                  <div>{emailError && <p style={{ color: 'red' }}>{emailError}</p>}</div>


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{(lnstr === 'en') ? 'Mobile Number' : 'رقم الهاتف المحمول'}<span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                    </div>
                    <input type="tel" name="phone" ref={mobileRef} className="form-control col-6" inputMode="numeric" value={phoneValue} maxLength={8} onBlur={handleMobileBlur}
                      onChange={handlephoneChange}  />
                  </div>
                  <div>{mobileError && <p style={{ color: 'red' }}>{mobileError}</p>}</div>


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{(lnstr === 'en') ? 'Civil ID Number' : 'رقم البطاقة المدنية'}<span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                    </div>
                    <input type="text" name="civilid" ref={civilIdRef} className="form-control col-6" inputMode="numeric"  value={civilidValue} onBlur={handleCivilIDBlur}
                      onChange={handlecivilidChange}  />
                  </div>
                  <div>{civilIDError && <p style={{ color: 'red' }}>{civilIDError}</p>}</div>


                 {/* <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{(lnstr === 'en') ? 'Passport Number' : 'رقم جواز السفر'}</span>
                    </div>
                    <input type="text" name="passportnumber" className="form-control" value={passportValue} onBlur={handlePassportBlur}
                      onChange={handlepassportChange} />
                  </div>
                  <div>{passportError && <p style={{ color: 'red' }}>{passportError}</p>}</div>
                  */}



               {/* <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{(lnstr === 'en') ? 'Occupation' : 'إشغال'}</span>
                    </div>

                    {/* <div className="styledata">
                      <Selectoptionocc name="occupation"
                        value={selectedoccupation}
                        options={occupations}
                        className='form-control select2'
                        onChange={handleChange2}
                         />
                    </div> */}
{/*
                    <div className="styledata">
                      <select
                        ref={selectOpt1}
                        className="form-control"
                        style={{ borderRadius: '5px', height: '30px' }}
                        onChange={handleChange2}
                        value={selectedCode}
                      // Add your desired height and border-radius here
                      >
                        <option>{`-- ${t('Select')} --`}</option>
                        {occupations.map((option) => (
                          <option key={option.CODE} value={option.CODE}>
                            {option.ENGLISH} - {option.ARABIC}
                          </option>
                        ))}

                      </select>
                    </div>

                  </div> */}
                
    <div className="input-group mb-2 mt-2">
      <div className="input-group-prepend">
        <label className="input-group-text">
          {lnstr === 'en' ? 'Occupation' : 'إشغال'}<span style={{color:'red', marginLeft: '5px'}}>*</span>
        </label>
      </div>
      <div className="col-6 w-100 pl-0 pr-0">
        <select ref={selectOpt1} value={selectedCode} className="form-control">
          <option value="">{`-- ${t('Select')} --`}</option>
          {occupations.map((option) => (
            <option key={option.CODE} value={option.CODE}>
              {option.ENGLISH} - {option.ARABIC}
            </option>
          ))}
        </select>
  </div>
</div>
    <div>{occupationError && <p style={{ color: 'red' }}>{occupationError}</p>}</div>


{/* 
<div className="input-group mb-2 mt-2">
  <div className="input-group-prepend ">
    <label className="input-group-text">{(lnstr === 'en') ? 'Occupation' : 'إشغال'}</label>
  </div>
<select 
// ref={selectOpt1}
 className="form-control">
      <option value="">{`-- ${t('Select')} --`}</option>
      {occupations.map((option) => (
        <option key={option.CODE} value={option.CODE}>
          {option.ENGLISH} - {option.ARABIC}
        </option>
      ))}
    </select>
</div>
<div className="row">
  <div className="col-sm-12">
<div className="input-group mb-2 mt-2">
  <div className="input-group-prepend ">
    <label className="input-group-text">{(lnstr === 'en') ? 'Occupation' : 'إشغال'}</label>
  </div>
  <div className="styledata">
    <select
      // ref={selectOpt1}
      className="form-control"
      style={{ borderRadius: '5px', height: '30px' }}
      onBlur={handleChange2} 
      value={selectedCode}
    >
      <option value="">{`-- ${t('Select')} --`}</option>
      {occupations.map((option) => (
        <option key={option.CODE} value={option.CODE}>
          {option.ENGLISH} - {option.ARABIC}
        </option>
      ))}
    </select>
  </div>
</div>
    <div>{occupationError && <p style={{ color: 'red' }}>{occupationError}</p>}</div>

</div>
</div>
 */}


                 {/* <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{(lnstr === 'en') ? 'Height (in cms)' : 'الارتفاع (سم)'} </span>
                    </div>
                    <input type="number" name="height" value={heightValue} maxLength={3} className="form-control" onBlur={handleHeightBlur} onChange={handleHeightChange} />
                  </div>
                  <div>{heightError && <p style={{ color: 'red' }}>{heightError}</p>}</div>


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{(lnstr === 'en') ? 'Weight (in kgs)' : 'الوزن (بالكجم)'}</span>
                    </div>
                    <input type="number" name="weight" value={weightValue} maxLength={2} className="form-control" onBlur={handleWeightBlur} onChange={handleWeightChange}  />
                  </div>
                  <div>{weightError && <p style={{ color: 'red' }}>{weightError}</p>}</div>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  {bmi != 0  && <p>Your Body Mass Index is {bmi}</p>}

*/}


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{(lnstr === 'en') ? 'Residential Location' : 'إشغال'}<span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                    </div>
                    <input type="text" name="ResidentialLocation" value={ResidentialLocationValue} ref={locationRef}
                     className="form-control col-6" placeholder={lnstr === 'en' ? 'For example (Muscat)' : 'على سبيل المثال (مسقط)'} onBlur={handleLocationBlur} onChange={handleResidentialLocationChange}  />
                  </div>
                  <div>{locationError && <p style={{ color: 'red' }}>{locationError}</p>}</div>

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{(lnstr === 'en') ? 'Expiry Date of Civil ID/Passport' : 'تاريخ انتهاء البطاقة المدنية / جواز السفر'}<span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                    </div>
                    <input type="date" ref={expiryDateRef} name="expirydate" value={expirydateValue} className="form-control col-6" max='2100-12-31' min={currentdate} onChange={handleexpirydateChange} onBlur={handleexpirydateChange} required />
                  </div>
                  <div>{dateError && <p style={{ color: 'red' }}>{dateError}</p>}</div>
                  


<div className="input-group mb-2 mt-2">
      <div className="input-group-prepend">
        <label className="input-group-text">{t('Nationality')}<span style={{color:'red', marginLeft: '5px'}}>*</span></label>
                  </div>

                <div className="col-6 w-100 pl-0 pr-0">
                <select   ref={selectOpt2}
                      className="form-control occupationRef"
                      // style={{ borderRadius: '5px', height: '30px' }}
                      onChange={(e)=>handleChangenationality(e)} 
                      value={selectednationality}>
                      {/* <option>{`-- ${t('Select')} --`}</option> */}
                      {natioalitys != null && natioalitys.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>  
                  </div>
                </div>
    <div>{nationalityError && <p style={{ color: 'red' }}>{nationalityError}</p>}</div>
                

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span
                        className="input-group-text">{(lnstr === 'en') ? 'VATIN (optional)' : 'ضريبة القيمة المضافة (اختياري)'}</span>
                    </div>
                    <input type="text" name="VATIN" value={VATINValue} className="form-control col-6" maxLength={15} onChange={handleVATINChange} />
                  </div>



                  <button type="submit"
                    className="btn btn-primary rounded-pill text-white my-3 py-1 px-4">{(lnstr === 'en') ? 'Proceed' : 'يتابع'}</button>


                </form>
</div>
</div>
               {/* </div> */}




</div>
            {/* </Col> */}

            <div className="col-md-3" ></div>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}

export default TenantPlanPersonalInformation;
