import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../../tr.png';
import { format } from 'date-fns';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';
const { config } = require('../../api/api');


function DownloadSanadAffordablePolicy() {

  const base_url = config.baseURL;
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

  const { t } = useTranslation();
  const vendor_key = 'c3VwZXJqZXRfc2FuYWRfcGVyc29uYWxfYWNjaWRlbnRfdmVuZG9yX2tleQ==';
  const api_headers = {
    'Authorization': 'Bearer ' + vendor_key,
    'Content-Type': 'application/json' // Ensure you're sending JSON
  }

  const [dhofarData, setDhofarData] = useState(null);
  const [policyNo, setPolicyNo] = useState(null);
  const [policyLink, setPolicyLink] = useState(null);
  const [policyDetails, setPolicyDetails] = useState(null);
  const [policyCoverDetails, setPolicyCoverDetails] = useState(null);
  const [sandaUrlData, setSandaUrlData] = useState(null);
  const [benData, benAppData] = useState(false);
  const redirect_url = "https://superjetom.com/";
  const [payment_link, setPaymentLink] = useState(null);
  const [loading, setLoading] = useState(false);


  useEffect(async () => {
    debugger
    const fetchData = async () => {
      try {
        var accessTokenData = await createAccessToken();
        var accessToken = accessTokenData?.result?.data?.token;
        if (accessToken != null && accessToken != undefined) {
          debugger
          setDhofarData(accessToken);

        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);



  useEffect(async () => {
    const urlSearchString = window.location.search;
    const uparams = new URLSearchParams(urlSearchString);
    const policy_no = uparams.get('policyno');
    setPolicyNo(policy_no);
    var policy_api_data = {
      "policy_no": policy_no
    };
    const response = await fetch(`${base_url}/get_sanad_medical_affordable_poliy_number`, {
      method: 'POST',
      headers: api_headers,
      body: JSON.stringify(policy_api_data),
    });

    const data = await response.json();

    if (data.message === 'success') {

      setPolicyDetails(data?.result[0]);

      var policy_cover_details = data?.result[0]?.policy_data;
      var policy_sanad_details = data?.result[0]?.sanad_data;
      if (policy_cover_details) {
        policy_cover_details = JSON.parse(policy_cover_details);
        setPolicyCoverDetails(policy_cover_details);
      } else {
        setPolicyCoverDetails(null);
      }

      if (policy_sanad_details) {
        policy_sanad_details = JSON.parse(policy_sanad_details);
        var url_data = {
          'back_url': policy_sanad_details.sanad_BackUrl,
          'return_url': policy_sanad_details.sanad_ReturnUrl,
          'error_url': policy_sanad_details.sanad_ErrorUrl,
      };
      setSandaUrlData(url_data);
      }
      // Handle successful response
      // Example: setPaymentUrl(data.result.data.payment_url);
    } else {
      setPolicyDetails(null);
      setPolicyCoverDetails(null);
      // Handle failure
      // setPolicyLink('');
    }
    // var url_data = Cookies.get('sanadUrlData');
    // debugger
    // if (url_data) {
    //   setSandaUrlData(JSON.parse(url_data));
    // }


  }, []);
  const handledownload = async () => {
    var datas={
      'token':dhofarData,
      'policy_no':policyNo
    };
    
      if (vendor_key !== "") {
        setLoading(true);
        try {
          const response = await fetch(base_url + "/medical_documents", {
            method: "POST",
            headers: {
              "Authorization": `Bearer ${vendor_key}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(datas),
            responseType: "blob", // Fetch response as a binary file
          });
    
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
    
          // Convert response to blob
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
    
          // Create a download link
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", policyNo+".pdf"); // Ensure correct file type
          document.body.appendChild(link);
          link.click();
          setLoading(false);
    
          // Cleanup
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
    
        } catch (error) {
          setLoading(false);  
          console.error("Error generating quotation PDF:", error);
        }
      }
    };


  const createAccessToken = async () => {
    try {
      const response = await fetch(base_url + '/sanad_access_token', {
        method: 'GET',
        headers: api_headers
      });
      const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
      if (data.message === 'success') {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };


//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         debugger
//         const postData = { token: dhofarData, policy_no: policyNo };

//         // const postData = { id: someId }; // Adjust as necessary
//         const response = await fetch(base_url + '/get_sanad_medical_affordable_policydocument', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(postData),
//         });
// debugger
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         const data = await response.json();
//         if (data.message === 'success' && data?.result?.data?.policy_schedule_url != null) {
//           setPolicyLink(data.result.data.policy_schedule_url);
//         } else {
//           setPolicyLink('');
//         }
//       } catch (error) {
//         console.error('Error fetching policy documents:', error);
//       }
//     };
//     fetchData();
//   }, [dhofarData, policyNo]);

useEffect(() => {
    const fetchPolicyDocument = async () => {
        debugger
      if (!dhofarData || !policyNo) return; // Ensure both values are available before making the API call
debugger
      try {
        const postData = { token: dhofarData, policy_no: policyNo };

        const response = await fetch(base_url + '/get_sanad_medical_affordable_policydocument', {
          method: 'POST',
          headers:api_headers,
          body: JSON.stringify(postData),
        });

        if (!response.ok) {
          setPolicyLink('');
          // throw new Error('Network response was not ok');
        }

        const data = await response.json();

        if (data.message === 'success' && data?.result?.data?.policy_schedule_url) {
          setPolicyLink(data.result.data.policy_schedule_url);
        } else {
          setPolicyLink('');
        }
      } catch (error) {
        console.error('Error fetching policy documents:', error);
      }
    };

    fetchPolicyDocument();
  }, [dhofarData, policyNo]);


  return (
    policyNo?<Fragment>
      <div className="features_section layout_padding my-5">
        <div>
          <div className="row">
            <Col md={3}></Col>
            <Col md={6} className="myforms my-3 ">
              <div style={{ display: 'flex', paddingBottom: '20px' }}>
                <button style={{ width: '100px', fontWeight: 'bold' }} onClick={() => {
                  const regex = /^(ftp|http|https):\/\/[^ "]+$/;
                  var isValidUrl = regex.test(sandaUrlData?.back_url);

                  if (isValidUrl) {
                    window.location.href = sandaUrlData?.back_url + '?policyno=' + policyDetails?.policy_no;
                    // window.open(sandaUrlData?.back_url + '?policyno=' + policyDetails?.policy_no, '_blank')
                  } else {
                    window.location.reload();
                  }

                }} >Back</button>
              </div>

              <div style={{ textAlign: 'left', fontSize: '20px' }}>
                <b>Policy Details  - {policyDetails != null ? policyDetails?.policy_no : ''}</b>
              </div>

              <br />

              <table className="table table-bordered table-condenced" style={{ textAlign: 'left' }}>

                <tbody>

                  <tr><td style={{ width: '50%' }}><b>Name</b>
                  </td><td>{policyDetails != null ? policyDetails?.first_name : ''}</td></tr>

                  <tr><td><b>Occupation</b>
                  </td><td>{policyCoverDetails != null ? policyCoverDetails?.personalInformation?.occupation?.toString() : ''}</td></tr>


                  <tr><td><b>Passport</b>
                  </td><td>{policyDetails != null ? policyDetails?.sanad_passport_no : ''}</td></tr>

                  <tr><td><b>Sanad Reference No</b>
                  </td><td>{policyDetails != null ? policyDetails?.sanad_reference_no : ''}</td></tr>

                  <tr><td><b>Civil ID</b>
                  </td><td>{policyCoverDetails != null ? policyCoverDetails?.civil_id : ''}</td></tr>

                  <tr><td><b>Total Fee (OMR)</b>
                  </td><td>{policyCoverDetails != null ? policyCoverDetails?.amount?.total?.toString() : ''}</td></tr>

                  <tr><td><b>Created</b>
                  </td><td>{policyDetails != null ? policyDetails?.created_at.toString().split('T')[0] : ''}</td></tr>

                </tbody>

              </table>
              <div className="text-center">
                {
                  policyLink != null && policyLink != '' ?
                    // true ?
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <button
                        onClick={() => {
                          debugger
                          handledownload();
                          // if (policyLink) {
                          //   var url = policyLink;
                          //   const link = document.createElement('a');
                          //   link.href = url;
                          //   link.target = '_blank';
                          //   link.download = url.split('/').pop(); // Extracts filename from URL
                          //   document.body.appendChild(link);
                          //   link.click();
                          //   document.body.removeChild(link);
                          // } else {
                          //   alert('Policy error')
                          // }
                        }}
                        style={{ fontWeight: 'bold' }}
                      >
                        Download Policy Document
                      </button>
                      <div style={{ margin: '10px 0' }} >
                        <button style={{ marginLeft: 'auto', fontWeight: 'bold' }}
                          onClick={() => {
                            debugger
                            const regex = /^(ftp|http|https):\/\/[^ "]+$/;
                            var isValidUrl = regex.test(sandaUrlData?.return_url);

                            if (isValidUrl) {
                              window.location.href =
                                sandaUrlData?.return_url +
                                '?policyno=' + policyDetails?.policy_no +
                                '&sanad_reference_no=' + policyDetails?.sanad_reference_no +
                                '&policy_link=' + policyLink;
                              // window.open(sandaUrlData?.return_url+ '?policyno=' + policyDetails?.policy_no+'&sanad_reference_no='+policyDetails?.sanad_reference_no+'&policy_link='+policyLink, '_blank')
                            } else {
                              window.location.reload();
                            }

                          }}
                        >Go To Sanad</button>
                      </div></div>
                    : policyLink === '' ? <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><h2>Policy Payment Pending</h2>
                      <div>
                        <button
                          onClick={() => {

                            if (policyDetails?.payment_link) {
                              window.location.href = policyDetails?.payment_link;

                              // Reload the current page (form tab)
                              // window.location.reload();
                            }

                          }} style={{ marginLeft: 'auto', width: '200px', fontWeight: 'bold' }}>Make Payment</button></div></div> : "Loading"
                }
              </div>
              <p></p>
             {/* {!policyLink &&(<p style={{ color: "red" }}>(This payment link is valid for a maximum of 24 hours. If the link expires, please create a new policy.\nرابط الدفع هذا صالح لمدة أقصاها 24 ساعة. إذا انتهت صلاحية الرابط، يرجى إنشاء سياسة جديدة.) </p>)} */}
             {!policyLink&&( <label style={{ color: "red" ,display: "flex", justifyContent: "space-between", alignItems: "center" }}>
             <span>
             This payment link is valid for a maximum of 24 hours. If the link expires, please create a new policy.
             </span>
             <span style={{ textAlign: "right", direction: "rtl" }}>
             رابط الدفع هذا صالح لمدة أقصاها 24 ساعة. إذا انتهت صلاحية الرابط، يرجى إنشاء سياسة جديدة.
             </span>
         </label>)}
          </Col>
            <Col md={3}></Col>
            
          </div>
        </div>
      </div>
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
    </Fragment>: <div style={{
           
            border: 'none',
            textAlign: 'center',
            marginTop: '25%',
            fontSize: '20px',
            fontWeight: 'bold'
        }}>  { "Policy Number Invalid"}</div>
  );
}

export default DownloadSanadAffordablePolicy;