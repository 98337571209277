import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';

// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import { event } from "jquery";
import $ from 'jquery';

const { config } = require('../api/api');

function HotelPayment({ initialTime = 15 * 60 }){


    const [timeLeft, setTimeLeft] = useState(initialTime);   
     
     useEffect(() => {
        if (timeLeft <= 0)
          return;
        const timer = setInterval(() => {
          setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);
        return () => clearInterval(timer);
      }, [timeLeft]);
    
    
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
      };
    

    
    const base_url = config.baseURL;
    const redirect_url = config.redirectURL;
    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
    const [loading, setLoading] = useState();

    const { t } = useTranslation();
   return (
    <>
        
        <div className="header_section1">

<Navbar />


<div className="container-fluid bg-breadcrumb">
    <div className="container text-center py-5">
        <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'e-Hotel Details' : 'تفاصيل الفندق الإلكتروني\n'}</h3>
    </div>
</div>


</div>
            <div className="features_section">
                <div className="container">

                <div className="row p-4">
            <div className="col-md-2">
            </div>
            <div className="col-md-6">
            <div className="card p-2 shadow"
              style={{
                boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
                Width: "100%"
              }}>
              <h2 className="card-title p-0" >Guests</h2>
            </div>
            <div className="card p-2 shadow mt-0">
                <table className="table table-bordered ">
                    <thead style={{backgroundColor:"#f4f6fb"}}>
                <tr>
                    <td>Room</td>
                    <td>Guests</td>
                </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Comfort Cottage</td>
                        
                        
                            <td>
                                <div className="d-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                  </svg>
                           <span className="mx-1"> menking</span>
                            </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="card p-2 shadow mt-3"
              style={{
                boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
                Width: "100%"
              }}>
                <div className="d-flex justify-content-end">
              <h3 className="card-title p-1">Order cost : 1333</h3>
              </div>
            </div>
            <div className="card p-3 shadow">
            <div className="d-flex justify-content-between">
                <button className="btn-primary text-white my-3 py-1" style={{ height: '35px', width: '100px' }} >
                  Back
                </button>
                <button className="btn-primary text-white my-3 py-1" style={{ height: '35px', width: '100px' }}>
                  Buy
                </button>
              </div>
              </div>
            </div>
            <div className="col-md-3">
            <div className="card p-3 shadow"
              style={{
                //   width: '250px',
                //   height: '160px',
                boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
                Width: "100%"
              }}>
              <h2 className="card-title p-0" >Booking details</h2>
              </div>

            <div className="card p-3 shadow">
              <div className="d-flex" >
                <h3 class="fw-bold p-0">Men'k Kings</h3>
                <div className="mx-3 mt-2" style={{ display: "flex", gap: "4px" }} >
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="orange" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="orange" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="orange" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
                    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
                    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                  </svg>
                </div>
              </div>
              <p>86A,Revolyustil highway</p>
              <div style={{
                height: "150px",
                width: "100%",
                overflow: "hidden",
                 }}>
                <img src="https://cdn.worldota.net/t/x220/content/0f/26/0f267156e844bf590f5c0ae4c84da500f3c825d3.jpeg" class="w-100 h-20 object-fit-cover" alt=""></img>
              </div>
              <table class="table table-borderless">
                <tbody>
                  <tr>
                    <th >Check-in</th>
                    <td>2025-03-20</td>
                  </tr>
                  <tr>
                    <th >Check-out</th>
                    <td>2025-03-21</td>
                  </tr>
                  <tr>
                    <th scope="row">Nights</th>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Guests</th>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th scope="row">Rooms</th>
                    <td>1</td>
                  </tr>
                </tbody>
              </table>
              <div>
                <h3>Order cost :     66 dollars</h3>
              </div>
              </div>

            <div className="card p-3 shadow"
              style={{
                //   width: '250px',
                //   height: '160px',
                boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
                Width: "100%"
              }}>
              <div className="d-flex">
                <h2 className="card-title p-0">Cancellation Policy</h2>
                 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="skyblue" class="bi bi-exclamation-circle-fill ml-2 mt-1" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
                </svg>

              </div>
              <div className="border border-info rounded p-3 bg-light">
                <h3>Attention!</h3>
                <p>From 17.03.25 10:00 (UTC) cancel penalty will be cancel penalty will be 100 percent of order netto price (140.24 USD)</p>
              </div>
              <span className="mt-1 mx-2"><span style={{ color: "red" }}>*</span> Time Zone according to your settings is (UTC +0)</span>
            </div>
            <div className="mt-4">
              <h3 style={{ fontSize: "20px" }}>{formatTime(timeLeft)}</h3>
              <span>Pay for your order within 15 minutes</span>
            </div>
          </div>

            <div className="col-md-1">
                </div>

        </div>
                </div>
            </div>
    </>
   )
}
export default HotelPayment;

