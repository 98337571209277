import React, { Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { format } from 'date-fns';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';
const { config } = require('../api/api');


function ShowHotelDetails() {

    const base_url = config.baseURL;
    const redirect_url = config.redirectURL;
    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
    const [loading, setLoading] = useState();

    const { t } = useTranslation();

    const [vendorKey, setVendorkey] = useState('');
    const [dhofarData, setDhofarData] = useState(false);
    const [downloadpolicy, setDownloadValue] = useState('');
    const [policyLink, setPolicyLink] = useState(null);
    const [policy_no, setPolicyNo] = useState(null);
    const [contract_id, setcontractsID] = useState('');
    const [showFilters, setShowFilters] = useState(false);
    const [searchDetails,setSearchDetails]=useState(null);
    // const [showUploadModal, setShowUploadModal] = useState(false);

    const [loungescoupon, setLoungesToken] = useState('');

    const [passportFile, setPassportFile] = useState(null);
    const [questionnaireFile, setQuestionnaireFile] = useState(null);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [fileChoosen, setFileChoosen] = useState(true);
    const [barvalue, setBarValue] = useState(13);
    const [etmToken, setEtmToken] = useState(null);
    const [etmLoginRes, setEtmLoginRes] = useState(null);
    const [requestId,setRequestId]=useState(null);
    const hotelSample= {
        id: "1c711edc-959e-4d6a-bffd-7919d1bfe343_2fbbc382-7be8-4bbb-befd-26b3795a0482_a20d3f7e-6b99-4bb8-bfbd-f495ee16a78e",
        guide_hotel_id: 35756538,
        name: "The Pride",
        image_id: 350910344,
        image_url: "http://media.dev.paximum.com/hotelimages/311781/1.jpg",
        image_qty: 28,
        stars: 4,
        address: "216, E V R Periyar Salai Poonamallee High Road Chennai 600010",
        latitude: 13.07885,
        longitude: 80.2491,
        offers_qty: 1,
        min_price: 369,
        max_price: 369,
        price_per_currency: {
            EUR: {
                min_price: 355,
                max_price: 355
            },
            USD: {
                min_price: 369,
                max_price: 369
            },
            RUB: {
                min_price: 32571,
                max_price: 32571
            },
            UZS: {
                min_price: 4743933,
                max_price: 4743933
            },
            KZT: {
                min_price: 183681,
                max_price: 183681
            },
            TRY: {
                min_price: 13414,
                max_price: 13414
            },
            BYN: {
                min_price: 1179,
                max_price: 1179
            },
            UAH: {
                min_price: 15311,
                max_price: 15311
            },
            AED: {
                min_price: 1352,
                max_price: 1352
            }
        },
        is_discount: false,
        is_travel_policy_fail: 0,
        travel_policy_fail_details: " ",
        facilities: [
            {
                id: 1966,
                name: "24-hour reception"
            },
            {
                id: 1967,
                name: "Hotel Room Safe"
            },
            {
                id: 1968,
                name: "Elevators"
            },
            {
                id: 1976,
                name: "WLAN access"
            },
            {
                id: 1977,
                name: "Room Service"
            },
            {
                id: 1990,
                name: "Moneyexchange"
            },
            {
                id: 1991,
                name: "Cloakroom"
            },
            {
                id: 2004,
                name: "Laundryservice"
            },
            {
                id: 1973,
                name: "Restaurant(s)"
            },
            {
                id: 2000,
                name: "Conferenceroom"
            }
        ],
        top_facilities: [
            {
                id: 1,
                name: "Free Wi-fi",
                class_icons: "wifi",
                facilities: []
            },
            {
                id: 2,
                name: "Parking",
                class_icons: "parking",
                facilities: []
            },
            {
                id: 7,
                name: "Restaurant",
                class_icons: "food",
                facilities: []
            }
        ],
        category_groups: [],
        refundability: 1,
        boards: [],
        source_id: "311781"
    }
    const [hotelDetails,setHotelDetails]=useState([hotelSample,hotelSample]);
    
    const pattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)(:[0-9]{1,5})?(\/.*)?$/;

    // useEffect(() => {
    //     const urlSearchString = window.location.search;
    //     const uparams = new URLSearchParams(urlSearchString);
    //     const policy_no = uparams.get('order_id');
    //     setPolicyNo(policy_no);
    // }, []);

    useEffect(() => {
        if (showUploadModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [showUploadModal]);


      useEffect(() => {
        const createOrganization = async () => {
          try {
            const postvData = { organization_name: "Superjet" };

            const response = await fetch(base_url + '/create_organization', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(postvData),
            });
            const data = await response.json();
            setVendorkey(data.result[0].vendor_key);
          } catch (error) {
            console.error('Error posting data:', error);
          }
        };

        if (!vendorKey) {
          createOrganization();
        }
      }, [vendorKey, base_url]);




    //   useEffect(() => {
    //     const fetchPolicyDetails = async () => {
    //       if (vendorKey && contract_id  && policy_no) {
    //         try {
    //           const quotation_data = {
    //             contract_id: contract_id,
    //             order_id: policy_no,
    //           };

    //           const response = await fetch(base_url + '/get_order_details', {
    //             method: 'POST',
    //             headers: {
    //               'Authorization': `Bearer ${vendorKey}`,
    //               'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(quotation_data),
    //           });

    //           const data = await response.json();
    //           setDownloadValue(data.result.orderItems);
    //           setLoading(false);
    //         } catch (error) {
    //           setLoading(false);
    //           console.error('Error fetching policy details:', error);
    //         }
    //       }
    //     };

    //     fetchPolicyDetails();
    //   }, [vendorKey, contract_id, policy_no, base_url]);

    const items = [
        {
            Image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU9pwA6uqJV6TegsabT_5v3Y99zlPFzRNRKnrbVfax6H0FFeTKRqbSc3r5Rb976KP8Ybg&usqp=CAU", name: "Somerset Greenways Chennai", address: "No. 94 Sathyadev Avenue, Chennai",
            amount: "from 138.00 $",
            rating: '3'
        },
        {
            Image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU9pwA6uqJV6TegsabT_5v3Y99zlPFzRNRKnrbVfax6H0FFeTKRqbSc3r5Rb976KP8Ybg&usqp=CAU", name: "Somerset Greenways Chennai", address: "No. 94 Sathyadev Avenue, Chennai",
            amount: "from 138.00 $",
            rating: '5'
        },
        {
            Image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJpObS1YgotFcNh_XICe8k-zvOGMp7MrrrKjJ9ZyGTeX6-z3pVjdjFVOpi608eDzi11Wo&usqp=CAU", name: "Somerset Greenways Chennai", address: "No. 94 Sathyadev Avenue, Chennai",
            amount: "from 138.00 $",
            rating: '0'
        },
        {
            Image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJpObS1YgotFcNh_XICe8k-zvOGMp7MrrrKjJ9ZyGTeX6-z3pVjdjFVOpi608eDzi11Wo&usqp=CAU", name: "Somerset Greenways Chennai", address: "No. 94 Sathyadev Avenue, Chennai",
            amount: "from 138.00 $",
            rating: '2'
        },
        {
            Image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU9pwA6uqJV6TegsabT_5v3Y99zlPFzRNRKnrbVfax6H0FFeTKRqbSc3r5Rb976KP8Ybg&usqp=CAU", name: "Somerset Greenways Chennai", address: "No. 94 Sathyadev Avenue, Chennai",
            amount: "from 138.00 $",
            rating: '5'
        },
        {
            Image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU9pwA6uqJV6TegsabT_5v3Y99zlPFzRNRKnrbVfax6H0FFeTKRqbSc3r5Rb976KP8Ybg&usqp=CAU", name: "Somerset Greenways Chennai", address: "No. 94 Sathyadev Avenue, Chennai",
            amount: "from 138.00 $",
            rating: '1'
        },
        {
            Image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU9pwA6uqJV6TegsabT_5v3Y99zlPFzRNRKnrbVfax6H0FFeTKRqbSc3r5Rb976KP8Ybg&usqp=CAU", name: "Somerset Greenways Chennai", address: "No. 94 Sathyadev Avenue, Chennai",
            amount: "from 138.00 $",
            rating: '5'
        },
    ]

    const filtervalues = [
        { name: "Free Wi-fi" }, { name: "Parking" }, { name: "Pool" }, { name: "Beach" }, { name: "Restaurant" }, { name: "Fitness Center" }, { name: "Wellness \ SPA" }, { name: "Pets allowed" }, { name: "Child care" }, { name: "Smoke-free property" }

    ]

    const types = [
        { name: " Hotel" }, { name: "Guest house" }, { name: "Apartments" }, { name: " Bed &Breakfast" }, { name: " Villa" }

    ]

    const mealstypes = [
        { name: "Breakfast" }, { name: "No meals included" }, { name: "ApartmentsHalf board" }, { name: " Bed &Breakfast" }, { name: "Full board" }

    ]

    const handleChange = (e) => {
        setBarValue(Number(e.target.value));
    };

    const StarRating = ({ rating, totalStars = 5 }) => {
        const filledStars = Math.round(rating);
        const emptyStars = totalStars - filledStars;

        return (
            <div className="star-rating" style={{ display: 'flex', padding: '0' }}>
                {[...Array(filledStars)].map((_, index) => (
                    <span
                        key={`filled-${index}`}
                        //   onClick={() => handleStarClick(index)}
                        style={{ color: 'gold', cursor: 'pointer' }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                    </span>
                ))}
                {[...Array(emptyStars)].map((_, index) => (
                    <span
                        key={`empty-${index}`}
                        //   onClick={() => handleStarClick(filledStars + index)}
                        style={{ color: 'gray', cursor: 'pointer' }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
                            <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                        </svg>
                    </span>
                ))}
            </div>
        );

    }
   

    useEffect(() => {
        if (showUploadModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [showUploadModal]);


    const isValidUrl = (url) => {
        return pattern.test(url);
    };

    
        useEffect(() => {
            const getToken=async ()=>{
            debugger
            try {
              const response = await fetch(base_url + "/hotel_login", {
                method: "get",
                headers: {
                  "Authorization": "Bearer " + vendorKey,
                  "Content-Type": "application/json"
                }
              });
              if (!response.ok) {
                console.log("http response status : ", response.status)
              }
              else {
                const data = await  response.json();
                if(data.message==="success"){
                setEtmLoginRes();
                setEtmToken(data.result.etm_auth_key);
                
                await searchHotels(vendorKey,data.result.etm_auth_key);
                }
               
              }
             

              //session Storage to get search Data

             
             


            }
            catch (err) {
              console.log("gettting ETS token err : ", err);
            }
        }
        const check_vendorkey = vendorKey ?? false;
        if(check_vendorkey && vendorKey !== ""){
           getToken();
        }
        }, [vendorKey]);
      
   
        const getRequestId= async (vendor_key,etm_token)=>{
            debugger
            if (vendor_key && etm_token) {
                const searchData=sessionStorage.getItem("SearchData");
             
                setSearchDetails(searchData?JSON.parse(searchData):null);
                const parseDetails=searchData?JSON.parse(searchData):null;
                
                debugger
                // var searchInput = {
                //     etmtoken: etm_token,
                //     searchdata: {
                //       checkin_date: parseDetails?.fromDate,
                //       checkout_date: parseDetails?.toDate ,
                //       poi_name: parseDetails?.country,
                //       providers: [1718
                //         // number
                //       ],
                //       radius: parseDetails.searchRadiusKm,
                //       residency: "RU",
                //       rooms: [
                //         {
                //           adult_qty: 1,
                //           children: []
                //         }
                //       ]
                //     }
                
                //   }
                  var searchInput = {
                    etmtoken: etm_token,
                    searchdata: {
                        "destination_id": 5337,
                        "source_id": null,
                        "poi_latitude": "13.02556100",
                        "poi_longitude": "80.17271000",
                        "checkin_date": "2025-04-03",
                        "checkout_date": "2025-04-07",
                        "travel_policy_id": 0,
                        "one_order_id": 0,
                        "target_uid": null,
                        "rooms": [
                            {
                                "adult_qty": 1,
                                "children": []
                            }
                        ],
                        "residency": "TR",
                        "radius": 50000,
                        "providers": [
                            1718
                        ],
                        "travellers": [],
                        "poi_name": "Chennai" // you can send it in english
                    }
                
                  }
                
                        try {
                        const response =await fetch(base_url + "/hotel_search", {
                          method: "POST",
                          headers: {
                            "Authorization": "Bearer " + vendorKey,
                            "Content-Type": "application/json"
                          },
                          body: JSON.stringify(searchInput)
                        });
                        if (!response.ok) {
                          console.log("http response status : ", response.status)
                        }
                        else {
                          const data = await  response.json();
                          if(data.message==="success"){
                          setRequestId(data.result.request_id);
                          return data.result.request_id;
                          }
                        }
                      }
                      catch (err) {
                        console.log("Hotel Search API Err : ", err);
                      
                      }
                    
                    }
        }
const searchHotels = async (vendor_key,etm_token)=>{

    try{
        debugger
     const request_id= await getRequestId(vendor_key,etm_token);
     if(request_id){
     await getHotelDetails(vendor_key,etm_token,request_id);
    
     }
    }
    catch(err){
        console.log(err);
    }
}

const getHotelDetails= async (vendor_key,etm_token,request_id)=>{
    try {
        var findHotelreq={
            etmtoken:etm_token,
            request_id:request_id,
            search_data:{
            last_variant_id:null,
            limit:1000
        }
        
        }
        const response =await fetch(base_url + "/found_hotel", {
          method: "POST",
          headers: {
            "Authorization": "Bearer " + vendor_key,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(findHotelreq)
        });
        if (!response.ok) {
          console.log("http response status : ", response.status)
        }
        else {
          const data = await  response.json();
          if(data.message==="success"){
        //   setHotelDetails(data.result.data.hotels);
          }
        }
      }
      catch (err) {
        console.log("Hotel Search API Err : ", err);
      
      }
}

    const showHotelOffer =(e,hotel_id)=>{
        e.preventDefault();
        const setData={
            etm_token:etmToken,
            hotel_id:hotel_id
        }
        sessionStorage.setItem("ETM_Data",JSON.stringify(setData));
        window.location.href="/hotel-rooms"
    }

    return (



        <Fragment>

            <div className="header_section1">

                <Navbar />


                <div className="container-fluid bg-breadcrumb">
                    <div className="container text-center py-5">
                        <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'e-Hotel Details' : 'تفاصيل الفندق الإلكتروني\n'}</h3>
                    </div>
                </div>


            </div>


            <div className="features_section">
                <div className="container">

                    <div className="row">

                        <Col md={4} className="myforms my-5" >


                            <div className="card container shadow">
                                <div className="d-flex justify-content-between pt-2">
                                    <div>
                                    <button
                                   
        className="btn btn-outline-secondary d-md-none"
        onClick={() => setShowFilters(!showFilters)}
      >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-funnel-fill" viewBox="0 0 16 16">
                                            <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5z" />
                                        </svg>
                                        </button>
                                    </div>

                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                                        </svg>
                                    </div>

                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-filter-right" viewBox="0 0 16 16">
                                            <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5m0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5m0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5" />
                                        </svg>
                                    </div>
                                </div>
                            </div>


                            <div className={`mobile-filters ${showFilters ? "d-block" : "d-none"} d-md-block`}>


                            <div className="pt-4">
                                <div className="card container shadow">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h1 className="pt-3"><strong>Filter</strong></h1>
                                        </div>
                                        <div className="pt-3">
                                            <button className="btn btn-primary" type="submit">Map</button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div>
                                            <h6><strong><u>Search hotel by name</u></strong></h6>
                                        </div>
                                        <div>
                                            <input type='text' className="form-control" placeholder="Hotel" />
                                        </div>
                                        <hr />

                                        <div className="pt-2">
                                            <h6><strong><u>Policy</u></strong></h6>
                                        </div>
                                        <div>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                <label className="form-check-label" for="flexSwitchCheckDefault">
                                                    Free cancellation</label>
                                            </div>
                                        </div>
                                        <hr />

                                        <div className="pt-2">
                                            <h6><strong><u>Hotel class</u></strong></h6>

                                        </div>
                                        <div>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                <label className="form-check-label" for="flexSwitchCheckDefault">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg></label>
                                            </div>



                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                <label className="form-check-label" for="flexSwitchCheckDefault">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg></label>
                                            </div>



                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                <label className="form-check-label" for="flexSwitchCheckDefault">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg></label>
                                            </div>




                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                <label className="form-check-label" for="flexSwitchCheckDefault">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg></label>
                                            </div>


                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                <label className="form-check-label" for="flexSwitchCheckDefault">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg> </label>
                                            </div>
                                            <br />

                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                <label className="form-check-label" for="flexSwitchCheckDefault">Show hotels without stars </label>
                                            </div>
                                        </div>
                                        <hr />

                                        <div className="pt-2">
                                            <h6><strong><u>Price</u></strong></h6>
                                        </div>
                                        <div>
                                            <input
                                                type="range"
                                                min="13"
                                                max="3119"
                                                step="1"
                                                value={barvalue}
                                                onChange={handleChange}
                                                style={{
                                                    width: "100%",
                                                    appearance: "none",
                                                    height: "8px",
                                                    background: "#ddd",
                                                    outline: "none",
                                                    borderRadius: "5px",
                                                    cursor: "pointer",
                                                    marginBottom: "10px",
                                                }}
                                            />

                                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                                                <div style={{ textAlign: "center" }}>
                                                    <div>13</div>
                                                </div>

                                                <div style={{ textAlign: "center" }}>
                                                    <div>3119</div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="pt-2">
                                            <h6><strong><u>Search hotel by facilities</u></strong></h6>
                                        </div>
                                        <div>
                                            {filtervalues.map((val, index) =>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                    <label className="form-check-label" for="flexSwitchCheckDefault">
                                                        {val.name}</label>
                                                </div>

                                            )}

                                        </div>
                                        <hr />

                                        <div className="pt-2">
                                            <h6><strong><u>Property Type</u></strong></h6>
                                        </div>
                                        <div>
                                            {types.map((type, index) =>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                    <label className="form-check-label" for="flexSwitchCheckDefault">
                                                        {type.name}</label>
                                                </div>

                                            )}

                                        </div>
                                        <hr />


                                        <div className="pt-2">
                                            <h6><strong><u>Meal type</u></strong></h6>
                                        </div>
                                        <div>
                                            {mealstypes.map((value, index) =>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                    <label className="form-check-label" for="flexSwitchCheckDefault">
                                                        {value.name}</label>
                                                </div>

                                            )}

                                        </div>
                                        <hr />

                                        <div className="pt-2">
                                            <h6><strong><u>Address</u></strong></h6>
                                        </div>
                                        <div>
                                            <input type="text" className="form-control" placeholder="Address" />
                                        </div>

                                        <hr />

                                        <div className="pt-2">
                                            <button type="submit" className="btn btn-primary">Clear Filter</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
</div>




                        </Col>



                        <Col md={8} className="myforms my-5" >

                            <br />

                            <div>
                                <div className="card container pt-2 shadow">
                                    <div className="row d-flex justify-content-between">
                                        <div className="p-2">
                                            <p>Available 246 hotels from 246</p>
                                        </div>
                                        <div>
                                            <div className="d-flex justify-content-between">
                                                <div className="p-2">
                                                    <p>Price ascending</p>
                                                </div>
                                                <div className="p-2">
                                                    <p>Price descending </p>
                                                </div>
                                                <div className="p-2">
                                                    <p>By rating</p>
                                                </div>
                                            </div>
                                            <div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                              {/*  {items.map((values, index) => (
                                    <div>
                                        <div className="card col-sm-12 shadow">
                                            <div className="row" >
                                                <div className="col-md-3 p-0">
                                                    {/* <h1>asdfghjkl</h1> */}
                                                    {/*
                                                    <img src={values.Image} style={{ objectFit: 'cover', width: '100%', height: '100px' }} />
                                                </div>
                                                <div className="col-md-9 d-flex justify-content-between pt-2">
                                                    <div>
                                                        <h4 className="p-0">{values.name}</h4>
                                                        <StarRating rating={values.rating} />
                                                        <p><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                                                        </svg></span>{values.address}</p>
                                                    </div>
                                                    <div className="">
                                                        <h4>
                                                            {values.amount}
                                                        </h4>
                                                        <button type='submit' onClick={(e)=>showHotelOffer(e)} className="btn btn-primary">Show offer</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                    </div>

                                ))} */}
                                {hotelDetails.map((hotel, index) => (

                                    <div>
                                        <div className="card col-sm-12 shadow">
                                            <div className="row" >
                                                <div className="col-md-3 p-0">
                                                    {/* <h1>asdfghjkl</h1> */}
                                                    <img src={hotel.image_url} alt={hotel.name} style={{ objectFit: 'cover', width: '100%', height: 'auto' }} />
                                                </div>
                                                <div className="col-md-9 d-flex justify-content-between pt-2">
                                                    <div>
                                                        <h4 className="p-0">{hotel.name}</h4>
                                                        <StarRating rating={hotel.stars} />
                                                        <p><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                                                        </svg></span>{hotel.address}</p>
                                                    </div>
                                                    <div className="">
                                                        <h4>
                                                            {hotel.min_price}
                                                        </h4>
                                                        <button type='submit' onClick={(e)=>showHotelOffer(e,"8d132d4b-ab82-405e-a5c5-b985dedb521f_11452a6a-d2ad-4dd8-8f11-b4ba68a9b635_0a61b61b-e197-47c6-8e23-cdd4f4486cc4")} className="btn btn-primary">Show offer</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                    </div>

                                ))}
                            </div>

                        </Col>
                        <Col md={1}></Col>
                    </div>
                </div>
            </div>
            <Footer />



            {loading && (
                <div className="overlay">
                    <div className="spinner"></div>
                </div>
            )}

        </Fragment>


    );
}

export default ShowHotelDetails;