import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
const { config } = require('../api/api');


function CustomerDetails() {
    const base_url = config.baseURL;

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        insurancetype: '',
    });
    const [loading, setLoading] = useState();
    const [selectedDateofBirth, setSelectedDateofBirth] = useState(null);
    const [startDateofBirth, setStartDate] = useState(null);
    const [expirtyDateofBirth, setExpiryDate] = useState(null);
    const [selectedGender, setSelectedGender] = useState(null);
    const [selectedmarital, setSelectedmarital] = useState(null);
    const [vendorKey, setVendorkey] = useState('');
    const [dobError, setDobError] = useState("");

    // const [selectedqOption, setSelectedqOption] = useState(null);
    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day); // month is 0-indexed
    };

    const calculateAge = (dob) => {
        const today = new Date();
        const birthDate = parseDate(dob);

        // Check if birthDate is valid
        if (isNaN(birthDate.getTime())) {
            // throw new Error('Invalid date');
        }

        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        // Adjust age if the birth date hasn't occurred yet this year
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };


    //   useEffect(() => {

    //     const postvData = { organization_name: "Superjet" };
    //     debugger

    //     if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


    //       fetch( base_url + '/create_organization', {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(postvData),
    //       })
    //         .then(response => response.json())
    //         .then(data => {
    //           setVendorkey(data.result[0].vendor_key);
    //         })
    //         .catch(error => {
    //           console.error('Error posting data:', error);
    //         });


    //     }
    //   }, [vendorKey]); // Dependency array


    const handleSubmit = async (event) => {

        debugger
        event.preventDefault();


    var data =[
        {
            'CustomerName' : fnameValue,
            'DateofBirth' : selectedDateofBirth,
            'Age': ageValue,
            'InInsured': insuredValue,
            'IsPolicyHolder' : policyholderValue,
            'GenderCode' : selectedmarital,
            'Mobile': phoneValue,
            'IsOrgParty': orgpartyValue,
            'Email': emailValue,
            'Address': addressValue,
            'startdate':startDateofBirth,
            'expirtydate':expirtyDateofBirth
        }
    ]

    sessionStorage.setItem("customerdetails" , JSON.stringify(data))

    window.location.href = '/buildingdetails';

    
    
        // debugger

        // if (selectedGender === null){
        //   event.preventDefault();
        //   document.getElementsByName('gender')[0].focus();
        // }else if (selectedmarital === null){
        //   event.preventDefault();
        //   document.getElementsByName('maritalstatus')[0].focus();
        // }
        // else{

        //   debugger

        // var data = {
        //   "basicinfo_gender_id": selectedgenderOptionid,
        //   "basicinfo_gender_detail": selectedGender,
        //   "basicinfo_marital_status_id": selectedmaritalOptionid,
        //   "basicinfo_marital_status_detail": selectedmarital,
        //   "dob": selectedDateofBirth
        // }

        // debugger

        // sessionStorage.setItem("personalaccidentbasicinfo", JSON.stringify(data))


        // setLoading(true);

        // event.preventDefault();
        // window.location.href = '/accident-cover';

        // }

    };

    //   useEffect(() => {
    //     debugger
    //     const basicvalue = sessionStorage.getItem('personalaccidentbasicinfo');
    //     if (basicvalue != undefined) {
    //       const value = JSON.parse(basicvalue);
    //       setSelectedGender(value.basicinfo_gender_detail);
    //       setSelectedmarital(value.basicinfo_marital_status_detail);
    //       setSelectedDateofBirth(value.dob);
    //       setSelectedgenderOption(value.basicinfo_gender_detail);
    //       setSelectedgenderOptionid(value.basicinfo_gender_id);
    //       setSelectedmaritalOptionid(value.basicinfo_marital_status_id);
    //       setSelectedmaritalOption(value.basicinfo_marital_status_detail);
    //       setLoading(false);

    //     }

    //   }, []);

    //   const [dhofarData, setDhofarData] = useState(false);
    //   const [age, setAge] = useState(null);
    //   useEffect(() => {


    //     const storedDhofarString = Cookies.get('personalData'); 
    //     if (storedDhofarString !== undefined) {
    //       const storedDhofar = JSON.parse(storedDhofarString);
    //       setDhofarData(storedDhofar);
    //     }
    //     else {
    //       alert("token expired");
    //       window.location.href='/';
    //     }

    //   }, []);



    const [buttonDisabled, setButtonDisabled] = useState(false);
    //   const postData = { token: dhofarData };
    // const today = new Date().toISOString().split('T')[0];
    const today = new Date();
    const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];
    const minDate = new Date(today.getFullYear() - 60, today.getMonth(), today.getDate()).toISOString().split('T')[0];

    const [dobValue, setdobValue] = useState('');
    const handledobChange = (event) => {
        const selectedDob = event.target.value;
        setdobValue(event.target.value);

        const dobDate = new Date(selectedDob);
        const today = new Date();
        let calculatedAge = today.getFullYear() - dobDate.getFullYear();
        const monthDiff = today.getMonth() - dobDate.getMonth();

        // Adjust age if the current date is before the user's birthday in the current year
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
            calculatedAge--;
        }

        // setAge(calculatedAge);
    };

    const [selectedgenderOption, setSelectedgenderOption] = useState(null);
    const [selectedgenderOptionid, setSelectedgenderOptionid] = useState(null);
    const handlegenderChange = (event) => {
        debugger
        setSelectedGender(event.target.value)
        setSelectedgenderOptionid(event.target.options[event.target.selectedIndex].id);
        setSelectedgenderOption(event.target.value);
    };

    const [selectedmaritalOption, setSelectedmaritalOption] = useState(null);
    const [selectedmaritalOptionid, setSelectedmaritalOptionid] = useState(null);
    const handlemaritalChange = (event) => {
        setSelectedmarital(event.target.value)
        setSelectedmaritalOptionid(event.target.options[event.target.selectedIndex].id);
        setSelectedmaritalOption(event.target.value);
    };
    //getgender
      const [fnameValue, setfnameValue] = useState('');
    
      const handlefnameChange = (event) => {
        setfnameValue(event.target.value);
      };

      const [ageValue, setageValue] = useState('');
    
      const handleageChange = (event) => {
        setageValue(event.target.value);
      };

      const [addressValue, setaddressValue] = useState('');
    
      const handleaddressChange = (event) => {
        setaddressValue(event.target.value);
      };

      const [insuredValue, setinsuredValue] = useState('');
    
      const handleinsuredChange = (event) => {
        setinsuredValue(event.target.value);
      };

      const [policyholderValue, setpolicyholderValue] = useState('');
    
      const handlepolicyholderChange = (event) => {
        setpolicyholderValue(event.target.value);
      };

      const [orgpartyValue, setorgpartyValue] = useState('');
    
      const handleORGpartyChange = (event) => {
        setorgpartyValue(event.target.value);
      };



    const [emailValue, setemailValue] = useState('');
    const handleemailChange = (event) => {
        setemailValue(event.target.value);
        event.preventDefault();
    };

    const [emailError, setEmailError] = useState('');

    const handleEmailBlur = (e) => {
        const { value } = e.target;

        // Regular expression to validate a basic email format
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        // Check if the email matches the pattern
        if (!emailPattern.test(value)) {
            // document.getElementsByName('email')[0].focus();
            setEmailError('Please enter a valid email address. Example: example@domain.com');
            setemailValue('');
        } else {
            setEmailError(''); // Clear the error if valid
        }
    };

    const [phoneValue, setphoneValue] = useState('');
    const handlephoneChange = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        setphoneValue(value);
    };


    const [mobileError, setMobileError] = useState('');

    const handleMobileBlur = (e) => {
        const { value } = e.target;

        // Check if the mobile number has exactly 8 digits
        if (value.length !== 8 || !/^\d+$/.test(value)) {
            // document.getElementsByName('phone')[0].focus();
            setMobileError('Please enter 8 digit phone number');
            setphoneValue('');

        } else {
            setMobileError('');
        }
    };

    const handleDOBBlur = (e) => {
        //     const { value } = e.target;
        //    if(age<18){
        //     document.getElementsByName("dob")[0].focus();

        //    } 
    };

    const [goptions, setGender] = useState([]);
    const [moptions, setMarital] = useState([]);

    //   useEffect(() => {
    //     if (goptions && goptions.length === 0 && vendorKey !== '') {



    //       debugger
    //       fetch(base_url + '/getGender', {
    //         method: 'POST',
    //         headers: {
    //           'Authorization': `Bearer ${vendorKey}`,
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(postData),
    //       })
    //         .then(response => response.json())
    //         .then(data => {
    //           setGender(data.result.data.response_data);
    //           married()

    //         })
    //         .catch(error => {
    //           console.error('Error posting data:', error);
    //         });

    //     }    
    //   },[vendorKey]
    //   );

    //   const married = () => {
    //     if (moptions && moptions.length === 0 && vendorKey !== '') {
    //       setLoading(true);

    //       fetch(base_url + '/getMaritalStatus', {
    //         method: 'POST',
    //         headers: {
    //           'Authorization': `Bearer ${vendorKey}`,
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(postData),
    //       })
    //         .then(response => response.json())
    //         .then(data => {

    //           setMarital(data.result.data.response_data);
    //           setLoading(false);
    //           document.getElementsByName("gender")[0].focus();

    //         })
    //         .catch(error => {
    //           console.error('Error posting data:', error);
    //         });

    //     }
    //   };

    // const isButtonDisabled = !selectedgenderOption || !selectedmaritalOption || !selectedDateofBirth;






    return (
        <Fragment>

            <div className="header_section1">

                <Navbar />


                <div className="container-fluid bg-breadcrumb">
                    <div className="container text-center py-5">
                        <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Personal Accident' : 'حادث شخصي\n'}</h3>
                    </div>
                </div>


            </div>


            <div className="features_section">
                <div className="container">

                    <div className="row">

                        <Col md={3}></Col>

                        <Col md={6} className="myforms my-5">



                            <h4 className="px-0 py-2">{(lnstr === 'en') ? 'Select Customer Details' : 'حدد تفاصيل المؤمن عليه\n'}</h4>

                            <form onSubmit={handleSubmit}>

                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? "Customer Name" : 'اسم العميل'} <span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input type="text" name="Customername" className="form-control"
                                        value={fnameValue} onChange={handlefnameChange}
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                                        }}
                                        required />
                                </div>
                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? "Age" : 'عمر'} <span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input type='number' name='customername' className="form-control" value={ageValue} onChange={handleageChange} required/>
                                </div>
                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{t('DOB')} <span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input value={selectedDateofBirth} type="date" name="dob" max={maxDate} min={minDate} className="form-control" onChange={(e) => {
                                        setDobError("");
                                        setSelectedDateofBirth(e.target.value);
                                        const formattedDate = e.target.value.replace(/\//g, '-');
                                        const [year, month, day] = formattedDate.split("-");
                                        const finalformattedDate = `${day}-${month}-${year}`;
                                        setFormData({ ...formData, ['dob']: finalformattedDate });

                                        // const finalAgeFormattedDate = `${day}-${month}-${year}`;
                                        debugger
                                        // Calculate and set the age
                                        const age = calculateAge(finalformattedDate);
                                        // setAge(age);
                                        if (age < 18) {
                                            setDobError('Your Age is invalid');
                                            // alert("Age is less than 18");
                                            // setSelectedDateofBirth("");
                                        }
                                        if (age >= 18) {
                                            setDobError('');

                                            // alert("Age is less than 18");
                                            // setSelectedDateofBirth("");
                                        }


                                    }} onBlur={handleDOBBlur} required />
                                </div>

                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{t('Email')}<span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input type="text" name="email" className="form-control" value={emailValue}
                                        onBlur={handleEmailBlur}
                                        onChange={handleemailChange} required />
                                </div>

                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? 'Mobile Number' : 'رقم الهاتف المحمول'}<span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input type="tel" name="phone" className="form-control" inputMode="numeric" value={phoneValue} maxLength={10} onBlur={handleMobileBlur}
                                        onChange={handlephoneChange} required/>
                                </div>

                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? "Address" : 'عنوان'} <span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <textarea type='text' name='address' className="form-control" value={addressValue} onChange={handleaddressChange} required/>
                                </div>

                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? 'Marital Status' : 'الحالة الأجتماعية'} <span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <select value={selectedmarital} name="maritalstatus" className="form-control" onChange={handlemaritalChange}
                                        required>
                                        <option value=''>{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                                        <option value='M' >Male</option>
                                        <option value='F' >Female</option>
                                    </select>
                                </div>
                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? 'Is Insured' : 'مؤمن'} <span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <select value={insuredValue} name="maritalstatus" className="form-control" onChange={handleinsuredChange}
                                        required>
                                        <option value=''>{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                                        <option value='Y'>Yes</option>
                                        <option value='N'>No</option>
                                    </select>
                                </div>
                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? 'Is PolicyHolder' : 'هو حامل السياسة'} <span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <select value={policyholderValue} name="maritalstatus" className="form-control" onChange={handlepolicyholderChange}
                                        required>
                                        <option value=''>{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                                        <option value='Y'>Yes</option>
                                        <option value='N'>No</option>
                                    </select>
                                </div>
                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? 'Is ORGParty' : 'هو ORGParty'} <span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <select value={orgpartyValue} name="maritalstatus" className="form-control" onChange={handleORGpartyChange}
                                        required>
                                        <option value=''>{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                                        <option value='Y'>Yes</option>
                                        <option value='N'>No</option>
                                    </select>
                                </div>
                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? 'Start Date' : 'تاريخ البدء'}<span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input type='date' name='startdate' className="form-control" onChange={(e)=>{
                                        setStartDate(e.target.value)
                                    }} required/>
                                </div>

                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? 'Expiry Date' : 'تاريخ انتهاء الصلاحية'}<span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input type='date' name='startdate' className="form-control" onChange={(e)=>{
                                        setExpiryDate(e.target.value)
                                    }}  required/>
                                </div>



                                {/* {age !== null && <p>Your age is {age} year(s).</p>} */}
                                {dobError && <div ><p style={{ color: "red" }}>{dobError}</p></div>}
                                <button type="submit" className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{t('Submit')}</button>


                            </form>


                        </Col>

                        <Col md={3}></Col>

                    </div>

                </div>
            </div>

            <Footer />

            {loading && (
                <div className="overlay">
                    <div className="spinner"></div>
                </div>
            )}

        </Fragment>
    );


}

export default CustomerDetails;
