import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SessionRemove from "../helper/sessionremove";
const { config } = require('../api/api');



function PersonalAccidentinsurance() {
  const base_url = config.baseURL;

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    insurancetype: '',
  });

  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSection, setShowSection] = useState(false);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    debugger
    setFormData({ ...formData, 'insurancetype': event.target.value });

    if (event.target.value === ' ') {
      setShowSection(true);
      setButtonDisabled(true);
    }
    else {
      setShowSection(false);
      setButtonDisabled(false);
    }

  };



  // const [selectedqOption, setSelectedqOption] = useState(null);

  //   const handleqOptionChange = (event) => {

  //   if (event.target.value === 'No') {
  //     alert("The outbound plans are available only for residents of Oman.");
  //     setButtonDisabled(true);
  //     return;
  //   }
  //   else {
  //     setButtonDisabled(false);
  //   }

  // };


  const [vendorKey, setVendorKey] = useState('');

  // useEffect(() => {
  //   debugger

  //   const postvData = { organization_name: "Superjet" };
  //   Cookies.set('Vendorkey', JSON.stringify(vendorKey))

  //   if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


  //     fetch('https://api.superjetom.com/create_organization', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(postvData),
  //     })
  //       .then(response => response.json())
  //       .then(data => {
  //         debugger
  //         setVendorKey(data.result[0].vendor_key);

  //         Cookies.set('Vendorkey', JSON.stringify(vendorKey))
  //       })
  //       .catch(error => {
  //         console.error('Error posting data:', error);
  //       });


  //   }
  // }, [vendorKey]); // Dependency array



  // const [personaltocken , setPersonaltocken] = useState('');
  // useEffect(() => {
  //   const postvData = {organization_name: "Superjet"};
  //   debugger
  //   if (personaltocken === '' || personaltocken === null || personaltocken === undefined){

  //     fetch('https://api.superjetom.com/create_personal_accident_token' ,{
  //       method:'POST',
  //       headers : {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(postvData),
  //     })
  //     .then(response => response.json())
  //     .then(data => {
  //       debugger
  //       setPersonaltocken(data.result)
  //     })
  //     .catch(error => {
  //       console.error('error posting data:',error)
  //     });
  //   }
  // },[personaltocken]);



  useEffect(()=>{
    if (selectedOption != null){
      const planvalue = sessionStorage.getItem('chooseplan');
    if(planvalue != undefined) {
      const plan = JSON.parse(planvalue)
      setSelectedOption(plan.plan)
    }
    }else{
      setSelectedOption(null)
    }
  },[])
  







  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedOption === null) {
      alert('Please choose a plan');
      return false;
    }

    setLoading(true);

    debugger

    const postvData = { organization_name: "Superjet" };

    try {
      // Create the organization
      const response = await fetch( base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      });

      const data = await response.json();
      const vendorKey = data.result[0].vendor_key;

      // Set vendor key in cookie
      Cookies.set('Vendorkey', vendorKey);
      debugger

      // Check if the selected option is 'Personal Accident'
      if (selectedOption === 'Personal Accident') {
        const tokenResponse = await fetch( base_url + '/create_personal_accident_token', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${vendorKey}`,
          },
        });

        const tokenData = await tokenResponse.json();
        const personaltocken = tokenData.result.data.token;

        const expiredpersonaltocken = new Date();
        expiredpersonaltocken.setTime(expiredpersonaltocken.getTime() + (1 * 60 * 60 * 1000));
debugger
        Cookies.set('personalData', JSON.stringify(personaltocken), {
          expires: expiredpersonaltocken,
        });

        var plandata= {
          'plan':selectedOption
        }
          // SessionRemove();
        sessionStorage.clear();

        sessionStorage.setItem("insuranceplan" , JSON.stringify(plandata));


      
        // Redirect after token is set
        
        window.location.href = '/basic-information';
      } else {
        
        const tokenResponse = await fetch( base_url + '/create_term_plan_token', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${vendorKey}`,
          },
        });

        const tokenData = await tokenResponse.json();
        const personaltocken = tokenData.result.data.token;

        const expiredpersonaltocken = new Date();
        expiredpersonaltocken.setTime(expiredpersonaltocken.getTime() + (1 * 60 * 60 * 1000));

        Cookies.set('personalData', JSON.stringify(personaltocken), {
          expires: expiredpersonaltocken,
        });


        var plandata= {
          'plan':selectedOption
        }

        sessionStorage.setItem("insuranceplan" , JSON.stringify(plandata));
        
        // SessionRemove();
        sessionStorage.clear();

        window.location.href = '/term-plan';
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Ensure loading state is reset
    }
  };



  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Personal Accident Insurance' : 'التأمين ضد الحوادث الشخصية\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">



              <h4 className="px-0 py-2">{(lnstr === 'en') ? 'Choose your cover plan' : 'اختر خطة التغطية الخاصة بك\n'}</h4>

              <form onSubmit={handleSubmit}>

                <div class="align-items-center travel-selection-option">
                  {(lnstr === 'en') ? 'Personal Accident' : 'حادث شخصي\n'}
                  <input type="radio" name="personalaccident" value="Personal Accident" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                </div>

                <div class="align-items-center travel-selection-option my-3">
                  {(lnstr === 'en') ? 'Term Plan' : 'خطة المدة\n'}
                  <input type="radio" name="personalaccident" value="Term Plan" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                </div>


                {/* {showSection && (
                  <div>
                    <p className={lnstr}>{(lnstr === 'en') ? 'Are you a resident of Oman ?' : 'هل أنت مقيم في عمان؟'}</p>
                    <Row>
                      <Col md={4}>
                        <div class="align-items-center travel-selection-option">

                          {(lnstr === 'en') ? 'YES' : 'نعم'}
                          <input type="radio" name="residentans" value="Yes" onChange={handleqOptionChange} className="d-inline-block option-circle ml-auto" />
                        </div>
                      </Col>

                      <Col md={4}>
                        <div class="align-items-center travel-selection-option">

                          {(lnstr === 'en') ? 'NO' : 'لا\n'}
                          <input type="radio" name="residentans" value="No" onChange={handleqOptionChange} className="d-inline-block option-circle ml-auto" />
                        </div>
                      </Col>
                    </Row><br />
                  </div>
                )} */}



                <button type="submit" disabled={buttonDisabled} className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{t('Submit')}</button>

              </form>


            </Col>

            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}

export default PersonalAccidentinsurance;
