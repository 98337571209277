
import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';

// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import { event } from "jquery";
import $ from 'jquery';

const { config } = require('../api/api');


function HotelSearchPage() {
  const base_url = config.baseURL;
  const country = {  
DE:'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 513 342"><path fill="#D80027" d="M0 0h513v342H0z"/><path d="M0 0h513v114H0z"/><path fill="#FFDA44" d="M0 228h513v114H0z"/></svg>',
RU:'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 85.333 512 341.333"><path fill="#FFF" d="M0 85.33v341.332h512V85.33z"/><path fill="#0052B4" d="M0 85.333h512V426.67H0z"/><path fill="#FFF" d="M0 85.333h512v113.775H0z"/><path fill="#D80027" d="M0 312.884h512v113.775H0z"/></svg>'

   };


 const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  const { t } = useTranslation();
  const [vendorKey, setVendorkey] = useState('');

  const [formData, setFormData] = useState({
    insurancetype: '',
  });
  const [loading, setLoading] = useState(false);

  const [contract_id, setcontractsID] = useState('');
  const [countryname, setCountryName] = useState([]);
  const [airport, setAirPorts] = useState([]);
  const selectOpt1 = useRef(null);
  const selectOpt2 = useRef(null);
  const selectOpt3 = useRef(null);
  const [selectairportname, setSelectAirPortName] = useState(null);
  const [airport_id, setAirPortId] = useState(null);
  const [roomsCount,setRoomsCount]=useState(1);
  const [adultsCount,setAdultsCount]=useState(1);
  const [childrensCount,setChildrensCount]=useState(0);
  const [roomData, setRoomData] = useState(Array(4).fill({ adults: 1, children: 0 }));
  const[childage,setChildAge]=useState();
  const [providerData,setProviderData]=useState([]);

  const [direction, setSelectDirectionname] = useState(null);
  const [etmtoken , setEtmtoken] = useState('')
  const [transferallvalue , setTrandfervalue] = useState([]);
  const [citizionshipvalue , setCitizionshipvalue] =useState([]);
  const [latitudedate, setLatitudevalue] = useState(null);
  const [longtitude , setLongtitudevalue]=useState(null);
  const [destinationid , setDestinationid] = useState(null);
  const [poiname , setPoinamevalue] = useState(null)
  const [citizionship , setCitizionship] = useState(null)
  const [selectedProvider,setSelectedProvider]=useState({});

  const[fromdate , setFromdatevalue] = useState(null);
  const [todate , setTodatevalue] = useState(null);




  useEffect(() => {

    const postvData = { organization_name: "Superjet" };
    debugger

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {

      debugger
      fetch(base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorkey(data.result[0].vendor_key);

          fetch(base_url + '/hotel_login', {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.result[0].vendor_key}`,
                  },
                })
                  .then(response => response.json())
                  .then(data => {
                    setEtmtoken(data.result.etm_auth_key);
                    // alert(data.result.etm_auth_key)
                  })
                  .catch(error => {
                    console.error('Error posting data:', error);
                  });
          
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]);
useEffect (()=>{
  console.log(selectedProvider)
},[selectedProvider])

  useEffect(() => {
debugger


      if (vendorKey !== '' && etmtoken !='') {
        
      var valuedata = {
        'etmtoken': etmtoken
      }
        fetch(base_url + '/hotel_group', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(valuedata),

        })
          .then(response => response.json())
          .then(data => {

            setTrandfervalue(data.result.data);

          })
          .catch(error => {
            console.error('Error posting data:', error);
          });

            fetch(base_url + '/hotel_provider', {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${vendorKey}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(valuedata),
            })
              .then(response => response.json())
              .then(data => {
                setProviderData(data.result.data.original.data);

        // Set initial selection for all providers
      
               
              })
              .catch(error => {
                console.error('Error posting data:', error);
              });
              fetch(base_url + '/citizenship', {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${vendorKey}`,
                  'Content-Type': 'application/json',
                },
      
              })
                .then(response => response.json())
                .then(data => {
                  setCitizionshipvalue(data.result.data);
      
                })
                .catch(error => {
                  console.error('Error posting data:', error);
                });

      }


  }, [vendorKey, etmtoken]);


  useEffect(() => {
    $(selectOpt1.current).select2();
    $(selectOpt1.current).on('select2:select', (e) => {
      debugger
      const selectedValue = e.params.data.text;
      const selectairport_id = e.params.data.id;

      setSelectAirPortName(selectedValue);
      setAirPortId(selectairport_id);
    });

    // Clean up Select2 instance on unmount
    return () => {
      $(selectOpt1.current).select2('destroy');
    };
  }, [vendorKey, airport]);



  useEffect(() => {
    $(selectOpt3.current).select2();
    $(selectOpt3.current).on('select2:select', (e) => {
      debugger
      const selectedValue = e.params.data.text;
      const selectairport_id = e.params.data.id;

      setSelectAirPortName(selectedValue);
      setCitizionship(selectairport_id);
    });

    return () => {
      $(selectOpt3.current).select2('destroy');
    };
  }, [vendorKey]);
  
  const handleSelectDirection = (e) => {
 // setFormData({ ...formData, [name]: value });
    debugger
    setSelectAirPortName(e.target.value)
    // console.log(formData);
  };

  const handlecitizionshipchange = (e) => {
    debugger
    setCitizionship(e.target.value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    debugger

    var fulldetails = {
      "etmtoken":etmtoken,
      "searchdata":{
      "checkin_date": fromdate,
      "checkout_date": todate,
      "destination_id": destinationid,
      "one_order_id": 1,
      "poi_latitude": latitudedate,
      "poi_longitude": longtitude,
      "poi_name": poiname,
      "providers": [
          1785, //Островок
          1718 // PaximumGL
      ],
      "radius": 50000,
      "residency": citizionship,
      "rooms": [{"adult_qty": 1, "children": []}],
      "source_id": null,
      "target_uid": null,
      "transfer_group_id": null,
      "travel_policy_id": 0,
      "travellers": []
  }
  
  }
};


 const [isOpen, setIsOpen] = useState(false);

 const toggleDropdown = () => {
    setIsOpen(!isOpen);
  }
  const handleRoomChange = (event) => {
    setRoomsCount(parseInt(event.target.value, 10));
  };

  const handleChange = (index, field, value) => {
    setRoomData((prev) => {
      const newData = [...prev];
      newData[index] = { ...newData[index], [field]: parseInt(value, 10) };
      return newData;
    });
 };

 const handleChangehotel = (event) => {
  debugger
  setSelectDirectionname(event.target.value)

  var name = event.target.value

  if (name.length > 1){

    var data = {
      'etmtoken':etmtoken,
      'poi_name': name,
      'locale' : 'EN'
    }
  
    if (vendorKey !== '') {
      fetch(base_url + '/hotel_destinations', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        
      })
        .then(response => response.json())
        .then(data => {
  
          setLatitudevalue(data.result.data.latitude);
          setLongtitudevalue(data.result.data.longitude);
          setDestinationid(data.result.data.id);
          setPoinamevalue(data.result.data.name);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });
  
    }

  }

  

 }

 const handleChangeForChildAge = (e, index) => {
  let value = e.target.value;
if (value === "" || (Number(value) >= 0 && Number(value) <= 17)) {
    setRoomData((prev) => {
      const newData = [...prev];
      newData[index] = { ...newData[index], childAge: value };
      return newData;
    });
  }
};
const handleProviderChange = (e) => {
  const {id}=e.target;
  providerData.forEach((obj,index)=>{
    if(id===`provider${index}`){
      e.target.classList.toggle('btn-primary'); 
    }
})
  
};

return (

  <Fragment>
    {/* <div className="header_section1"> */}
    <Navbar />

    {/* <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Search for Lounges' : 'البحث عن الصالات\n'}</h3>
          </div>
        </div>
      </div> */}
   
      <div className="container"  >
        <div className="row">
          <Col md={1}></Col>

          <Col md={10} className="myforms my-5">

            <div className=" container">

            <div className="card p-3 shadow"
              style={{
                //   width: '250px',
                //   height: '160px',
                boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
                Width: "100%",
                borderRadius: '1rem'
              }}>
                <div className="row justify-content-center">
                  <h2 className="text-center"><strong><u>E-Hotels</u></strong></h2>
                

                </div>
              <form className="p-4" style={{ backgroundColor: 'rgba(255, 255, 255, 0.6)', borderRadius: '2.5rem' }}  onSubmit={handleSubmit}>

                

                {/* <br/> */}


                <div className="mb-3">
                <div className="row">
                <div className="col-md-6">
                  <h4><strong>Direction</strong></h4>

                  <input type="text" className="form-control" onChange={handleChangehotel} required/>

                  {/* <select
                    ref={selectOpt1}
                    className="form-control"
                    style={{ borderRadius: '5px', height: '30px', width: '100%', maxWidth: '300px' }}
                    onChange={handleSelectDirection} required
                    value={selectcountryname}
                    
                  >
                    <option value={'Select Direction'} >Select Direction</option>
                    {countryname.map((name) => (
                      <option key={name} value={name}>{name.country_name}</option>
                    ))}
                  </select> */}

                </div>
                
             

                <div className="col-md-3">
                  <div className="mb-3">
                  <h4><strong>From</strong></h4>
                  <input type="Date" className="form-control" value={fromdate} onChange={(e)=>{setFromdatevalue(e.target.value)}} required/>

                </div>
                </div>
                
                <div className="col-md-3">
                 <div className="mb-3">
                  <h4><strong>To</strong></h4>
                  <input type="Date" className="form-control" value={todate} onChange={(e)=>{setTodatevalue(e.target.value)}} />
                </div>
                </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-6">
                <div className="mb-3">
                  <h4><strong>Citizenship</strong></h4>

                  <select
                    ref={selectOpt1}
                    className="form-control"
                    style={{ borderRadius: '5px', height: '30px', width: '100%', maxWidth: '300px' }}
                    onChange={handlecitizionshipchange} required
                    // value={selectcountryname}
                    
                  >
                    <option value={'Select Direction'} >Select Direction</option>
                    {citizionshipvalue.map((citizionname) => (
                      <option key={citizionname} value={citizionname.code}>{citizionname.name}</option>
                    ))}
                  </select>

                </div>
                </div>

                <div className="col-md-6">
                <div className=" mb-3">
                  <h4><strong>Search radius, km</strong></h4>

                 <input type="text" className="form-control" onChange={handleChangehotel} required />

                </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-6">
                <div className=" mb-3">
                  <h4><strong>Transfer</strong></h4>

                  <select
                    ref={selectOpt3}
                    className="form-control"
                    style={{ borderRadius: '5px', height: '30px', width: '100%', maxWidth: '300px' }}
                    onChange={handleSelectDirection} required
                    // value={selectcountryname}
                    >
                    <option value={'Select Direction'} >Select Direction</option>
                    {transferallvalue.map((valuename) => (
                      <option key={valuename} value={valuename.id}>{valuename.name}</option>
                    ))}
                  </select>

                </div>
                </div>

                <div className="col-md-6">
                <div className=" mb-3">
                  <h4><strong>Rooms</strong></h4>

                  <select
                    //  ref={selectOpt3}
                    className="form-control"
                    style={{ height: '40px' }}
                    onChange={handleRoomChange} required
                    value={roomsCount}
                    >
                     {[1, 2, 3, 4].map((num) => (
                       <option key={num} value={num}>{num}</option>
                     ))}
                   </select>

                </div>
                </div>
                </div>
               
                <div>
        {Array.from({ length: roomsCount }, (_, index) => (
        <div key={index} className="border mt-3 p-3">
          <h4><b>Room {index + 1}</b></h4>
             <div className="row p-0">
            <div className="col-md-3">
              <div className="">
              <h5 className="mt-2">Adults:</h5>

              <select
                    // ref={selectOpt2}
                    className="form-control mx-2"
                    value={roomData[index].adults}
                    // style={{ borderRadius: '5px', height: '30px', width: '100%', maxWidth: '300px' }}
                   onChange={(e) => handleChange(index, "adults", e.target.value)}>
                {[1, 2, 3, 4].map(num => (
                <option key={num} value={num}>{num}</option>
                ))}
              </select>
              </div>
              </div>


              <div className="col-md-3">
              <div className="">
              <h5 className="mt-2">Children:</h5>

              <select
                    // ref={selectOpt2}
                    className="form-control mx-2"
                    // style={{ borderRadius: '5px', height: '30px', width: '100%', maxWidth: '300px' }}
                    value={roomData[index].children}
                    onChange={(e) => handleChange(index, "children", e.target.value)}>
                  {[0, 1, 2].map(num => (
                  <option key={num} value={num}>{num}</option>
                ))}
                    
          </select>
          </div>
          </div>
          {roomData[index].children > 0 && ( 
            
            <>
            {[...Array(roomData[index].children)].map((_, childIndex) => ( 
            <div className="col-md-3 mt-2" key={childIndex}>
             
           <h5 className="">Child's age:</h5>
            <input type="number" className="form-control " min="0" max="17" defaultValue="" //value={childage}onChange={handleChangeForChildAge}          
            />  
              </div>
            ))}
             </>
          
            )}
     </div>
      </div>
      ))}
    </div>
<div className="row mt-4 ">
  <div className="col-md-6">
    <div className="mb-3">
               {/* <h4><strong>Provider</strong></h4> */}
               <div className="d-flex">
                   {providerData?.map((providerObj, index) => (
          <div className="btn border btn-primary mr-3 d-flex" style={{width:"28%",height:"40px"}}  id ={`provider${index}`}   onClick={handleProviderChange}>
           <div 
           id ={`provider${index}`}
          className="mr-2 align-item-center"   
          style={{width: '34px', height: '30px', overflow: 'hidden' }}
          // onClick={handleProviderChange}
          dangerouslySetInnerHTML={{ __html: country[providerObj.country] || '' }}
        />
          <div className="" >{providerObj.name}</div>
          
        </div>
      ))}
    </div>
               </div>
               </div>
               <div className="col-md-6">
                <div className="d-flex justify-content-md-end">
                  <button className="btn btn-primary" style={{width:"30%",height:"40px"}}>Find</button>
                </div>
                </div>
                </div>
              </form>
              </div>
            </div>
            <h3></h3>
            {/* <div style={{ position: 'relative' }}>
                <i className="fas fa-search" style={{
                  position: 'absolute',
                  left: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  fontSize: '18px',
                  color: '#ccc',
                }}></i>
                <input type="text" className="form-control" style={{
                  paddingLeft: '30px',
                  fontSize: '16px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                }} onChange={handleSearchChange} />
              </div> */}

            {/* <div className="p-1">
                {airport.map((air, index) => (
                  <div className="d-flex justify-content-between my-3 colmds-4 card m-0" onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave} style={{
                      backgroundColor: hoveredIndex === index ? '#bfbfbf' : '#f2f2f2',
                      cursor: 'pointer',
                      transition: 'background-color 0.3s ease',
                    }}>
                    <div className="d-flex justify-content-between my-1" onClick={() => handleSubmit(air.iata, air.location, air.title)} key={index}>
                      <div>
                        <h3>{air.title}</h3>
                      </div>
                      <div>
                        <p style={{ textAlign: 'right' }}>
                          {air.iata}
                          <p>{air.city.title}</p>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div> */}

               
          </Col>

          <Col md={1}></Col>
          

        </div>
      </div>
   

    <Footer />

    {
      loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )
    }
  </Fragment >
);
}

export default HotelSearchPage;
