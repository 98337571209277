import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
const { config } = require('../api/api');


function BuildingDetails() {
    const base_url = config.baseURL;

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        insurancetype: '',
    });
    const [loading, setLoading] = useState();
    const [selectedbuildingDate, setSelectedBuildingDate] = useState(null);
    const [startDateofBirth, setStartDate] = useState(null);
    const [expirtyDateofBirth, setExpiryDate] = useState(null);
    const [selectedGender, setSelectedGender] = useState(null);
    const [buildingnamevalue, setBuildingnameValue] = useState(null);
    const [vendorKey, setVendorkey] = useState('');
    const [dobError, setDobError] = useState("");

    // const [selectedqOption, setSelectedqOption] = useState(null);
    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day); // month is 0-indexed
    };

    const calculateAge = (dob) => {
        const today = new Date();
        const birthDate = parseDate(dob);

        // Check if birthDate is valid
        if (isNaN(birthDate.getTime())) {
            // throw new Error('Invalid date');
        }

        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        // Adjust age if the birth date hasn't occurred yet this year
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };


    //   useEffect(() => {

    //     const postvData = { organization_name: "Superjet" };
    //     debugger

    //     if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


    //       fetch( base_url + '/create_organization', {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(postvData),
    //       })
    //         .then(response => response.json())
    //         .then(data => {
    //           setVendorkey(data.result[0].vendor_key);
    //         })
    //         .catch(error => {
    //           console.error('Error posting data:', error);
    //         });


    //     }
    //   }, [vendorKey]); // Dependency array


    const handleSubmit = async (event) => {
        event.preventDefault();
        debugger
        var data =[
            {
                'bulidingdate' : selectedbuildingDate,
                'buildingyear' : buildingyearValue,
                'buildingAge': buildingageValue,
                'buildingareafit': buildingareaValue,
                'state' : stateValue,
                'city' : cityValue,
                'buildingname': buildingnamevalue,
                'streetname': streetValue,
                'postalcode': postcodevalue,
            }
        ]
    
        sessionStorage.setItem("buildingdetails" , JSON.stringify(data))
        window.location.href = '/homequestion';



    
    
        // debugger

        // if (selectedGender === null){
        //   event.preventDefault();
        //   document.getElementsByName('gender')[0].focus();
        // }else if (selectedmarital === null){
        //   event.preventDefault();
        //   document.getElementsByName('maritalstatus')[0].focus();
        // }
        // else{

        //   debugger

        // var data = {
        //   "basicinfo_gender_id": selectedgenderOptionid,
        //   "basicinfo_gender_detail": selectedGender,
        //   "basicinfo_marital_status_id": selectedmaritalOptionid,
        //   "basicinfo_marital_status_detail": selectedmarital,
        //   "dob": selectedDateofBirth
        // }

        // debugger

        // sessionStorage.setItem("personalaccidentbasicinfo", JSON.stringify(data))


        // setLoading(true);

        // event.preventDefault();
        // window.location.href = '/accident-cover';

        // }

    };

    //   useEffect(() => {
    //     debugger
    //     const basicvalue = sessionStorage.getItem('personalaccidentbasicinfo');
    //     if (basicvalue != undefined) {
    //       const value = JSON.parse(basicvalue);
    //       setSelectedGender(value.basicinfo_gender_detail);
    //       setSelectedmarital(value.basicinfo_marital_status_detail);
    //       setSelectedDateofBirth(value.dob);
    //       setSelectedgenderOption(value.basicinfo_gender_detail);
    //       setSelectedgenderOptionid(value.basicinfo_gender_id);
    //       setSelectedmaritalOptionid(value.basicinfo_marital_status_id);
    //       setSelectedmaritalOption(value.basicinfo_marital_status_detail);
    //       setLoading(false);

    //     }

    //   }, []);

    //   const [dhofarData, setDhofarData] = useState(false);
    //   const [age, setAge] = useState(null);
    //   useEffect(() => {


    //     const storedDhofarString = Cookies.get('personalData'); 
    //     if (storedDhofarString !== undefined) {
    //       const storedDhofar = JSON.parse(storedDhofarString);
    //       setDhofarData(storedDhofar);
    //     }
    //     else {
    //       alert("token expired");
    //       window.location.href='/';
    //     }

    //   }, []);



    const [buttonDisabled, setButtonDisabled] = useState(false);
    //   const postData = { token: dhofarData };
    // const today = new Date().toISOString().split('T')[0];
    const today = new Date();
    const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];
    const minDate = new Date(today.getFullYear() - 60, today.getMonth(), today.getDate()).toISOString().split('T')[0];

    const [dobValue, setdobValue] = useState('');
    const handledobChange = (event) => {
        const selectedDob = event.target.value;
        setdobValue(event.target.value);

        const dobDate = new Date(selectedDob);
        const today = new Date();
        let calculatedAge = today.getFullYear() - dobDate.getFullYear();
        const monthDiff = today.getMonth() - dobDate.getMonth();

        // Adjust age if the current date is before the user's birthday in the current year
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
            calculatedAge--;
        }

        // setAge(calculatedAge);
    };

    const [selectedgenderOption, setSelectedgenderOption] = useState(null);
    const [selectedgenderOptionid, setSelectedgenderOptionid] = useState(null);
    const handlegenderChange = (event) => {
        debugger
        setSelectedGender(event.target.value)
        setSelectedgenderOptionid(event.target.options[event.target.selectedIndex].id);
        setSelectedgenderOption(event.target.value);
    };

    const handleBuildingnameeChange = (event) => {
        setBuildingnameValue(event.target.value)
    };
    //getgender
      const [buildingyearValue, setbuildingyearValue] = useState('');
    

      const [buildingageValue, setbuildingageValue] = useState('');
    

      const [cityValue, setcityValue] = useState('');
    
      const handlecityChange = (event) => {
        setcityValue(event.target.value);
      };

      const [streetValue, setStreetValue] = useState('');
    
      const handleStreetChange = (event) => {
        setStreetValue(event.target.value);
      };

      const [postcodevalue, setPostcodeValue] = useState('');
    
      const handlePostcodeChange = (event) => {
        setPostcodeValue(event.target.value);
      };

      const [orgpartyValue, setorgpartyValue] = useState('');
    
      const handleORGpartyChange = (event) => {
        setorgpartyValue(event.target.value);
      };



    const [buildingareaValue, setbuildareaValue] = useState('');
    const handleBuildAreaChange = (event) => {
        setbuildareaValue(event.target.value);
        event.preventDefault();
    };

    // const [emailError, setEmailError] = useState('');

    // const handleEmailBlur = (e) => {
    //     const { value } = e.target;

    //     // Regular expression to validate a basic email format
    //     const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    //     // Check if the email matches the pattern
    //     if (!emailPattern.test(value)) {
    //         // document.getElementsByName('email')[0].focus();
    //         setEmailError('Please enter a valid email address. Example: example@domain.com');
    //         setemailValue('');
    //     } else {
    //         setEmailError(''); // Clear the error if valid
    //     }
    // };

    const [stateValue, setstateValue] = useState('');
    const handleStateChange = (e) => {
        const value = e.target.value;
        setstateValue(value);
    };


   

    const handleDOBBlur = (e) => {
        //     const { value } = e.target;
        //    if(age<18){
        //     document.getElementsByName("dob")[0].focus();

        //    } 
    };

    const [goptions, setGender] = useState([]);
    const [moptions, setMarital] = useState([]);

    //   useEffect(() => {
    //     if (goptions && goptions.length === 0 && vendorKey !== '') {



    //       debugger
    //       fetch(base_url + '/getGender', {
    //         method: 'POST',
    //         headers: {
    //           'Authorization': `Bearer ${vendorKey}`,
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(postData),
    //       })
    //         .then(response => response.json())
    //         .then(data => {
    //           setGender(data.result.data.response_data);
    //           married()

    //         })
    //         .catch(error => {
    //           console.error('Error posting data:', error);
    //         });

    //     }    
    //   },[vendorKey]
    //   );

    //   const married = () => {
    //     if (moptions && moptions.length === 0 && vendorKey !== '') {
    //       setLoading(true);

    //       fetch(base_url + '/getMaritalStatus', {
    //         method: 'POST',
    //         headers: {
    //           'Authorization': `Bearer ${vendorKey}`,
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(postData),
    //       })
    //         .then(response => response.json())
    //         .then(data => {

    //           setMarital(data.result.data.response_data);
    //           setLoading(false);
    //           document.getElementsByName("gender")[0].focus();

    //         })
    //         .catch(error => {
    //           console.error('Error posting data:', error);
    //         });

    //     }
    //   };

    // const isButtonDisabled = !selectedgenderOption || !selectedmaritalOption || !selectedDateofBirth;






    return (
        <Fragment>

            <div className="header_section1">

                <Navbar />


                <div className="container-fluid bg-breadcrumb">
                    <div className="container text-center py-5">
                        <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Home Insurance' : 'التأمين على المنزل\n'}</h3>
                    </div>
                </div>


            </div>


            <div className="features_section">
                <div className="container">

                    <div className="row">

                        <Col md={3}></Col>

                        <Col md={6} className="myforms my-5">



                            <h4 className="px-0 py-2">{(lnstr === 'en') ? 'Select Building Details' : 'حدد تفاصيل المبنى\n'}</h4>

                            <form onSubmit={handleSubmit}>

                            <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr ==='en') ? 'Building Date':'تاريخ البناء'} <span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input value={selectedbuildingDate} type="date" name="buildingdob" max={maxDate} min={minDate} className="form-control" onChange={(e) => {
                                        setDobError("");
                                        setSelectedBuildingDate(e.target.value);
                                        const formattedDate = e.target.value.replace(/\//g, '-');
                                        const [year, month, day] = formattedDate.split("-");
                                        const finalformattedDate = `${day}-${month}-${year}`;
                                        setFormData({ ...formData, ['dob']: finalformattedDate });

                                        const currentYear = new Date().getFullYear();

                                        const buildingage = currentYear - year

                                        setbuildingyearValue(buildingage)

                                        debugger

                                        if (buildingage >=0 && buildingage <= 40){
                                            setbuildingageValue('1')
                                        }else if(buildingage >= 41 && buildingage <=45){
                                            setbuildingageValue('2')
                                        }else if(buildingage >=46 && buildingage <=50 ){
                                            setbuildingageValue('3')
                                        }else if(buildingage >=51 && buildingage <= 60){
                                            setbuildingageValue('4')
                                        }else{
                                            setbuildingageValue('5')
                                        }
                                        



                                        // const finalAgeFormattedDate = `${day}-${month}-${year}`;
                                        // Calculate and set the age
                                        const age = calculateAge(finalformattedDate);
                                        // setbuildingageValue(age)

                                        // setAge(age);
                                        if (age < 18) {
                                            setDobError('Your Age is invalid');
                                            // alert("Age is less than 18");
                                            // setSelectedDateofBirth("");
                                        }
                                        if (age >= 18) {
                                            setDobError('');

                                            // alert("Age is less than 18");
                                            // setSelectedDateofBirth("");
                                        }


                                    }} onBlur={handleDOBBlur} required />
                                </div>

                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? "Building Year" : 'سنة البناء'} <span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input type="text" name="buildingyear" className="form-control"
                                        value={buildingyearValue} readOnly
                                        required />
                                </div>
                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? "Building Age" : 'عمر البناء'} <span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input type='number' name='buildingage' className="form-control" value={buildingageValue} readOnly/>
                                </div>
                                

                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? "Built Up Area(in Sq Ft.)" :"المساحة المبنية (بالقدم المربع)"}<span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input type="text" name="builtarea" className="form-control" value={buildingareaValue}
                                        // onBlur={handleEmailBlur}
                                        onChange={handleBuildAreaChange} required />
                                </div>

                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? 'State' : 'ولاية'}<span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input type="text" name="state" className="form-control"  value={stateValue}
                                        onChange={handleStateChange} required/>
                                </div>

                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? "City" : 'مدينة'} <span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input type='text' name='city' className="form-control" value={cityValue} onChange={handlecityChange} required/>
                                </div>

                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? 'Building Name' : 'اسم المبنى'} <span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input value={buildingnamevalue} name="buildingname" className="form-control" onChange={handleBuildingnameeChange}
                                        required/>
                                        
                                </div>
                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? 'Street Name' : 'اسم الشارع'} <span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input value={streetValue} name="maritalstatus" className="form-control" onChange={handleStreetChange}
                                        required/>
                                </div>
                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? 'Postal Code' : 'رمز بريدي'} <span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input value={postcodevalue} name="maritalstatus" className="form-control" onChange={handlePostcodeChange}
                                        required/>
                                </div>
                                {/* <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? 'Is ORGParty' : 'هو ORGParty'} <span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <select value={orgpartyValue} name="maritalstatus" className="form-control" onChange={handleORGpartyChange}
                                        required>
                                        <option value=''>{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                                        <option value='Y'>Yes</option>
                                        <option value='N'>No</option>
                                    </select>
                                </div>
                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? 'Start Date' : 'تاريخ البدء'}<span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input type='date' name='startdate' className="form-control" onChange={setStartDate} required/>
                                </div>

                                <div className="input-group mb-2 mt-2">
                                    <div className="input-group-prepend prepends">
                                        <span className="input-group-text">{(lnstr === 'en') ? 'Expiry Date' : 'تاريخ انتهاء الصلاحية'}<span style={{ color: 'red', marginLeft: '5px' }}>*</span></span>
                                    </div>
                                    <input type='date' name='startdate' className="form-control" onChange={setExpiryDate} required/>
                                </div> */}



                                {/* {age !== null && <p>Your age is {age} year(s).</p>} */}
                                <button type="submit" className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{t('Submit')}</button>


                            </form>


                        </Col>

                        <Col md={3}></Col>

                    </div>

                </div>
            </div>

            <Footer />

            {loading && (
                <div className="overlay">
                    <div className="spinner"></div>
                </div>
            )}

        </Fragment>
    );


}

export default BuildingDetails;
