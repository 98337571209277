import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import { useTranslation } from 'react-i18next';
// import travelimg from '../tr.png';
import { format } from 'date-fns';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';
const { config } = require('../../api/api');

function Tenantsummary(){

  const base_url = config.baseURL;
  const redirect_url = config.redirectURL;

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);


  const [dhofarData, setDhofarData] = useState(null);
  

     const [premiumDetails,setPremiumDetails]=useState(null);

    const [personalInformation,setPersonalInformation]=useState(null);
    const [sumAssured,setSumAssured]=useState(null);
    const [homeDetails,setHomeDetails]=useState(null);
    const [homePlanType,setHomePlanType]=useState(null);
    const [quotation,setQuotation]=useState(null);
    const [premium, setPremium] = useState(null);
    const [files,setFiles]=useState([]);
    const [policyNo,setPolicyNo]=useState(null);
    const [questionsData,setQuestionsData]=useState(null);
    const [signature,setSignature]=useState(null);
    const [userData, setUserData]=useState(null);



  

  useEffect(() => {
   const storedDhofarString = Cookies.get('homeData'); //sessionStorage.getItem('userData')
    
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href='/';
    }
  }, []);


     useEffect(() => {
    const storedUserString = Cookies.get("userData"); 
    if (storedUserString !== undefined && storedUserString !== "undefined") {
      const storedUser = JSON.parse(storedUserString);
      setUserData(storedUser);
    }
  }, []);

  const [vendorKey, setVendorkey] = useState('');

  useEffect(() => {

    const postvData = { organization_name: "Superjet" };
    

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch( base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorkey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]);

  const [payment_link , setPaymentValue] = useState('');
 
  var addPolicyData = {
  nationality: personalInformation?.nationality,
  civil_id: personalInformation?.civilid,
  personalInformation_fullName: personalInformation?.fullName,
  personalInformation_email: personalInformation?.email,
  personalInformation_mobileNumber: personalInformation?.mobilenumber,
  personalInformation_resident_location: personalInformation?.Residential_Location,
  id_expiry_date: personalInformation?.expiry_date,
  occupation: personalInformation?.occupation,
  occupation_code: personalInformation?.occupation_code,
  amount_base_price: quotation?.base_price,
  amount_emergency_fund_fee: quotation?.emergency_fund_fee,
  amount_government_fee: quotation?.government_fee,
  amount_policy_fee:quotation?.policy_fee,
  amount_premium_details_section_1_premium: premium?.section_1_premium ,
  amount_premium_details_section_2_premium: premium?.section_2_premium,
  amount_premium_details_section_3_premium: premium?.section_3_premium,
  amount_premium_details_section_4_premium: premium?.section_4_premium,
  amount_premium_details_section_5_premium: premium?.section_5_premium,
  amount_premium_details_section_6_premium_ownership_type: premium?.section_6_premium_ownership_type,
  amount_premium_details_section_6_premium_personal:premium?.section_6_premium_personal ,
  amount_premium_details_section_7_premium: premium?.section_7_premium,
  amount_premium_details_section_8_premium: premium?.section_8_premium,
  amount_premium_details_total_premium: premium?.total_premium,
  amount_total: quotation?.total,
  amount_vat: quotation?.vat,
  home_details_address: homeDetails?.address,
  home_details_business_of_insured: "Employee",
  home_details_floor_no: homeDetails?.floor_no,
  home_details_type_of_building: homeDetails?.type_of_building,
  homeOwnerType: homePlanType?.plan.toLowerCase(),
  question_has_any_claims: questionsData?.has_any_claims,
  question_have_any_mortgage: questionsData?.have_any_mortgage,
  question_have_any_special_conditions_imposed: questionsData?.have_any_special_conditions_imposed,
  question_have_insurance_cancelled_by_other_company: questionsData?.have_insurance_cancelled_by_other_company,
  question_have_insurance_declined_before: questionsData?.have_insurance_declined_before,
 section_1_selected: sumAssured?.section_1,
  section_2_selected: sumAssured?.section_2,
  section_3_selected: sumAssured?.section_3,
  section_4_selected: sumAssured?.section_4,
  section_5_selected: sumAssured?.section_5 || 0,
  section_6_selected: sumAssured?.section_6,
  section_7_selected: sumAssured?.section_7,
  section_8_selected: sumAssured?.section_8,
  section_1_sum_assured: sumAssured?.section_1_sum_assured,
  section_3_sum_assured: sumAssured?.section_3_sum_assured,
  section_5_sum_assured: sumAssured?.section_5_sum_assured||0,
  signature: signature,
  token: dhofarData,
  total_property_photograph_count: "3"

}


useEffect(()=>{

const storedFiles=sessionStorage.getItem("tenantfiles");
if(storedFiles){
    const fileArray=JSON.parse(storedFiles);
    const recreatedFiles =fileArray.map((file)=>{
    const arr= file.base64.split(",");
    const bstr=atob(arr[1]);
    let n = bstr.length;
    const u8arr =new Uint8Array(n);
    while(n--){
      u8arr[n]=bstr.charCodeAt(n);
    }
    return new File([u8arr],file.name,{type:file.type})
  });
  setFiles(recreatedFiles);
}
},[])


  
    const NumberFormatter = ({ value }) => {
      // Ensure value is a number and format to 3 decimal places
      const formattedValue = `${Number(value).toFixed(3)} OMR`;
   
      return formattedValue ;
    };
    useEffect(() => {
 const personalInfo = sessionStorage.getItem("homeTenantPersonalInfo");
const sectionSumAssured = sessionStorage.getItem("hometenantsection");
const homeAllDetails = sessionStorage.getItem("hometenantdetails");
const homePlan = sessionStorage.getItem("homeplan");
const  signatureData = sessionStorage.getItem("homeTenantSignature");
const quotations_tenant=sessionStorage.getItem("tenantquotation");  
const  tenantQuestions = sessionStorage.getItem("hometenantquestions");  

if (personalInfo !== undefined) {
  const personalInfoParse = JSON.parse(personalInfo);
  setPersonalInformation(personalInfoParse);
}
if (quotations_tenant !== undefined) {
  const quotationParse = JSON.parse(quotations_tenant);
  setQuotation(quotationParse);
  setPremiumDetails(quotationParse);
  setPremium(quotationParse.premium_details);
}
if (signatureData  !== undefined) {
  setSignature(signatureData);
}

if (tenantQuestions !== undefined) {
  const question = JSON.parse(tenantQuestions);
  setQuestionsData(question);
}


if (sectionSumAssured !== undefined) {
  const sumAssured = JSON.parse(sectionSumAssured);
  setSumAssured(sumAssured);
}

if (homeAllDetails !== undefined) {
  const homeDetailsObj = JSON.parse(homeAllDetails);
  setHomeDetails(homeDetailsObj);
}

if (homePlan !== undefined) {
  const parseHomePlan = JSON.parse(homePlan);
  setHomePlanType(parseHomePlan);
}
  
}, [vendorKey]);
    const handleSubmit = async (e) => {
        e.preventDefault();
       
      setLoading(true); 
        var policy_url = '';
        if (userData != null && userData != undefined && userData.agency_name != undefined) {
          policy_url=`${base_url}/get_home_tenant_agent_add_policy`;
          addPolicyData["agent_token"] = userData.access_token;
        }
         else if(userData != null && userData != undefined && userData.traveller_id != undefined && userData.first_name != undefined){
         policy_url=`${base_url}/get_home_tenant_user_add_policy`;
        addPolicyData["user_token"] = userData.access_token;
    }
        else{
         policy_url=`${base_url}/get_home_tenant_addpolicy`;
        }
    
      try{
      
        var policyFormData = new FormData();
  policyFormData.append("policy_data",JSON.stringify(addPolicyData));
  files.forEach((file,index)=>{
  policyFormData.append(`property_photograph_${index+1}`,file);
  });
  
     
      const response=await fetch(policy_url, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${vendorKey}`
        },
        body:policyFormData
      });
      const  data=await response.json();
      if(data.message==="success"){
       setPolicyNo(data?.result?.policy_number);
       sessionStorage.setItem("homePolicyNo",data?.result?.policy_number);
       const policy_no=data?.result?.policy_number;

        var quotation_data =
      {
        "token": dhofarData,
        "policy_no":policy_no,
        "redirect_url": redirect_url + '/home-downloadpolicy?policyno='+ policy_no
      };
     
    
        try {
          const Policyresponce = await fetch( base_url + '/get_home_paymentlink', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${vendorKey}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(quotation_data),
          });
  
          if (!Policyresponce.ok) {
            throw new Error('Network response was not ok');
          }
         
  
          const data = await Policyresponce.json();
  
        setPaymentValue(data.result?.data?.payment_url);
        setLoading(false);
        window.location.href = data.result?.data?.payment_url;
        //   await handlePolicy(data.result.data.amount)
        } catch (error) {
            console.error('Error fetching occupations:', error);
        }
    
       setLoading(false);
      }
      else{
      setPolicyNo(null);
      }
      }
      catch(err){
      console.log("error occured while getting policy No",err)
      }
    
      };

      const handlecancel = async (event) => {

        window.location.href = '/home-tenant'
      };

      function formatDOB(dob) {
        const date = new Date(dob);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      }
      
  return (


    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Home Insurance Policy' : 'وثيقة التأمين على المنزل\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>
            {/* style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} */}

            <Col md={6} className="myforms my-5">

            <h1 style={{textAlign:"center"}}><b>{(lnstr === 'en') ? 'SUMMARY' : 'ملخص\n'}</b></h1>



            {/* <h2 style={{textAlign:'left'}}>{(lnstr === 'en') ? 'Plan Details':'تفاصيل الخطة'}</h2> */}
            <div className="d-flex justify-content-between my-3 colmds-4 card m-0">
              <div className="d-flex justify-content-between my-3">
                <div>
                <p>{(lnstr === 'en') ? 'Plan Type' :'نوع الخطة'}</p>
                <h6><strong>{homePlanType?.plan.substring(0,1).toUpperCase()+homePlanType?.plan.slice(1)}</strong></h6>
                </div>
                <div>
              <p>{(lnstr === 'en') ? 'Sum Assured':'المبلغ المؤمن'}</p>
              <h6><strong>{premium !=null ? <NumberFormatter value={premium?.total_premium} /> :''}</strong></h6>
              </div>
              
              </div>
              
            </div>

            


            <br />
            <h3>{(lnstr === 'en') ? 'Personal Details':'التفاصيل الشخصية'}</h3>
            <div>



            <table className="table table-bordered table-condenced">

    <tbody>

    <tr><td>Full Name</td><td><b>{personalInformation != null ? personalInformation?.fullName : ''}</b></td></tr>
    <tr><td>Email ID</td><td><b>{personalInformation != null ?personalInformation?.email : ''}</b></td></tr>

    <tr><td>Civil ID</td><td><b>{personalInformation != null ? personalInformation?.civilid : ''}</b></td></tr>
    <tr><td>Mobile</td><td><b>{personalInformation !=null ? personalInformation?.mobilenumber : ''}</b></td></tr>

    <tr><td>Occupation</td><td><b>{personalInformation != null ? personalInformation?.occupation : ''}</b></td></tr>
    <tr><td>Expiry Date of Civil ID / Passport</td><td><b>{personalInformation != null ? formatDOB (personalInformation?.expiry_date) : ''}</b></td></tr>

    {/* <tr><td>Gender</td><td><b>gender</b></td>
    <td>Marital Status</td><td><b>marital_status_value</b></td></tr> */}

    <tr><td>Residential Location</td><td><b>{personalInformation != null ? personalInformation?.Residential_Location: ''}</b></td></tr>
    <tr><td>Nationality</td><td><b>{personalInformation != null ? personalInformation?.nationality: ''}</b></td></tr>

    </tbody>

    </table>
                            
                            </div>
                            <br/>



                            <h3>{(lnstr === 'en') ? 'Premium Details':'تفاصيل متميزة'}</h3>
            <div>
            <table className="table table-bordered table-condenced" style={{ textAlign: 'left' }}>

  <tbody>

      <tr><td style={{ width: '50%' }}>
      Basic premium
          </td><td><b>{premiumDetails != null ? <NumberFormatter value={premiumDetails?.base_price} /> : ''}</b></td></tr>

      <tr><td>Policy Fee	
          </td><td><b>{premiumDetails != null ? <NumberFormatter value={premiumDetails?.policy_fee} /> : ''}</b></td></tr>
        
      
          <tr><td>Government Fee
      
      </td><td><b>{premiumDetails != null ? <NumberFormatter value={premiumDetails?.government_fee} />: ''}</b>
      </td></tr>


      <tr><td>Emergency Fund Fee
  </td><td><b>{premiumDetails != null ? <NumberFormatter value={premiumDetails?.emergency_fund_fee} />: ''}</b></td></tr>

      <tr><td>VAT
          </td><td><b>{premiumDetails != null ?<NumberFormatter value={premiumDetails?.vat} />  : ''}</b></td></tr>

      <tr><td>Total Premium
  </td><td><b>{premiumDetails != null ?<NumberFormatter value={premiumDetails?.total} />  : ''}</b></td></tr>
  </tbody>

  </table>
            </div>


            {/* <p style={{textAlign:'center'}}><strong>{policy_no}</strong></p> */}

            <div className="d-flex  my-3">

            <button className="btn btn-primary" style={{ padding: '10px 30px' , marginRight : '10px'}}   onClick={handlecancel} >Edit</button>

            <button className="btn btn-primary" style={{ padding: '10px 30px'}}   onClick={(e)=>handleSubmit(e)} > Proceed to Payment</button>

            </div>

            


            </Col>

            <Col md={3}></Col>

          </div>


        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>



    
  );
}

export default Tenantsummary;
