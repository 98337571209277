import React, { Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { format } from 'date-fns';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';
const { config } = require('../api/api');


function ShowBuyHotel() {

    const base_url = config.baseURL;
    const redirect_url = config.redirectURL;
    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
    const [loading, setLoading] = useState();

    const { t } = useTranslation();

    const [vendorKey, setVendorkey] = useState('');
    const [dhofarData, setDhofarData] = useState(false);
    const [downloadpolicy, setDownloadValue] = useState('');
    const [policyLink, setPolicyLink] = useState(null);
    const [policy_no, setPolicyNo] = useState(null);
    const [contract_id, setcontractsID] = useState('');
    // const [showUploadModal, setShowUploadModal] = useState(false);

    const [loungescoupon, setLoungesToken] = useState('');

    const [passportFile, setPassportFile] = useState(null);
    const [questionnaireFile, setQuestionnaireFile] = useState(null);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [fileChoosen, setFileChoosen] = useState(true);
    const [barvalue, setBarValue] = useState(13);

    const pattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)(:[0-9]{1,5})?(\/.*)?$/;

    useEffect(() => {
        const urlSearchString = window.location.search;
        const uparams = new URLSearchParams(urlSearchString);
        const policy_no = uparams.get('order_id');
        setPolicyNo(policy_no);
    }, []);

    useEffect(() => {
        if (showUploadModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [showUploadModal]);


      useEffect(() => {
        const createOrganization = async () => {
          try {
            const postvData = { organization_name: "Superjet" };

            const response = await fetch(base_url + '/create_organization', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(postvData),
            });
            const data = await response.json();
            setVendorkey(data.result[0].vendor_key);
          } catch (error) {
            console.error('Error posting data:', error);
          }
        };

        if (!vendorKey) {
          createOrganization();
        }
      }, [vendorKey, base_url]);




    //   useEffect(() => {
    //     const fetchPolicyDetails = async () => {
    //       if (vendorKey && contract_id  && policy_no) {
    //         try {
    //           const quotation_data = {
    //             contract_id: contract_id,
    //             order_id: policy_no,
    //           };

    //           const response = await fetch(base_url + '/get_order_details', {
    //             method: 'POST',
    //             headers: {
    //               'Authorization': `Bearer ${vendorKey}`,
    //               'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(quotation_data),
    //           });

    //           const data = await response.json();
    //           setDownloadValue(data.result.orderItems);
    //           setLoading(false);
    //         } catch (error) {
    //           setLoading(false);
    //           console.error('Error fetching policy details:', error);
    //         }
    //       }
    //     };

    //     fetchPolicyDetails();
    //   }, [vendorKey, contract_id, policy_no, base_url]);

    const items = [
        {
            rooms: "Deluxe room with city view",
            values: "no meals included",
            free: 'free cancellation',
            date: 'under 18.02.2025',
            amount: '23.000'
        },
        {
            rooms: "Deluxe room with city view",
            values: "no meals included",
            free: 'free cancellation',
            date: 'under 18.02.2025',
            amount: '23.000'

        },
        {
            rooms: "Deluxe room with city view",
            values: "no meals included",
            free: 'free cancellation',
            date: 'under 18.02.2025',
            amount: '23.000'
        }
    ]

    const handleChange = (e) => {
        setBarValue(Number(e.target.value));
    };

    const StarRating = ({ rating, totalStars = 5 }) => {
        const filledStars = Math.round(rating);
        const emptyStars = totalStars - filledStars;

        return (
            <div className="star-rating" style={{ display: 'flex', padding: '0' }}>
                {[...Array(filledStars)].map((_, index) => (
                    <span
                        key={`filled-${index}`}
                        //   onClick={() => handleStarClick(index)}
                        style={{ color: 'gold', cursor: 'pointer' }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                    </span>
                ))}
                {[...Array(emptyStars)].map((_, index) => (
                    <span
                        key={`empty-${index}`}
                        //   onClick={() => handleStarClick(filledStars + index)}
                        style={{ color: 'gray', cursor: 'pointer' }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
                            <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                        </svg>
                    </span>
                ))}
            </div>
        );

    }
    const handleQotation = async (itemvalue) => {
        var quotation_data = {
            orderItemId: itemvalue,
            orderId: policy_no,
        };

        if (vendorKey !== "") {
            setLoading(true);
            try {
                const response = await fetch(base_url + "/generate_coupon", {
                    method: "POST",
                    headers: {
                        "Authorization": `Bearer ${vendorKey}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(quotation_data),
                    responseType: "blob", // Fetch response as a binary file
                });

                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }

                // Convert response to blob
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);

                // Create a download link
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "order_coupon.pdf"); // Ensure correct file type
                document.body.appendChild(link);
                link.click();
                setLoading(false);

                // Cleanup
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);

            } catch (error) {
                setLoading(false);
                console.error("Error generating quotation PDF:", error);
            }
        }
    };


    useEffect(() => {
        if (showUploadModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [showUploadModal]);


    const isValidUrl = (url) => {
        return pattern.test(url);
    };
    const showPaymentPage=(e)=>{
      e.preventDefault();
      window.location.href="/hotel-booking"
    }

    useEffect(()=>{
  
        const getETMData= sessionStorage.getItem("ETM_Data");
        const parseData=getETMData?JSON.parse(getETMData):null;
        
        const getHotelOfferDetails= async ()=>{
          debugger
          try {
              var hotelOffersReq={
                  etmtoken:parseData.etm_token,
                  hotel_id:parseData.hotel_id
              }
            //   var hotelOffersReq={
            //     etmtoken:"50f725bd8487201f2db490ef66a3770d",
            //     hotel_id:"8d132d4b-ab82-405e-a5c5-b985dedb521f_11452a6a-d2ad-4dd8-8f11-b4ba68a9b635_0a61b61b-e197-47c6-8e23-cdd4f4486cc4"
            // }
              const response =await fetch(base_url + "/hotel_offer", {
                method: "POST",
                headers: {
                  "Authorization": "Bearer "+ vendorKey,
                  "Content-Type": "application/json"
                },
                body: JSON.stringify(hotelOffersReq)
              });
              if (!response.ok) {
                console.log("http response status : ", response.status)
              }
              else {
                const data = await  response.json();
                if(data.message==="success"){
              //   setHotelDetails(data.result.data.hotels);
                }
              }
            }
            catch (err) {
              console.log("Hotel Show Offer API Err : ", err);
            
            }
      }
      debugger
      if(parseData !==undefined && vendorKey){
        getHotelOfferDetails();
      }
      },[vendorKey])

    return (



        <Fragment>

            <div className="header_section1">

                <Navbar />


                <div className="container-fluid bg-breadcrumb">
                    <div className="container text-center py-5">
                        <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'e-Hotel Details' : 'تفاصيل الفندق الإلكتروني\n'}</h3>
                    </div>
                </div>


            </div>


            <div className="features_section">
                <div className="container">

                    <div className="row">

                        <Col md={1}> </Col>



                        <Col md={10} className="myforms my-5" >

                            <br />

                            <div>
                                <div className="card shadow">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJpObS1YgotFcNh_XICe8k-zvOGMp7MrrrKjJ9ZyGTeX6-z3pVjdjFVOpi608eDzi11Wo&usqp=CAU' />
                                            </div>
                                            <div className="col-md-8 pt-3">
                                                <h1>Deluxe room</h1>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                                                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>

                                    {items.map((item, index) => (

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <h4>{item.rooms}</h4>
                                                    <p>{item.values}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <div><h4>{item.free}</h4></div>
                                                    <div><p>{item.date}</p></div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="text-center">
                                                        <h4>{item.amount}$</h4>
                                                    </div>
                                                    <div>
                                                        <button type='submit' onClick={(e)=>{showPaymentPage(e)}} className="btn btn-primary" style={{ width: "100%" }}>Buy</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    ))}




                                </div>  
                            </div>

                        </Col>
                        <Col md={1}></Col>
                    </div>
                </div>
            </div>
            <Footer />



            {loading && (
                <div className="overlay">
                    <div className="spinner"></div>
                </div>
            )}

        </Fragment>


    );
}

export default ShowBuyHotel;