import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
// import SignatureCanvas from 'react-signature-canvas';
// import Termplandownload from './Term-Plan Certificate.pdf';
// import Armedforcesquestionnaire from './Armed-forces-questionnaire.docx';
const { config } = require('../api/api');


function HomeNewQuestions() {

  const base_url = config.baseURL;

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
  const signatureRef = useRef();

  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    insurancetype: '',
  });
  const [loading, setLoading] = useState(false);
  const [form_download, setdownload] = useState(false);

  const handleDownload = () => {
    setdownload(true);
  };

  const handleqOptionChange = (event) => {

  };
  const [quotationData, setQuotationData] = useState(null);


  const [questionList, setQuestionList] = useState();

  const [questionvalue, setQuestionsData] = useState([{
    english: '',
    isChecked_answer: '',
  }]);


  const [selectedValue, setSelectedValue] = useState('Yes');

  const handleChanges = (e) => {
    setSelectedValue(e.target.value);
  };
  function handleRadioChange(index, isChecked) {

    var value = ''

    if(isChecked == true){
        value = 'Yes'
    }else{
        value = 'NO'
    }
    debugger
    const updatedQuestionsData = [...questionvalue];

    updatedQuestionsData[index] = {
      ...updatedQuestionsData[index],
      
      isChecked,
      isChecked_answer: isChecked ? "Yes" : "No"
    };
    setQuestionsData(updatedQuestionsData);
  }

  function handleDetailsChange(index, details) {

    // const updatedQuestionsData = [...questionsData];
    // updatedQuestionsData[index] = {
    //   ...updatedQuestionsData[index],
    //   details
    // };
    // setQuestionsData(updatedQuestionsData);
  }

  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
  const [isSignatureEmpty1, setIsSignatureEmpty1] = useState(true);


  const clearSignature = () => {
    signatureRef.current.clear();
    setIsSignatureEmpty(true);
    setIsSignatureEmpty1(true); // Disable button after clearing
  };
  const handleSignature = () => {
    if (!signatureRef.current.isEmpty()) {
      setIsSignatureEmpty(false);
      setIsSignatureEmpty1(false); // Enable button when signature is drawn
    }
  };
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };


  const [isChecked1, setIsChecked1] = useState(false);
  const handleCheckboxChange1 = (event) => {
    setIsChecked1(event.target.checked);
  };


  const handleSubmit = async (event) => {

    event.preventDefault();
    setLoading(true);

    event.preventDefault();
    window.location.href = '/';



  };
  const [dhofarData, setDhofarData] = useState(false);
  const [age, setAge] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Referanceid, setReferanceid] = useState('');
  const [modalMessage, setModalMessage] = useState("");
  const [bmi, setbmi] = useState('')
//   useEffect(() => {


//     const storedDhofarString = Cookies.get('personalData'); //sessionStorage.getItem('userData')
//     debugger
//     if (storedDhofarString !== undefined) {
//       const storedDhofar = JSON.parse(storedDhofarString);
//       setDhofarData(storedDhofar);
//     }
//     else {
//       alert("token expired");
//       window.location.href='/';
//     }

//   }, []);
  const [userData, setUserData] = useState();

  useEffect(() => {
    const storedUserString = Cookies.get('userData'); //sessionStorage.getItem('userData')
    if (storedUserString != undefined) {
      const storedUser = JSON.parse(storedUserString);
      setUserData(storedUser);
    } else {
      // alert("token expired");
      // window.location.href = '/';
    }
  }, []);

  const [vendorKey, setVendorkey] = useState('');

  useEffect(() => {

    const questionsData = [ 
        {
            english:'Claim Made in 3Years',
            isChecked_answer: "",
    
        },
        {
            english:'If the insured premises is less than 60Yrs and also not a village or hous of similar nature',
            isChecked_answer: "",
        },
        {
            english:'The insured premises are used soley for domestic residential purposes only',
            isChecked_answer: "",
        },
        {
            english:'The insured premises contain no illegal structure and its built of and roofed with bricks,stones or concrete',
            isChecked_answer: "",
        },
        {
            english:'Is Worldwide all Risk Opted?',
            isChecked_answer: "",
        },
        {
            english:'Is Landlord Insurance Opted?',
            isChecked_answer: "",
        },
        
    ]

    setQuestionsData(questionsData)

    // const postvData = { organization_name: "Superjet" };
    // debugger

    // if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


    //   fetch( base_url + '/create_organization', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(postvData),
    //   })
    //     .then(response => response.json())
    //     .then(data => {
    //       setVendorkey(data.result[0].vendor_key);
    //     })
    //     .catch(error => {
    //       console.error('Error posting data:', error);
    //     });


    // }
  }, [vendorKey]); // Dependency array

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const postData = { token: dhofarData };
  // const today = new Date().toISOString().split('T')[0];
  const today = new Date();
  const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];


  const [dobValue, setdobValue] = useState('');
  const handledobChange = (event) => {
    const selectedDob = event.target.value;
    setdobValue(event.target.value);

    const dobDate = new Date(selectedDob);
    const today = new Date();
    let calculatedAge = today.getFullYear() - dobDate.getFullYear();
    const monthDiff = today.getMonth() - dobDate.getMonth();

    // Adjust age if the current date is before the user's birthday in the current year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
      calculatedAge--;
    }

    setAge(calculatedAge);
  };

  const [selectedgenderOption, setSelectedgenderOption] = useState(null);
  const [selectedgenderOptionid, setSelectedgenderOptionid] = useState(null);
  const handlegenderChange = (event) => {
    setSelectedgenderOptionid(event.target.options[event.target.selectedIndex].id);
    setSelectedgenderOption(event.target.value);
  };

  const [selectedmaritalOption, setSelectedmaritalOption] = useState(null);
  const [selectedmaritalOptionid, setSelectedmaritalOptionid] = useState(null);
  const handlemaritalChange = (event) => {
    setSelectedmaritalOptionid(event.target.options[event.target.selectedIndex].id);
    setSelectedmaritalOption(event.target.value);
  };
  //getgender

//   const [questions, setquestionsValue] = useState([]);




  const api_headers = {
    'Authorization': 'Bearer ' + vendorKey,
    'Content-Type': 'application/json' // Ensure you're sending JSON
  }


  const [amount, setAmountValue] = useState('');
  const [policy, setPolicyValue] = useState('');

  const handleQotation = async (event) => {
    // event.preventDefault();

    debugger 

      const bacsicinfojson = sessionStorage.getItem('insuranceplan');
    const basicinfo = JSON.parse(bacsicinfojson);

    const basicPremiumjson = sessionStorage.getItem('customerdetails');
    const basicPremium = JSON.parse(basicPremiumjson);

    const addbenefityjson = sessionStorage.getItem('buildingdetails');
    const addbenefity = JSON.parse(addbenefityjson);

    function formatDOB(dob) {
      const date = new Date(dob);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }

    debugger

    var quotation_data =
    {
        "token": basicinfo[0].token,
        "policy_data":{
        "EffectiveDate": basicPremium[0].startdate,
        "ExpiryDate": basicPremium[0].expirtydate,
        "PolicyCustomerList": [
            {
                "Address": basicPremium[0].Address,
                "Age": basicPremium[0].Age,
                "BusinessObjectId": 1000000290,
                "CustomerName": basicPremium[0].CustomerName,
                "DateOfBirth": basicPremium[0].DateOfBirth,
                "Email": basicPremium[0].Email,
                "GenderCode": basicPremium[0].GenderCode,
                "IsInsured": basicPremium[0].InInsured,
                "IsOrgParty": basicPremium[0].IsOrgParty,
                "IsPolicyHolder": basicPremium[0].IsPolicyHolder,
                "Mobile": basicPremium[0].Mobile,
                "PolicyStatus": 2,
                "SequenceNumber": 1
            }
        ],
        "PolicyLobList": [
            {
                "PlanList": [
                    {
                        "PlanCode": "CIFHOME20220001",
                        "PolicyStatus": 2,
                        "ProductElementCode": "PLANS"
                    }
                ],
                "PolicyRiskList": [
                    {
                        "AllotherclaimsExcess": 500,
                        "BuildDate": "1984-08-17",
                        "BuildingAge": "1",
                        "BuildingAgeYears": 38,
                        "BuildingName": "AGP Shrirangam",
                        "BuildingType": "1",
                        "BuiltOfBrickStoneAndConcreted": "0",
                        "BuiltUpAreaInSqFt": "1",
                        "City": "Chennai",
                        "ClaimInLast3Years": "0",
                        "Country": "India",
                        "IsLandordInsRiskOpted": "1",
                        "IsWorldwideRiskOpted": "1",
                        "LandsliporsubsidenceExcess": "10,000 or 10% of adjusted loss whichever is greater",
                        "PolicyCoverageList": [
                            {
                                "ProductElementCode": "C0001286"
                            },
                            {
                                "ProductElementCode": "C0000745"
                            },
                            {
                                "ProductElementCode": "C0001651"
                            },
                            {
                                "ProductElementCode": "PRSL_LIAB"
                            }
                        ],
                        "PolicyStatus": 2,
                        "PostalCode": "6000042",
                        "ProductElementCode": "R00005",
                        "State": "TamilNadu",
                        "StreetName": "ParkAvenue 1stStreet",
                        "UnitNo": "17",
                        "UseOfPremises": "0",
                        "UsedConfinedToOwnPremises": "0",
                        "WaterdamageExcess": "1,000 or 10% of adjusted loss whichever is the greater",
                        "WorldwideAllRiskExcess": 500
                    }
                ],
                "PolicyStatus": 2,
                "ProductCode": "HOME01",
                "ProductElementCode": "HOME01"
            }
        ],
        "PolicyType": "1",
        "ProductCode": "HOME01",
        "ProductVersion": "1.0",
        "ProposalDate": "2022-10-18",
        "SchemaVersion": 2,
        "SourceChannelCode": "NA",
        "TechProductCode": "HOME01_TECH"
    }
    }
    if (vendorKey !== '') {
      // const fetchPolicy = async () => {
      try {

        const dd = await fetch(base_url + '/get_term_plan_quotation', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(quotation_data),
        });

        if (!dd.ok) {
          throw new Error('Network response was not ok');
        }
        debugger

        const data = await dd.json();

        setAmountValue(data.result.data.amount);
        sessionStorage.setItem("personalaccdientamount", JSON.stringify(data.result.data.amount))


        // var questionvalue ={
        //   "on_medication": questionsData[0].isChecked_answer,
        //   "have_travelled": questionsData[1].isChecked_answer,
        //   "in_good_health": questionsData[2].isChecked_answer,
        //   "is_armed_member": questionsData[3].isChecked_answer,
        //   "signature":signatureRef.current.toDataURL()
        // }
        // sessionStorage.setItem('personalquestionvalue', JSON.stringify(questionvalue))
        // await handlePolicy(data.result.data.amount)
        const signatureData=signatureRef.current.toDataURL();
        sessionStorage.setItem("ternSignature",signatureData);
        window.location.href = '/termplan-policy'
      } catch (error) {
        console.error('Error fetching occupations:', error);
      }
      // };
    }
 
  };






  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Please answer the following' : 'الرجاء الإجابة على ما يلي\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">


              {questionvalue.map((question, index) => (
                <div key={index}>
                  <p>{index+1}.{question.english}</p>
                  <div className="custom-radio" style={{ color: '#3fd2f2' }}>
                    <input
                      type="radio"
                      id={`yes-${index}`}
                      name={`option-${index}`}
                      value="Yes"
                      checked={question.isChecked === true && question.isChecked_answer==="Yes"}
                      onChange={() => handleRadioChange(index, true)}
                    />
                    <label htmlFor={`yes-${index}`}>Yes</label>

                    <input
                      type="radio"
                      id={`no-${index}`}
                      name={`option-${index}`}
                      value="No"
                      checked={question.isChecked === false&& question.isChecked_answer==="No"}
                      onChange={() => handleRadioChange(index, false)}
                    />
                    <label htmlFor={`no-${index}`}>No</label>
                  </div>
                  <br />
                  {/* {index === 3 && question.isChecked_answer === 'Yes' ? "Please download this and fill it.You will be asked to upload the filled document after the payment." : '' } */}
                </div>
              ))}
              <br/>
              <div className='col-md-2 text-center'>
                <button type="button"
                // disabled={!isChecked || !isChecked1}
                  style={{ backgroundColor: isChecked ? '#1D3561' : "grey", borderColor: '#1D3561' }}
                  className="btn btn-primary rounded-pill text-white my-3 py-2 "
                  // disabled={isChecked ? false : true}
                  onClick={async () => {


                    // var question_valid = !questionsData.some(q => q.isChecked_answer === '');
                    // debugger
                    // if (!question_valid) {
                    //   alert('Please ensure the question answer');
                    // }else if ( questionsData[0].isChecked_answer =='No'){
                    //   alert(`Please select the Answer I Agree in question 1`)
                    // }else if (questionsData[1].isChecked_answer =='No'){
                    //   alert(`Please select the Answer I Agree in question 2`)
                    // }else if (questionsData[2].isChecked_answer =='No'){
                    //   alert(`Please select the Answer I Agree in question 3`)
                    // }else if (questionsData[3].isChecked_answer !='No' && form_download != true){
                    //   alert("Please download the pdf document")
                    // }else if (signatureRef.current.isEmpty()) {
                    //   alert('Please put signature');
                    // } else if (!isChecked) {

                    //   alert('Please ensure the declare');
                    // } else if (!isChecked1) {

                    //   alert('Please ensure the declare');
                    // } else {
                    //   debugger
                      // event.preventDefault();



                //       setLoading(true);

                      await handleQotation();
                //     }

                  }}
                >
                  Proceed
                </button>
              </div>


            </Col>


            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}

export default HomeNewQuestions;
